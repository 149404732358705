import React, { useMemo } from 'react'
import { GeneralNumericFormat } from '@/components/Elements'
import { CommonUtils, ConvertUtils } from '@/utils'

interface IProps {
  value: string | number | null
}

export const PercentageFormat = ({ value = '', ...props }: IProps) => {
  const formattedValue = useMemo(() => {
    if (!(CommonUtils.isNil(value) || CommonUtils.isNanValue(value))) {
      return ConvertUtils.number.numberToPercentage(value as string | number)
    }

    return value
  }, [value])

  return <GeneralNumericFormat value={formattedValue} {...props} />
}
