import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { GeneralForecastContainer } from '@/features/forecast/components/Forecast/GeneralForecast'
import { ForecastInputsDataLoader } from '@/features/forecast/components/ForecastInputs'

export const Forecast = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'forecast'} />

      <PageContent>
        <ForecastInputsDataLoader>
          <GeneralForecastContainer />
        </ForecastInputsDataLoader>
      </PageContent>
    </>
  )
}
