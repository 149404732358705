import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { ScrollArea } from '@mantine/core'
import { Card, OverlayDataShower } from '@/components/Elements'
import { GeneralPageLayoutShell } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { useForecastSession } from '@/features/forecast-session/hooks/useForecastSession'
import { ForecastProvider } from '@/features/forecast/contexts'
import { useForecastFilters, useInputsList } from '@/features/forecast/hooks'
import { useForecast } from '@/features/forecast/hooks/useForecast'
import { useForecastDispatch } from '@/features/forecast/hooks/useForecastDispatch'
import { forecastData } from '@/features/forecast/store'
import { useAppSelector } from '@/store'
import { DateUtils } from '@/utils'
import { ErrorInfo } from './ErrorInfo'
import { Header } from './Header'
import useStyles from './Style.styles'
import { Table } from './Table'
import { validationSchema } from './validation'

export const GeneralForecastContainer = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const dispatch = useDispatch()

  const { data, loading, error: dataError } = useAppSelector((state) => state.forecast.data)

  const { filters, onFiltersChange } = useForecastFilters()

  const { period } = filters

  const onFetch = () => {
    const { period } = filters

    const [start, end] = period

    const params = {
      date_start: DateUtils.formatDateToServerMonthYearFormat(start),
      date_end: DateUtils.formatDateToServerMonthYearFormat(end),
    }

    dispatch(forecastData.getData({ params }))
  }

  const forecastSession = useForecastSession({ period })

  const { list: inputsData, getInputDataById } = useInputsList()

  const forecastDispatching = useForecastDispatch()

  const forecast = useForecast({
    formConfig: {
      resolver: yupResolver(validationSchema),
    },
    initialData: data,
    inputsData,
    state: { loading },
    period,
    onFetch,
    session: forecastSession,
    forecastDispatching,
  })

  const {
    values: { sections },
    dates,
  } = forecast

  useEffect(() => {
    return () => {
      dispatch(forecastData.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    onFetch()
  }, [filters])

  const {
    saving,
    error: saveError,
    step: { submitting: stepSubmitting },
  } = forecastSession.state

  const { goalsResetSubmitting } = forecastDispatching.goalsReset

  const isLoading = useMemo(() => {
    return loading || saving || stepSubmitting || goalsResetSubmitting
  }, [loading, saving, stepSubmitting, goalsResetSubmitting])

  return (
    <ForecastProvider {...forecast}>
      <OverlayDataShower
        className={classes.root}
        isLoading={isLoading}
        isFailed={dataError}
        error={dataError}
      >
        <GeneralPageLayoutShell
          grow
          header={<Header filters={filters} onFiltersChange={onFiltersChange} />}
        >
          <ErrorInfo error={saveError} />

          <Card px={0} py={0} className={'overflow-visible h-full'}>
            <ScrollArea
              className={'!absolute inset-0'}
              styles={{
                scrollbar: {
                  zIndex: 3,
                },
              }}
            >
              <Table
                data={!loading ? sections : []}
                dates={dates}
                getInputDataById={getInputDataById}
              />
            </ScrollArea>
          </Card>
        </GeneralPageLayoutShell>
      </OverlayDataShower>
    </ForecastProvider>
  )
}
