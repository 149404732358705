import { createStyles } from '@mantine/core'

const sizes = {
  xs: 30,
  sm: 36,
  md: 42,
  lg: 50,
  xl: 60,
}

export default createStyles((theme, _params, getRef) => {
  const defaultSize = 'sm'
  const defaultPaddingLeft = theme.fn.size({ size: 'xs', sizes })

  const iconWidth = 12
  const iconLeft = 4

  const rightSectionWidth = 16
  const rightSectionRight = 4

  const calcSize = theme.fn.size({ size: defaultSize, sizes })

  return {
    wrapper: {
      minHeight: theme.fn.size({ size: defaultSize, sizes }),
    },

    root: {},

    input: {
      // paddingLeft: `calc(${calcSize}px / 3 - 2px)`,
      paddingLeft: iconLeft,
      paddingRight: 20,
    },

    withIcon: {
      paddingLeft: 16,
    },

    icon: {
      width: iconWidth,
      left: iconLeft,
    },

    rightSection: {
      width: rightSectionWidth,
      right: rightSectionRight,
    },
  }
})
