import React from 'react'
import { Badge as MantineBadge, BadgeProps as MantineBadgeProps } from '@mantine/core'
import useStyles from './Badge.styles'

export interface BadgeProps extends MantineBadgeProps {
  children: React.ReactNode
}

export const Badge = ({ children, ...props }: BadgeProps) => {
  const { classes } = useStyles()

  return (
    <>
      <MantineBadge classNames={classes} {...props}>
        {children}
      </MantineBadge>
    </>
  )
}
