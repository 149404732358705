import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })

  return {
    root: {
      backgroundColor: colors.background,
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },

    navs: {},
  }
})
