import React from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleGrid, Text } from '@mantine/core'
import fb from '@/assets/images/integration-systems/facebook.svg'
import google from '@/assets/images/integration-systems/google-system-row.png'
import klaviyo from '@/assets/images/integration-systems/klaviyo-brand.png'
import shopify from '@/assets/images/integration-systems/shopify.svg'
import { BasicCard, SquareBg } from '@/features/landing/components/Elements'
import useStyles from './Integrations.styles'

export const Integrations = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const data = [
    {
      icon: shopify,
      text: t('landing.integrations-section.integration-1.text'),
    },
    {
      icon: fb,
      text: t('landing.integrations-section.integration-2.text'),
    },
    {
      icon: google,
      text: t('landing.integrations-section.integration-3.text'),
    },
    {
      icon: klaviyo,
      text: t('landing.integrations-section.integration-4.text'),
    },
  ]

  return (
    <div className={classes.wrapper}>
      <SquareBg />

      <SimpleGrid
        cols={2}
        spacing={38}
        breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
        style={{ marginTop: 50, marginBottom: 50 }}
      >
        {data.map(({ icon, text }, i) => (
          <BasicCard key={i} className={'flex-col'}>
            <div className={classes.itemIcon}>
              <img alt={'integration'} src={icon} />
            </div>

            <div>
              <Text component={'p'}>{text}</Text>
            </div>
          </BasicCard>
        ))}
      </SimpleGrid>
    </div>
  )
}
