import React from 'react'
import { useTranslation } from 'react-i18next'
import { INPUT_TYPE } from '@/features/input/consts/input'
import { InputModel } from '@/features/input/types'
import { EditControl, RemoveControl } from './Controls'

interface IProps {
  config?: {
    edit: boolean
    remove: boolean
  }
  input: InputModel
  editControlProps?: any
  removeControlProps?: any
}

const defaultConfig = {
  edit: false,
  remove: false,
}

export const Action = ({ input, config, editControlProps, removeControlProps }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  const { type } = input

  const isModifiable = type !== INPUT_TYPE.DEFAULT

  return (
    <div className={'flex gap-4 items-center'}>
      {config?.edit && isModifiable && <EditControl input={input} {...editControlProps} />}

      {config?.remove && isModifiable && <RemoveControl input={input} {...removeControlProps} />}
    </div>
  )
}
