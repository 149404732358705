import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@mantine/core'
import { Alert } from '@/components/Elements'

interface IProps {
  isLoading: boolean
  isFailed: boolean
  error: any
  children: React.ReactNode
  errorContainerClass?: string
  isFetched: boolean
  center?: boolean
  loaderWrapClass?: string
}

export const DataShower = ({
  isLoading,
  isFailed = false,
  error,
  children,
  errorContainerClass,
  isFetched = false,
  center = true,
  loaderWrapClass,
}: IProps) => {
  const { t } = useTranslation()

  if (isLoading)
    return (
      <div
        className={classNames({
          'text-center': center,
          [`${loaderWrapClass}`]: loaderWrapClass,
        })}
      >
        <Loader />
      </div>
    )

  if (isFailed) {
    return (
      <div className={errorContainerClass}>
        <Alert type={'error'}>{error || t('error')}</Alert>
      </div>
    )
  }

  return <React.Fragment>{isFetched ? children : null}</React.Fragment>
}
