import axios from 'axios'
import { DefaultForecastSessionDTO } from './dto'

class ForecastSessionAPI {
  saveForecastSession = async (data: DefaultForecastSessionDTO) => {
    return axios.post(`api/forecast-session/save`, data)
  }
}

export const forecastSessionAPI = new ForecastSessionAPI()
