import React from 'react'
import { useTranslation } from 'react-i18next'
import { INTEGRATION_SYSTEM_STATUS } from '@/features/integration/consts/system'
import { integrationSystemHelper } from '@/features/integration/helpers'

export const useIntegrationSystem = () => {
  const { t } = useTranslation()

  const isSetupStatus = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isSetup(status)
  }

  const isNewStatus = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isNew(status)
  }

  const isEnabledStatus = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isEnabled(status)
  }

  const isCollectingDataStatus = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isCollectingData(status)
  }

  const isInProgressStatus = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isInProgress(status)
  }

  const isSyncStartAllow = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isSyncStartAllow(status)
  }

  const isSyncStartVisible = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isSyncStartVisible(status)
  }

  const isStatusOneOfError = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isOneOfError(status)
  }

  const isRemoveAllow = (status: INTEGRATION_SYSTEM_STATUS) => {
    return !isInProgressStatus(status)
  }

  const isSyncActive = (status: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.isSyncActive(status)
  }

  return {
    isSetupStatus,
    isNewStatus,
    isEnabledStatus,
    isCollectingDataStatus,
    isInProgressStatus,
    isSyncStartAllow,
    isSyncStartVisible,
    isRemoveAllow,
    isSyncActive,
  }
}
