import React from 'react'
import { useTranslation } from 'react-i18next'
import { List } from '@mantine/core'
import { Anchor } from '@/components/Elements'

interface IProps {
  example?: string
}

export const ImportInstruction = ({ example }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <List type={'ordered'}>
        <List.Item>
          {t('download')}{' '}
          <Anchor fw={'600'} target="_blank" rel="noopener noreferrer" href={example}>
            {t('example')}
          </Anchor>
        </List.Item>
        <List.Item>{t('import.instruction.point-2')}</List.Item>
        <List.Item>{t('upload_file')}</List.Item>
      </List>
    </>
  )
}
