import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { ForecastSectionFormValue } from '@/features/forecast/types'
import { SectionEditContainer } from './SectionEditContainer'

interface IProps {
  section: ForecastSectionFormValue
  visible: boolean
  onClose: () => void
  onSave: (data: any) => void
  onRemove: () => void
}

export const SectionEdit = ({ section, visible, onClose, onSave, onRemove }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal centered size={'sm'} opened={visible} onClose={onClose} title={t('edit_section')}>
        <SectionEditContainer
          section={section}
          onClose={onClose}
          onSave={onSave}
          onRemove={onRemove}
        />
      </Modal>
    </>
  )
}
