import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })

  return {
    root: {
      position: 'relative',
      paddingTop: 80,
      paddingBottom: 100,

      [theme.fn.smallerThan('md')]: {
        paddingTop: 40,
        paddingBottom: 60,
      },
    },

    primary: {
      backgroundColor: colors.background,
    },
  }
})
