import React from 'react'
import {
  ForecastActions,
  ForecastProps,
  ForecastState,
  UseForecastCoreProps,
  UseForecastCoreReturn,
} from './types'
import { useForecastForm } from './useForecastForm'
import { useHistory } from './useHistory'
import { useTable } from './useTable'

export const useForecastCore = ({
  formConfig,
  ...props
}: UseForecastCoreProps): UseForecastCoreReturn => {
  const history = useHistory({})

  const { form, onHandleSubmit } = useForecastForm({ formConfig })

  const table = useTable({})

  const { isSubmitting, isValid } = form.formState

  const actions: ForecastActions = {
    onHandleSubmit,
  }

  const state: ForecastState = {
    isSubmitting,
    isValid,
  }

  const forecast: ForecastProps = {
    state,
    actions,
  }

  return {
    form,
    forecast,
    table,
    history,
  }
}
