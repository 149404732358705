import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { Title } from '@mantine/core'
import { Card, MetaPagination, OverlayDataShower } from '@/components/Elements'
import { GeneralPageLayoutShell } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { KEYWORDS_MIN_LENGTH } from '@/const/form'
import { GeneralHeader } from '@/features/input/components/Inputs/Layouts'
import { GeneralInputsTable } from '@/features/input/components/Inputs/Tables'
import { useGeneralInputsQueryParam } from '@/features/input/hooks'
import { inputInputsList } from '@/features/input/store'
import { InputModel } from '@/features/input/types'
import { RootState } from '@/store'

interface IProps {
  list: InputModel[]
  loading: boolean
  error: any
  meta: any
}

const GeneralInputsContainerComponent = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { page, onPageChange, filters, onFiltersChange } = useGeneralInputsQueryParam()

  const [syncCount, forceUpdate] = React.useReducer((x: number) => x + 1, 0)

  useEffect(() => {
    return () => {
      dispatch(inputInputsList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    fetchList()
  }, [page, filters, syncCount])

  const syncData = () => {
    forceUpdate()
  }

  const fetchList = () => {
    const { keywords, ...restFilters } = filters

    const params = {
      page: page,
      sort: [{ id: 'created_at', desc: true }],
      filters: {
        ...restFilters,
        keywords: keywords.length >= KEYWORDS_MIN_LENGTH ? keywords : '',
      },
    }

    dispatch(inputInputsList.getList({ params }))
  }

  const onFilterFormChange = (values: any) => {
    onFiltersChange(values)
  }

  const onEditSuccess = (data: InputModel) => {
    dispatch(inputInputsList.updateListItem(data))
  }

  return (
    <GeneralPageLayoutShell
      header={
        <GeneralHeader
          filters={filters}
          onFiltersChange={onFilterFormChange}
          onAddSuccess={syncData}
        />
      }
    >
      <Title order={3} mb={'md'}>
        {t('inputs_and_metrics')}
      </Title>

      <OverlayDataShower isLoading={props.loading} isFailed={!!props.error} error={props.error}>
        <Card px={0} py={0}>
          <GeneralInputsTable
            keys={['title', 'description', 'type', 'date', 'action']}
            items={props.list}
            loading={props.loading}
            actionProps={{ config: { edit: true, remove: true } }}
            editControlProps={{ onSuccess: onEditSuccess }}
            removeControlProps={{ onSuccess: syncData }}
          />
        </Card>

        <MetaPagination meta={props.meta} page={page} onChange={onPageChange} className={'mt-4'} />
      </OverlayDataShower>
    </GeneralPageLayoutShell>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta } = state.input.inputs.list
  return {
    list,
    loading,
    error,
    meta,
  }
}

export const GeneralInputsContainer = connect(mapStateToProps)(GeneralInputsContainerComponent)
