import React from 'react'
import { useTranslation } from 'react-i18next'
import { integrationFacebookUrlFetchPA } from '@/features/integration/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useIntegrationFacebookUrlFetch = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onFetch = async () => {
    try {
      const data = await onAsyncDispatch(integrationFacebookUrlFetchPA.request)

      return data?.data
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onFetch,
    loading,
  }
}
