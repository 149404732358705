import axios from 'axios'

class ForecastInputsAPI {
  getForecastInputs = async (params: any = {}) => {
    return axios.get(`api/forecast-inputs`, {
      params,
    })
  }
}

export const forecastInputsAPI = new ForecastInputsAPI()
