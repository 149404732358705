import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT, LANDING_HEADER_HEIGHT, LANDING_HEADER_HEIGHT_XS } from '@/const/layout'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: LANDING_HEADER_HEIGHT,

    [theme.fn.smallerThan('md')]: {
      height: LANDING_HEADER_HEIGHT_XS,
    },

    display: 'flex',
    borderBottom:
      theme.colorScheme === 'dark'
        ? `1px solid ${theme.colors.dark[5]}`
        : `1px solid ${theme.colors.gray[2]}`,
    padding: `0 ${theme.spacing.xl}px`,
  },
}))
