export const EN = {
  login: 'Login',
  sign_up: 'Sign Up',
  'message.error-fallback': 'Error occurred',
  refresh: 'Refresh',
  'email_verification.success': 'Verification passed successfully',
  welcome_back: 'Welcome back',
  dont_have_account: 'Don’t have an account',
  or: 'or',
  'field.error.required': 'This field is required',
  'field.error.invalid': 'This field is invalid',
  'field.error.password.length': 'The password must be at least {{count}} characters',
  'field.error.password.no_match': 'Passwords do not match',
  error: 'Error',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confirm password',
  forgot_password: 'Forgot password',
  already_have_account: 'Already have an account',
  required: 'Required',
  reset_password: 'Reset password',
  remember_it: 'Remember it',
  reset: 'Reset',
  back_to: 'Back to',
  terms_and_conditions: 'Terms and conditions',
  'terms-and-conditions': 'Terms and Conditions',
  logout: 'Logout',
  name: 'Name',
  'confirmation-email-sent':
    'We’ve sent a verification email to your inbox. Confirm to start exploring the platform!',
  send_confirmation_email_to: 'Send a confirmation email to',
  success: 'Success',
  back: 'Back',
  next: 'Next',
  first_name: 'First name',
  last_name: 'Last name',
  no_options: 'No options',
  description: 'Description',
  start_date: 'Start date',
  end_date: 'End date',
  add: 'Add',
  saved_successfully: 'Saved successfully',
  phone: 'Phone',
  upload_photo: 'Upload photo',
  delete_photo: 'Delete photo',
  delete: 'Delete',
  'file.max_size.error': 'The file must not be greater than {{text}}',
  deleted_successfully: 'Deleted successfully',
  profile: 'Profile',
  yes: 'Yes',
  no: 'No',
  empty: 'Empty',
  not_filled: 'Not filled',
  no_data: 'No data',
  edit_profile: 'Edit profile',
  details: 'Details',
  cancel: 'Cancel',
  save: 'Save',
  skill_already_exist: 'Skill already exist',
  no_info: 'No info',
  from: 'From',
  to: 'To',
  'field.error.date.after-or-equal': 'The value must be a date after or equal to {{date}}.',
  today: 'Today',
  'field.error.date.after': 'The value must be a date after {{date}}.',
  status: 'Status',
  pending: 'Pending',
  active: 'Active',
  collapse: 'Collapse',
  expand: 'Expand',
  date: 'Date',
  confirmation: 'Confirmation',
  please_confirm: 'Please confirm',
  confirm: 'Confirm',
  sent_successfully: 'Sent successfully',
  action: 'Action',
  'field.error.time.after': 'The value must be a time after {{date}}.',
  removed_successfully: 'Removed successfully',
  settings: 'Settings',
  password_changed_successfully: 'Password changed successfully',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  confirm_new_password: 'Confirm new password',
  you: 'You',
  attachments: 'Attachments',
  no_messages: 'No messages',
  unread_messages: 'Unread messages',
  write_a_message: 'Write a message',
  select_chat_to_start_messaging: 'Select a chat to start messaging',
  load_more: 'Load more',
  new_message: 'New message',
  download: 'Download',
  pay: 'Pay',
  invoice: 'Invoice',
  home: 'Home',
  register_terms_label: 'I have read and accept the',
  register_terms_text:
    'I have read and agree to the <link1>{{name}}</link1> of the Spark Forecast Beta Testing Program',
  dashboard: 'Dashboard',
  chats: 'Chats',
  general_info: 'General info',
  deactivation: 'Deactivation',
  delete_account: 'Delete account',
  account_deleted_successfully: 'Account deleted successfully',
  dark: 'Dark',
  light: 'Light',
  'color-switch-to': 'Switch to {{ scheme }}',
  log_in_to_your_account: 'Log In to your account',
  registration: 'Registration',
  'forgot_password.note':
    'Please enter the email associated with your account and we’ll send you a link to reset your password.',
  forecast: 'Forecast',
  daily_tracker: 'Daily tracker',
  input: 'Input',
  inputs: 'Inputs',
  billing: 'Billing',
  integrations: 'Integrations',
  inputs_and_metrics: 'Inputs and Metrics',
  add_custom_item: 'Add custom item',
  title: 'Title',
  type: 'Type',
  created_date: 'Created date',
  email_verification: 'Email verification',
  'email-verification-label': 'Thanks for signing up!',
  select_period: 'Select period',
  add_custom_calculation: 'Add custom calculation',
  add_custom_formula: 'Add custom formula',
  add_value: 'Add value',
  'input.type.default': 'Data query',
  'input.type.custom': 'Custom formula',
  'input.type.constant': 'Custom value',
  'input.function_type.sum': 'Sum',
  'input.function_type.avg': 'Avg',
  'input.action.multiplication': 'Multiplication',
  'input.action.division': 'Division',
  'input.action.addition': 'Addition',
  'input.action.subtraction': 'Subtraction',
  function_type: 'Function type',
  metric: 'Metric',
  metric_type: 'Metric type',
  value: 'Value',
  'field.error.value.between': 'The value must be between {{min}} and {{max}}',
  edit_custom_calculation: 'Edit custom calculation',
  edit_custom_formula: 'Edit custom formula',
  edit_value: 'Edit value',
  edit: 'Edit',
  types: 'Types',
  search: 'Search',
  'min-characters.count': 'Min. {{count}} characters',
  note: 'Note',
  goals: 'Goals',
  actual: 'Actual',
  period: 'Period',
  add_section: 'Add section',
  discard_changes: 'Discard changes',
  save_changes: 'Save changes',
  remove: 'Remove',
  edit_section: 'Edit section',
  original_forecast: 'Original forecast',
  'field.sections.error.required': 'Must have at least one section',
  goal: 'Goal',
  start_period: 'Start period',
  sync_all_data: 'Sync all data',
  sync: 'Sync',
  add_account: 'Add account',
  added_successfully: 'Add successfully',
  account: 'Account',
  page: 'Page',
  sync_status: 'Sync status',
  last_sync: 'Last sync',
  last_run: 'Last run',
  info: 'Info',
  remove_account: 'Remove account',
  inactive: 'Inactive',
  failed: 'Failed',
  last_status: 'Last status',
  all_rights_reserved: 'All rights reserved',
  get_started: 'Get started',
  'landing.hero-title-start': 'Easy Forecasting for your',
  'landing.hero-title-end': 'Business Growth',
  'landing.hero-description': 'Unlock the secret to scaling your business',
  enter_your_email: 'Enter your email',
  integrates_with: 'Integrates with',
  'landing.product-section.title': 'Daily tracking with API integrations',
  product: 'Product',
  'landing.product-section.metrics.label': 'KPI & Metrics tracking',
  'landing.product-section.metrics.title': 'KPI & Metrics tracking',
  'landing.product-section.metrics.text':
    'Track the most important KPIs and metrics for your business with ease.  Set up custom metrics and custom formulas to get a better understanding of your business performance.',
  'landing.product-section.forecast.label': 'Financial & Sales Forecasting',
  'landing.product-section.forecast.title': 'Financial & Sales Forecasting',
  'landing.product-section.forecast.text':
    'Uses historical data to automatically project and plan your financial, sales and adspend forecasts, removingthe need for guesswork.',
  'landing.product-section.goals.label': 'Goal Planning & Daily Pacing tracker',
  'landing.product-section.goals.title': 'Goal Planning & Daily Pacing tracker',
  'landing.product-section.goals.text':
    'Keep track of your progress and make adjustments as ended, so you can stay on top of your performance and make data-driven decisions.',
  setup: 'Setup',
  ad_account: 'Ad account',
  setup_account: 'Setup account',
  business_account: 'Business account',
  new: 'New',
  enabled: 'Enabled',
  collecting_data: 'Collecting data',
  in_progress: 'In progress',
  sync_started_successfully: 'Sync started successfully',
  sync_completed: 'Sync completed',
  features: 'Features',
  'landing.features-section.title': 'More features to power-up your goals',
  'landing.features-section.feature-1.title': 'Realtime analytics',
  'landing.features-section.feature-1.text':
    "Measure your KPI's in real time so you can make better and smarter decisions.",
  'landing.features-section.feature-2.title': 'Reach your goals',
  'landing.features-section.feature-2.text':
    "Automate your goals using historical data and ensure you're pacing towards them with daily tracking.",
  'landing.features-section.feature-3.title': 'No more spreadsheets',
  'landing.features-section.feature-3.text':
    'Track your forecast without the hassle of spreadsheets.',
  'landing.features-section.feature-4.title': 'Optimize your Growth',
  'landing.features-section.feature-5.text':
    'Keep your team accountable and track your pacing on a daily basis.',
  'landing.cta-v2-section.title':
    'Want to be chosen for a free trial? Sign up to be notified when we start testing.',
  'landing.integrations-section.title': 'Integrated with all tools you already know and love',
  'landing.integrations-section.integration-1.text':
    'Lorem ipsum dolor sit amet con adipiscing elit diam quis id fringilla nulla orci sit cum pharetra ven.',
  'landing.integrations-section.integration-2.text':
    'Lorem ipsum dolor sit amet con adipiscing elit diam quis id fringilla nulla orci sit cum pharetra ven.',
  'landing.integrations-section.integration-3.text':
    'Lorem ipsum dolor sit amet con adipiscing elit diam quis id fringilla nulla orci sit cum pharetra ven.',
  'landing.integrations-section.integration-4.text':
    'Lorem ipsum dolor sit amet con adipiscing elit diam quis id fringilla nulla orci sit cum pharetra ven.',
  how_it_works: 'How it works',
  'landing.how_works-section.title': 'Create an account as easy as 1, 2, 3',
  'landing.how_works-section.step-1.title': 'Create account',
  'landing.how_works-section.step-1.text': 'No credit card needed!',
  'landing.how_works-section.step-2.title': 'Integrate your Data',
  'landing.how_works-section.step-2.text':
    'Easy API integrations. Or upload your historical data easily with a csv file.',
  'landing.how_works-section.step-3.title': 'Start Forecasting!',
  'landing.how_works-section.step-3.text':
    'Start setting up your forecast and creating your goals!',
  testimonials: 'Testimonials',
  'landing.testimonials-section.title': 'Don’t take our word for it. See what our clients say.',
  'landing.cta-v3-section.title': 'Ready to get started?\nCreate an account today',
  api_key: 'Api key',
  apply: 'Apply',
  change: 'Change',
  metric_id: 'Metric id',
  error_sync: 'Error sync',
  error_first_sync: 'Error first sync',
  error_account: 'Error account',
  error_unknown: 'Error unknown',
  domain: 'Domain',
  store_name: 'Store name',
  current_month: 'Current month',
  manager_account: 'Manager account',
  client_account: 'Client account',
  monthly_statistics: 'Monthly statistics',
  inputs_field_required: 'This inputs field is required',
  privacy_policy: 'Privacy Policy',
  'field.error.integer': 'This field must be an integer',
  day: 'Day',
  month: 'Month',
  adjust_forecast: 'Adjust forecast',
  'forecast-session.adjust-step.type.mom': 'MoM',
  'forecast-session.adjust-step.type.total': 'Total',
  percent: 'Percent',
  range: 'Range',
  reset_to_original: 'Reset to original',
  revert_to_original: 'Revert to original',
  'confirmation.action.warning': 'This action cannot be undone. Please confirm',
  'adjust-forecast.confirmation.revert.warning':
    'Are you sure you want to revert all data to the original data? This will remove any manual edits and adjustments made.',
  import: 'Import',
  start_successfully: 'Start successfully',
  mode: 'Mode',
  'import.mode.full': 'Full',
  'import.mode.replace': 'Replace',
  'import.mode.only_new': 'Only new',
  upload_data: 'Upload data',
  example: 'Example',
  'import.instruction.point-2': 'Fill out the fields and download the CSV file',
  upload_file: 'Upload file',
  last_import: 'Last import',
  file: 'File',
  upload: 'Upload',
  upload_data_completed: 'Upload data completed',
  'import.mode.full.description': 'Remove all and upload new data',
  'import.mode.replace.description': 'Replace exists data',
  'import.mode.only_new.description': 'Create only missing data',
}
