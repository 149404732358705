import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { Button } from '@/components/Elements'

interface IProps {
  onGoalsReset: () => void
  loading?: boolean
}

export const GoalsReset = ({ onGoalsReset, loading = false }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ConfirmationControl
        onConfirm={onGoalsReset}
        render={({ onOpen }) => (
          <Button variant="outline" color="red" onClick={onOpen} loading={loading}>
            {t('revert_to_original')}
          </Button>
        )}
        message={t('adjust-forecast.confirmation.revert.warning')}
      />
    </>
  )
}
