import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {},

    inner: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },

    aboutContent: {
      display: 'flex',
      maxWidth: 415,
      flexDirection: 'column',
      alignItems: 'flex-start',

      [theme.fn.smallerThan('sm')]: {
        maxWidth: '100%',
      },
    },

    mediaBlock: {
      justifyContent: 'flex-end',
    },
  }
})
