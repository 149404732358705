import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useImportDataQuery } from '@/features/import/hooks'

interface IProps {
  deps?: any[]
  onComplete?: () => Promise<void>
  options?: {
    delay: number
  }
}

const defaultOptions = {
  delay: 1500,
}

export const useImportDataEffect = ({ deps = [], onComplete, options }: IProps) => {
  const { t } = useTranslation()

  options = {
    ...defaultOptions,
    ...options,
  }

  const { state, onFetch } = useImportDataQuery()

  const onDispatch = async (options: { init: boolean } = { init: false }) => {
    try {
      const { init } = options

      const data: any = await onFetch()

      if (data?.active && onCheck) {
        onCheck()
      } else {
        if (!init) {
          await onComplete?.()
        }
      }
    } catch (e: any) {}
  }

  const onCheck = useCallback(debounce(onDispatch, options.delay), [onComplete])

  useEffect(() => {
    return () => {
      onCheck.cancel()
    }
  }, [onCheck])

  const onInit = async () => {
    onDispatch({ init: true })
  }

  useEffect(() => {
    onInit()
  }, [...deps])

  return {
    state,
    onInit,
    onCheck,
  }
}
