import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { forecastSessionAdjustStepAPI } from '@/features/forecast-session/api'
import { AdjustStepForecastSessionStepDTO } from '@/features/forecast-session/api/dto'
import { ForecastSessionAdjustStepDispatchResponse } from '@/features/forecast-session/types'
import {
  FORECAST_SESSION_ADJUST_STEP_SUBMIT_FAILED,
  FORECAST_SESSION_ADJUST_STEP_SUBMIT_REQUEST,
  FORECAST_SESSION_ADJUST_STEP_SUBMIT_SUCCESS,
} from './actionTypes'

type ArgsType = {
  params: AdjustStepForecastSessionStepDTO
}

export const forecastSessionAdjustStepSubmitPA = createPromiseAction(
  FORECAST_SESSION_ADJUST_STEP_SUBMIT_REQUEST,
  FORECAST_SESSION_ADJUST_STEP_SUBMIT_SUCCESS,
  FORECAST_SESSION_ADJUST_STEP_SUBMIT_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* submit(
  action: PromiseAction<string, ArgsType, ForecastSessionAdjustStepDispatchResponse>
) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(
      forecastSessionAdjustStepAPI.adjustStepForecastSession,
      params
    )
    const { data } = response

    yield put(forecastSessionAdjustStepSubmitPA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(forecastSessionAdjustStepSubmitPA.request, submit)
}
