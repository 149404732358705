import { all } from 'redux-saga/effects'
import { dataSaga } from './data/saga'
import { fetchSaga } from './fetch/saga'
import { forecastInputsSaga } from './forecast-inputs/sagas'
import { inputsSaga } from './inputs/sagas'
import { resetGoalsSaga } from './reset-goals/sagas'
import { updateSaga } from './update/saga'

export function* forecastSaga() {
  yield all([
    dataSaga(),
    fetchSaga(),
    updateSaga(),
    inputsSaga(),
    forecastInputsSaga(),
    resetGoalsSaga(),
  ])
}
