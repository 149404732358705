import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'
import { useFormSubmit } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: any
}

export const AccountForm = ({ onCancel, initialValues = {}, ...props }: IProps) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      domain: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInputController
            control={control}
            name={'domain'}
            id={'domain'}
            label={t('store_name')}
            placeholder={t('store_name')}
            mb={'md'}
          />

          <div className={'mt-2'}>
            <Group position={'right'}>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
