import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SignUpCTA } from '@/features/landing/components/CTA'
import { Container, Section, SkewBg } from '@/features/landing/components/Elements'
import useStyles from './CTAv2Section.styles'
import { ShapesSection, TitleSection } from './Sections'

export const CTAv2Section = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Section className={'p-0'}>
      <SkewBg primary={false} />

      <Container size={'xl'} py={0}>
        <div className={classes.wrapper}>
          <Grid align="center" gutter={60} gutterMd="md">
            <Grid.Col md={6}>
              <TitleSection />
            </Grid.Col>

            <Grid.Col md={6}>
              <SignUpCTA />
            </Grid.Col>
          </Grid>

          <ShapesSection />
        </div>
      </Container>
    </Section>
  )
}
