import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import { importAPI } from '@/features/import/api'
import { FormTypes } from '@/types'
import { importData } from '../data/slice'
import { IMPORT_FETCH_FAILED, IMPORT_FETCH_REQUEST, IMPORT_FETCH_SUCCESS } from './actionTypes'

type ArgsType = {}

export const importFetchPA = createPromiseAction(
  IMPORT_FETCH_REQUEST,
  IMPORT_FETCH_SUCCESS,
  IMPORT_FETCH_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* fetch(action: PromiseAction<string, ArgsType, any>) {
  try {
    yield put(importData.getData({}))

    const response: AxiosResponse = yield call(importAPI.getImport)
    const { data } = response

    yield put(importFetchPA.success({}))

    yield put(importData.getDataSuccess({ data: data?.data }))

    resolvePromiseAction(action, { ...data?.data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    yield put(importFetchPA.failure(error))

    yield put(importData.getDataError(error))

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* fetchSaga() {
  yield takeLatest(importFetchPA.request, fetch)
}
