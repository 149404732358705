import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FileInput as MantineFileInput,
  FileInputProps as MantineFileInputProps,
} from '@mantine/core'
import { FormUtils } from '@/utils'

export interface FileInputProps extends MantineFileInputProps {
  fieldRef?: any
  translateParams?: object
  meta?: any
}

export const FileInput = ({ meta, fieldRef, translateParams, ...props }: FileInputProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return <MantineFileInput {...props} ref={fieldRef} error={showError ? getErrorMessage() : null} />
}
