import React from 'react'
import useStyles from './Section.styles'

interface IProps {
  children: React.ReactNode
  primary?: boolean
  className?: string
}

export const Section = ({ children, primary = true, className }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [`${classes.primary}`]: primary,
        [`${className}`]: className,
      })}
    >
      {children}
    </div>
  )
}
