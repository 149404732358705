import { Row, RowData, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from '@mantine/core'
import { GeneralTable } from '@/components/Elements'

interface IProps {
  columns: any[]
  data: any[]
  loading: boolean
  tableProps?: TableProps
  lastRef: any
  getRowClassName: (row: Row<RowData>) => string
}

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    getRowClassName: (row: Row<TData>) => string
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    cellClassName?: string
  }
}

export const TableView = ({
  columns,
  data,
  loading,
  tableProps,
  lastRef,
  getRowClassName,
}: IProps) => {
  const { t } = useTranslation()

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      getRowClassName,
    },
  })

  return (
    <GeneralTable {...tableProps}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const __headerProps = (header.column.columnDef as any)?.__headerProps

              return (
                <th key={header.id} {...__headerProps} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.length > 0 ? (
          table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={table.options.meta?.getRowClassName(row)}
              ref={index === table.getRowModel().rows.length - 1 ? lastRef : null}
            >
              {row.getVisibleCells().map((cell) => {
                const cellClassName = (cell.column.columnDef.meta as any)?.cellClassName

                return (
                  <td key={cell.id} className={cellClassName}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length}>
              {!loading ? t('empty') : <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
            </td>
          </tr>
        )}
      </tbody>
    </GeneralTable>
  )
}
