import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Navbar, ScrollArea, Space } from '@mantine/core'
import { Footer } from './Footer'
import { GeneralLinks } from './GeneralLinks'
import { HelpLinks } from './HelpLinks'

interface IProps {
  onNavLinkClick?: () => void
}

export const NavbarContent = ({ onNavLinkClick }: IProps) => {
  const { t } = useTranslation()

  const onLinkClick = () => {
    onNavLinkClick && onNavLinkClick()
  }

  return (
    <>
      <Navbar.Section
        grow
        component={ScrollArea}
        mx="-xs"
        px="xs"
        styles={{
          viewport: {
            ['& > div']: {
              height: '100%',
            },
          },
        }}
      >
        <div className={'flex flex-col h-full'}>
          <Space h={'xs'} />
          <div className={'flex flex-col grow'}>
            <GeneralLinks onLinkClick={onLinkClick} />
          </div>
          <Box mt={'xs'}>
            <HelpLinks onLinkClick={onLinkClick} />
          </Box>
          <Space h={'xs'} />
        </div>
      </Navbar.Section>

      <Navbar.Section>
        <Footer />
      </Navbar.Section>
    </>
  )
}
