import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton, Modal } from '@/components/Elements'
import { usePopup } from '@/hooks'
import { AdjustForecastContainer } from './AdjustForecastContainer'

interface IProps {
  inputId: number
}

export const AdjustForecastControl = ({ inputId, ...props }: IProps) => {
  const { t } = useTranslation()

  const { visible, close, open } = usePopup()

  const onClose = () => {
    close()
  }

  return (
    <>
      <ActionIconButton name={'edit'} onClick={open} iconProps={{ size: 14 }} />

      <Modal centered size={'lg'} opened={visible} onClose={onClose} title={t('adjust_forecast')}>
        <AdjustForecastContainer inputId={inputId} onClose={onClose} />
      </Modal>
    </>
  )
}
