import * as Yup from 'yup'
import { FILE_MAX_SIZE } from '@/const/form'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object().shape({
  mode: Yup.mixed().nullable().required('field.error.required'),
  file: Yup.mixed()
    .nullable()
    .required('field.error.required')
    .test('maxSize', 'file.max_size.error', (value) =>
      ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
    ),
})
