import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { INPUT_PERIOD, INPUT_TYPE } from '@/features/input/consts/input'
import { useInputModel } from '@/features/input/hooks'
import { useFormSubmit } from '@/hooks'
import { ConvertUtils } from '@/utils'
import { BaseSection } from './BaseSection'
import { ConstantInputSection } from './ConstantInputSection'
import { CustomInputSection } from './CustomInputSection'
import { validationSchema } from './validation'

const { percentageToNumber } = ConvertUtils.number

interface IProps {
  type: INPUT_TYPE
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: any
  isEdit?: boolean
}

export const InputForm = ({
  type,
  onCancel,
  initialValues = {},
  isEdit = false,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const baseDefaultValues = {
    name: '',
    description: '',
    type: type,
    symbol: null,
  }

  const customTypeDefaultValues = {
    ...baseDefaultValues,
    function_type: null,
    input_a_id: null,
    input_b_id: null,
    action: null,
  }

  const constantTypeDefaultValues = {
    ...baseDefaultValues,
    period: INPUT_PERIOD.DAY,
    value: '',
  }

  const defaultValues = useMemo(() => {
    let values = type === INPUT_TYPE.CUSTOM ? customTypeDefaultValues : constantTypeDefaultValues

    values = {
      ...values,
      ...initialValues,
    }

    return values
  }, [type, initialValues])

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const { isCustomPercentageInput } = useInputModel()

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      let inputTypeConstantValues = {}

      if (type === INPUT_TYPE.CONSTANT) {
        const { value } = data

        const isPercentageFormat = isCustomPercentageInput(data)
        const resultValue = isPercentageFormat ? percentageToNumber(value) : value
        inputTypeConstantValues = {
          ...inputTypeConstantValues,
          value: resultValue.toString(),
        }
      }

      const values = {
        ...data,
        ...inputTypeConstantValues,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <BaseSection />

          {type === INPUT_TYPE.CUSTOM && <CustomInputSection />}

          {type === INPUT_TYPE.CONSTANT && <ConstantInputSection />}

          <div className={'mt-2'}>
            <Group position={'right'}>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
