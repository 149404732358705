import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { useFormSubmit } from '@/hooks'
import { ForecastSectionsSection } from './Sections'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  initialValues?: any
  sectionsData?: ForecastSectionModel[]
}

export const SettingsForm = ({ initialValues = {}, sectionsData = [], ...props }: IProps) => {
  const { t } = useTranslation()

  const defaultValues = {
    sections: [],
    ...initialValues,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <ForecastSectionsSection sectionsData={sectionsData} />

          <Group position={'right'}>
            <Button loading={isSubmitting} type={'submit'} size={'xs'}>
              {t('save')}
            </Button>
          </Group>
        </form>
      </FormProvider>
    </>
  )
}
