import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Container, Section } from '@/features/landing/components/Elements'
import useStyles from './FeaturesSection.styles'
import { Features, TitleSection } from './Sections'

export const FeaturesSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Section className={classes.section}>
      <Container size={'xl'}>
        <TitleSection />

        <Box mt={'xl'}>
          <Features />
        </Box>
      </Container>
    </Section>
  )
}
