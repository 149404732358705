import React from 'react'
import { useTranslation } from 'react-i18next'
import { SectionForm } from '../../SectionForm'

interface IProps {
  onClose: () => void
  onSave: (data: any) => void
}

export const SectionAddContainer = ({ onClose, onSave }: IProps) => {
  const { t } = useTranslation()

  const onSubmit = async (values: any) => {
    onSave(values)
  }

  return (
    <>
      <SectionForm onSubmit={onSubmit} onCancel={onClose} />
    </>
  )
}
