import _ from 'lodash'
import { INTEGRATION_SYSTEM_STATUS } from '@/features/integration/consts/system'
import i18n from '@/i18n'

const {
  SETUP,
  NEW,
  ENABLED,
  COLLECTING_DATA,
  IN_PROGRESS,
  ERROR_SYNC,
  ERROR_FIRST_SYNC,
  ERROR_ACCOUNT,
  ERROR_UNKNOWN,
} = INTEGRATION_SYSTEM_STATUS

type OptionType = {
  label: string
  value: INTEGRATION_SYSTEM_STATUS
}

export const getStatuses = () => {
  return [
    {
      label: i18n.t('setup'),
      value: SETUP,
    },
    {
      label: i18n.t('new'),
      value: NEW,
    },
    {
      label: i18n.t('enabled'),
      value: ENABLED,
    },
    {
      label: i18n.t('collecting_data'),
      value: COLLECTING_DATA,
    },
    {
      label: i18n.t('in_progress'),
      value: IN_PROGRESS,
    },
    {
      label: i18n.t('error_sync'),
      value: ERROR_SYNC,
    },
    {
      label: i18n.t('error_first_sync'),
      value: ERROR_FIRST_SYNC,
    },
    {
      label: i18n.t('error_account'),
      value: ERROR_ACCOUNT,
    },
    {
      label: i18n.t('error_unknown'),
      value: ERROR_UNKNOWN,
    },
  ]
}

export const getStatus = (value: INTEGRATION_SYSTEM_STATUS | string): OptionType | any => {
  const options = getStatuses()
  return _.find(options, { value })
}

export const isSetup = (value: INTEGRATION_SYSTEM_STATUS | string): boolean => {
  return value === SETUP
}

export const isNew = (value: INTEGRATION_SYSTEM_STATUS | string): boolean => {
  return value === NEW
}

export const isEnabled = (value: INTEGRATION_SYSTEM_STATUS | string): boolean => {
  return value === ENABLED
}

export const isCollectingData = (value: INTEGRATION_SYSTEM_STATUS | string): boolean => {
  return value === COLLECTING_DATA
}

export const isInProgress = (value: INTEGRATION_SYSTEM_STATUS | string): boolean => {
  return value === IN_PROGRESS
}

export const isSyncStartAllow = (value: INTEGRATION_SYSTEM_STATUS): boolean => {
  return [NEW, ENABLED, ERROR_SYNC, ERROR_FIRST_SYNC, ERROR_UNKNOWN].includes(value)
}

export const isSyncStartVisible = (value: INTEGRATION_SYSTEM_STATUS): boolean => {
  return isSyncStartAllow(value) || [COLLECTING_DATA, IN_PROGRESS].includes(value)
}

export const isSyncActive = (value: INTEGRATION_SYSTEM_STATUS): boolean => {
  return isInProgress(value) || isCollectingData(value)
}

export const isOneOfError = (value: INTEGRATION_SYSTEM_STATUS): boolean => {
  return [ERROR_SYNC, ERROR_FIRST_SYNC, ERROR_ACCOUNT, ERROR_UNKNOWN].includes(value)
}
