import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { statisticsAPI } from '@/features/statistics/api'
import i18n from '@/i18n'
import { RequestUtils } from '@/utils'
import { statisticsDashboardEntries } from './slice'

function* getData({ payload: { params } }: PayloadAction<{ params: any }>) {
  try {
    const requestParams: any = RequestUtils.getMapRequestParams(params)

    const response: AxiosResponse = yield call(statisticsAPI.getDashboardStatistics, requestParams)

    const { data } = response

    yield put(statisticsDashboardEntries.getDataSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(statisticsDashboardEntries.getDataError(message))
  }
}

function* watchGetData() {
  yield takeLatest(statisticsDashboardEntries.getData, getData)
}

export function* entriesSaga() {
  yield all([fork(watchGetData)])
}
