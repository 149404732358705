import { combineReducers } from 'redux'
import data from './data/slice'
import inputs from './inputs/reducers'

const forecastReducer = combineReducers({
  data,
  inputs,
})

export default forecastReducer
