import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { INPUT_TYPE } from '@/features/input/consts/input'
import { InputDataLoader } from './InputDataLoader'
import { InputEditContainer } from './InputEditContainer'

interface IProps {
  id: number
  visible: boolean
  onClose: () => void
  onSuccess: (data: any) => void
  type?: INPUT_TYPE
}

export const InputEdit = ({ id, type, visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const title =
    type === INPUT_TYPE.CUSTOM
      ? t('edit_custom_formula')
      : type === INPUT_TYPE.CONSTANT
      ? t('edit_value')
      : t('edit')

  return (
    <>
      <Modal size={'xl'} opened={visible} onClose={onClose} title={title}>
        <div style={{ minHeight: '380px' }}>
          <InputDataLoader id={id}>
            <InputEditContainer onClose={onClose} onSuccess={onSuccess} />
          </InputDataLoader>
        </div>
      </Modal>
    </>
  )
}
