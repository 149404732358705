import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })
  const { background } = colors

  return {
    card: {
      minHeight: 544,
      borderRadius: theme.radius.xl,
      background: theme.fn.linearGradient(90, `${theme.white} 70%`, `${background} 70%`),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      [theme.fn.smallerThan('sm')]: {
        background: theme.fn.linearGradient(180, `${theme.white} 70%`, `${background} 70%`),
      },

      [theme.fn.smallerThan('md')]: {
        // minHeight: 'auto',
      },
    },
  }
})
