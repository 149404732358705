import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Container, Section, SkewBg } from '@/features/landing/components/Elements'
import useStyles from './ProductSection.styles'
import { ContentSection, TitleSection } from './Sections'

export const ProductSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Section primary={false}>
      <SkewBg />

      <Container size={1074}>
        <TitleSection />

        <Box mt={'xl'}>
          <ContentSection />
        </Box>
      </Container>
    </Section>
  )
}
