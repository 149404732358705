import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@mantine/core'
import { useIntegrationSystem } from '@/features/integration/hooks'
import { IntegrationSystemModel } from '@/features/integration/types'
import { AddAccountControl, SyncControl } from '../Controls'

interface IProps {
  data: IntegrationSystemModel | null
  config?: {
    addAccount?: boolean
    sync?: boolean
  }
  onAccountAdd: () => void
  addAccountControlProps?: any
  onSync: () => void
  syncControlProps?: any
}

const defaultConfig = {
  addAccount: false,
  sync: false,
}

export const TopBar = ({
  data,
  config,
  onAccountAdd,
  addAccountControlProps,
  onSync,
  syncControlProps,
}: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  const { disabled: syncDisabled, ...syncProps } = syncControlProps

  const { isSyncStartAllow } = useIntegrationSystem()

  return (
    <Flex justify={'center'}>
      {config.addAccount && <AddAccountControl onAdd={onAccountAdd} {...addAccountControlProps} />}

      {config.sync && (
        <SyncControl
          onSync={onSync}
          disabled={syncDisabled || (data && !isSyncStartAllow(data?.status))}
          {...syncProps}
        />
      )}
    </Flex>
  )
}
