import { yupResolver } from '@hookform/resolvers/yup'
import { DatesRangeValue } from 'mantine-dates-6'
import React, { FormEvent, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { FORECAST_SESSION_ADJUST_STEP_TYPE } from '@/features/forecast-session/consts/adjust-step'
import { useFormSubmit } from '@/hooks'
import { GoalsReset, PeriodSection, TypeSection } from './Sections'
import { validationSchema } from './validation'

type FormValues = {
  period: DatesRangeValue
  type: FORECAST_SESSION_ADJUST_STEP_TYPE
  percent: string
}

interface IProps {
  onCancel: () => void
  onSubmit: (data: FormValues) => Promise<void>
  initialValues?: {
    period?: DatesRangeValue
  }
  onGoalsReset: (data: { period: DatesRangeValue }) => Promise<void>
}

export const AdjustForecastForm = ({ initialValues, onCancel, ...props }: IProps) => {
  const { t } = useTranslation()

  const [isResetMode, setIsResetMode] = useState<boolean>(false)

  const defaultPeriod = initialValues?.period || [null, null]

  const defaultValues: FormValues = {
    type: FORECAST_SESSION_ADJUST_STEP_TYPE.MOM,
    period: defaultPeriod,
    percent: '',
    ...initialValues,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    context: { isResetMode },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const { error: resetSubmitError, onSubmit: onFormResetSubmit } = useFormSubmit({
    submit: props.onGoalsReset,
    setError,
  })

  const errorData = useMemo(() => {
    return submitError || resetSubmitError
  }, [submitError, resetSubmitError])

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  const onSaveSubmit = async (e: FormEvent<HTMLFormElement>) => {
    await flushSync(async () => {
      setIsResetMode(false)
    })

    await handleSubmit(async (data) => await onSubmit(data))(e)
  }

  const onResetSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const { period } = data
      const values = {
        period,
      }

      await onFormResetSubmit(values)
    } catch (error) {}
  }

  const onResetGoals = async () => {
    await flushSync(async () => {
      setIsResetMode(true)
    })

    await handleSubmit(async (data) => await onResetSubmit(data))()
  }

  return (
    <>
      <FormProvider {...methods}>
        {errorData && (
          <Alert type={'error'} mb={'sm'}>
            {errorData?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={onSaveSubmit}>
          <TypeSection />

          <PeriodSection />

          <Group position={'right'} mt={'xl'}>
            <Button variant="outline" color="dark" onClick={onCancel}>
              {t('cancel')}
            </Button>

            <GoalsReset onGoalsReset={onResetGoals} loading={isSubmitting && isResetMode} />

            <Button loading={isSubmitting && !isResetMode} type={'submit'}>
              {t('save')}
            </Button>
          </Group>
        </form>
      </FormProvider>
    </>
  )
}
