import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { GeneralHeader as GeneralPageHeader } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { GeneralInputsFilter } from '@/features/input/components/Inputs/Filters'
import { InputCreateControl } from './InputCreateControl'

interface IProps {
  config?: {}
  filters: {}
  onFiltersChange: (data: object) => void
  onAddSuccess: () => void
}

const defaultConfig = {}

export const GeneralHeader = ({ config, filters, onFiltersChange, onAddSuccess }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <GeneralPageHeader>
      <Group align={'flex-start'}>
        <InputCreateControl onSuccess={onAddSuccess} />

        <div className={'flex flex-wrap grow justify-end'}>
          <GeneralInputsFilter values={filters} onFiltersChange={onFiltersChange} />
        </div>
      </Group>
    </GeneralPageHeader>
  )
}
