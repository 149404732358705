import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useLogout } from '@/features/auth/hooks'
import { useNotify } from '@/hooks'

interface IProps {
  onReset: () => void
  data?: {
    message?: string
    loggedIn?: boolean
  }
}

export const ErrorAlert = ({ data, onReset }: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { logout, loading } = useLogout()

  const onLogout = async () => {
    await logout()

    onReset()

    showNotification({ type: 'success', message: t('success') })
  }

  return (
    <Box mb={'sm'}>
      <Alert type={'error'} mb={'sm'}>
        {data?.message || t('error')}
      </Alert>

      {data?.loggedIn && (
        <Button loading={loading} onClick={onLogout}>
          {t('logout')}
        </Button>
      )}
    </Box>
  )
}
