import axios from 'axios'

class IntegrationsAPI {
  getIntegrations = async () => {
    return axios.get(`api/integrations`)
  }

  startAll = async () => {
    return axios.post(`api/integrations/start-all`)
  }
}

export const integrationsAPI = new IntegrationsAPI()
