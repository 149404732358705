import React from 'react'
import { useTranslation } from 'react-i18next'
import { CloseButtonProps, CloseButton as MantineCloseButton } from '@mantine/core'
import useStyles from './CloseButton.styles'

// eslint-disable-next-line
interface IProps extends CloseButtonProps {}

export const CloseButton = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return <MantineCloseButton className={classes.root} {...props} />
}
