import React from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateForecastSectionTrackerDTO } from '@/features/forecast-section/api/dto'
import { forecastSectionTrackerUpdatePA } from '@/features/forecast-section/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useFSTrackerUpdate = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async ({
    id,
    params,
  }: {
    id: number
    params: UpdateForecastSectionTrackerDTO
  }) => {
    try {
      const data = await onAsyncDispatch(forecastSectionTrackerUpdatePA.request, { id, params })

      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })

      return data
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onSubmit,
    loading,
  }
}
