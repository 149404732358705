import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {
      maxWidth: 540,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',

      [theme.fn.smallerThan('md')]: {
        margin: `0 auto`,
      },
    },

    title: {
      [theme.fn.smallerThan('md')]: {
        textAlign: 'center',
      },
    },
  }
})
