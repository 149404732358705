import React from 'react'
import {
  AdjustStepForecastSessionStepDTO,
  CellStepParamsDTO,
} from '@/features/forecast-session/api/dto'
import { FORECAST_SESSION_STEP_TYPE } from '@/features/forecast-session/consts/step'
import { ForecastFormValue } from '@/features/forecast/types'
import { UseSessionProps, UseSessionReturn } from '../types'

const {
  CELL_UPDATE,
  STRUCTURE_CREATE,
  STRUCTURE_UPDATE,
  STRUCTURE_DELETE,
  STRUCTURE_UPDATE_INPUTS,
} = FORECAST_SESSION_STEP_TYPE

export const useSession = ({ session, ...props }: UseSessionProps): UseSessionReturn => {
  const onSave = async () => {
    return session.actions.onSave()
  }

  const onReset = () => {
    session.actions.onReset()
  }

  const onUndo = async () => {
    return session.actions.step.onUndo()
  }

  const onRedo = async () => {
    return session.actions.step.onRedo()
  }

  const onStepSubmit = async (data: any) => {
    return session.actions.step.onSubmit(data)
  }

  const onCellUpdate = async (data: CellStepParamsDTO) => {
    const params = {
      type: CELL_UPDATE,
      data,
    }
    return onStepSubmit(params)
  }

  const onStructureCreate = async (data: ForecastFormValue) => {
    const params = {
      type: STRUCTURE_CREATE,
      data,
    }
    return onStepSubmit(params)
  }

  const onStructureDelete = async (data: ForecastFormValue) => {
    const params = {
      type: STRUCTURE_DELETE,
      data,
    }
    return onStepSubmit(params)
  }

  const onStructureUpdate = async (data: ForecastFormValue) => {
    const params = {
      type: STRUCTURE_UPDATE,
      data,
    }
    return onStepSubmit(params)
  }

  const onStructureUpdateInputs = async (data: ForecastFormValue) => {
    const params = {
      type: STRUCTURE_UPDATE_INPUTS,
      data,
    }
    return onStepSubmit(params)
  }

  const onAdjustStepSubmit = async (data: Omit<AdjustStepForecastSessionStepDTO, 'session_id'>) => {
    return session.actions.adjustStep.onSubmit(data)
  }

  return {
    actions: {
      onSave,
      onReset,
      onUndo,
      onRedo,
      onCellUpdate,
      onStructureCreate,
      onStructureDelete,
      onStructureUpdate,
      onStructureUpdateInputs,
      onAdjustStepSubmit,
    },
  }
}
