import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    shape: {
      position: 'absolute',
    },

    shape1: {
      left: -33,
      bottom: -33,

      [theme.fn.smallerThan('md')]: {
        bottom: -58,
      },

      [theme.fn.smallerThan('sm')]: {
        bottom: -41,
      },
    },

    shape2: {
      left: 70,
      bottom: -42,

      [theme.fn.smallerThan('md')]: {
        bottom: -73,
      },

      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },

    shape3: {
      top: -87,

      [theme.fn.smallerThan('md')]: {
        left: 60,
        top: -105,
      },

      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },

    shape4: {
      right: 388,
      bottom: -71,

      [theme.fn.smallerThan('md')]: {
        right: 40,
        bottom: -91,
      },

      [theme.fn.smallerThan('sm')]: {
        right: -39,
        bottom: -94,
      },
    },

    shape5: {
      top: -46,
      right: -21,

      [theme.fn.smallerThan('md')]: {
        top: -68,
      },

      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },

    shape6: {
      top: -9,
      right: -21,

      [theme.fn.smallerThan('md')]: {
        right: -49,
      },

      [theme.fn.smallerThan('sm')]: {
        top: -81,
        right: -41,
      },
    },
  }
})
