import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { Container, Section } from '@/features/landing/components/Elements'
import useStyles from './CTAv3Section.styles'
import { ContentSection, MediaSection } from './Sections'

export const CTAv3Section = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Section primary={false}>
      <Container size={'xl'} py={0}>
        <div className={classes.wrapper}>
          <Grid align="center" gutter={60} gutterMd="md">
            <Grid.Col md={6}>
              <ContentSection />
            </Grid.Col>

            <Grid.Col md={6}>
              <div className={classes.mediaBlock}>
                <MediaSection />
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </Container>
    </Section>
  )
}
