import React, { useEffect } from 'react'
import { DataShower } from '@/components/Elements'
import { useInputsList } from '@/features/forecast/hooks'

interface IProps {
  children: React.ReactNode
}

export const ForecastInputsDataLoader = ({ children }: IProps) => {
  const { fetched, loading, error, onFetch, onClean } = useInputsList()

  useEffect(() => {
    onFetch()

    return () => {
      onClean()
    }
  }, [])

  return (
    <DataShower isLoading={loading} isFetched={fetched} isFailed={!!error} error={error}>
      {children}
    </DataShower>
  )
}
