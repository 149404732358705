import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useFilterForm } from '@/features/filter/hooks'
import { ForecastFilterForm } from './ForecastFilterForm'

interface IProps {
  values: {
    period: Date[]
  }
  onFiltersChange: (data: any) => void
}

export const ForecastFilter = ({ values = { period: [] }, onFiltersChange }: IProps) => {
  const { methods } = useFilterForm({
    config: {
      defaultValues: {
        ...values,
      },
    },
    onFiltersChange,
    initialValues: {
      period: [],
    },
    delay: 500,
  })

  return (
    <FormProvider {...methods}>
      <ForecastFilterForm />
    </FormProvider>
  )
}
