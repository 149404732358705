import React, { useEffect } from 'react'
import { DataShower } from '@/components/Elements'
import { useTrackerForecastSectionsList, useTrackerInputsList } from '@/features/tracker/hooks'

interface IProps {
  children: React.ReactNode
}

export const TrackerQueryDataLoader = ({ children }: IProps) => {
  const inputsList = useTrackerInputsList()

  const forecastSectionsList = useTrackerForecastSectionsList()

  useEffect(() => {
    inputsList.onFetch()

    forecastSectionsList.onFetch()

    return () => {
      inputsList.onClean()

      forecastSectionsList.onClean()
    }
  }, [])

  const fetched = inputsList.fetched && forecastSectionsList.fetched

  const loading = inputsList.loading && forecastSectionsList.loading

  const error = inputsList.error || forecastSectionsList.error

  return (
    <DataShower isLoading={loading} isFetched={fetched} isFailed={!!error} error={error}>
      {children}
    </DataShower>
  )
}
