import { DatesRangeValue } from 'mantine-dates-6'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect, useFirstMountState } from 'react-use'
import { MonthPickerInput } from '@/components/FormElements'

interface IProps {
  name: string
  value: DatesRangeValue
  onChange: (value: DatesRangeValue) => void
  meta: any
}

export const MonthPicker = ({ name, value, onChange, meta }: IProps) => {
  const { t } = useTranslation()

  const isFirstMount = useFirstMountState()

  const [period, setPeriod] = useState<DatesRangeValue>(value)

  const onPeriodChange = (value: DatesRangeValue) => {
    const [start] = value
    if (start) {
      setPeriod(value)
    }
  }

  useDeepCompareEffect(() => {
    setPeriod(value)
  }, [value])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      const [start, end] = period

      if (start && end) {
        onChange(period)
      }
    }
  }, [period])

  return (
    <MonthPickerInput
      name={name}
      id={'period'}
      placeholder={t('select_period')}
      type={'range'}
      value={period}
      onChange={onPeriodChange}
      allowSingleDateInRange={false}
      allowDeselect={false}
      numberOfColumns={2}
      valueFormat="MMM YYYY"
      meta={meta}
    />
  )
}
