import * as Yup from 'yup'
import {
  FORECAST_INPUT_CELL_GOAL_MAX_VALUE,
  FORECAST_INPUT_CELL_GOAL_MIN_VALUE,
} from '@/features/forecast/consts/forecast'

export const validationSchema = Yup.object().shape({
  sections: Yup.array()
    .of(
      Yup.object().shape({
        inputs: Yup.array().of(
          Yup.object().shape({
            cells: Yup.array().of(
              Yup.object().shape({
                goal: Yup.number()
                  .required('field.error.required')
                  .min(FORECAST_INPUT_CELL_GOAL_MIN_VALUE, 'field.error.value.between')
                  .max(FORECAST_INPUT_CELL_GOAL_MAX_VALUE, 'field.error.value.between'),
              })
            ),
          })
        ),
      })
    )
    .nullable()
    .min(1, 'field.sections.error.required'),
})
