import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Group } from '@mantine/core'
import { GeneralHeader as GeneralPageHeader } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { ActionBar } from './ActionBar'
import { ForecastFilter } from './ForecastFilter'

interface IProps {
  filters: {
    period: Date[]
  }
  onFiltersChange: (data: any) => void
}

export const Header = ({ filters, onFiltersChange }: IProps) => {
  const { t } = useTranslation()

  return (
    <GeneralPageHeader>
      <Group align={'flex-start'}>
        <Flex className={'grow'}>
          <Group>
            <ActionBar />
          </Group>
        </Flex>

        <Group grow position={'right'}>
          <ForecastFilter values={filters} onFiltersChange={onFiltersChange} />
        </Group>
      </Group>
    </GeneralPageHeader>
  )
}
