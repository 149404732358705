import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SimpleGrid } from '@mantine/core'
import { SelectController, TextInputController } from '@/components/FormElements'
import { useForecastSessionStaticOptions } from '@/features/forecast-session/hooks'
import { NormalizeUtils } from '@/utils'

export const TypeSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const { getAdjustStepTypes } = useForecastSessionStaticOptions()

  const watchType = watch('type')

  return (
    <>
      <SimpleGrid cols={2}>
        <SelectController
          control={control}
          name={'type'}
          id={'type'}
          mb={'md'}
          data={getAdjustStepTypes()}
        />

        <TextInputController
          control={control}
          name={'percent'}
          id={'percent'}
          placeholder={t('percent')}
          mb={'md'}
          normalize={NormalizeUtils.onlyNumbers}
          rightSection={'%'}
        />
      </SimpleGrid>
    </>
  )
}
