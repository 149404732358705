import { createSlice } from '@reduxjs/toolkit'
import { ForecastModel } from '@/features/forecast/types'

interface IState {
  data: {
    sections: ForecastModel[]
  }
  loading: boolean
  error: any | null
  meta: any | null
}

const initialState: IState = {
  data: {
    sections: [],
  },
  loading: false,
  error: null,
  meta: null,
}

const forecastDataSlice = createSlice({
  name: 'forecastData',
  initialState,
  reducers: {
    getData(state, action) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
      state.meta = action.payload.meta
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const forecastData = forecastDataSlice.actions

export default forecastDataSlice.reducer
