import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const todayRowColors = theme.fn.variant({ variant: 'light', color: 'yellow' })

  return {
    table: {
      height: '1px',
      borderCollapse: 'separate',
      borderSpacing: 0,

      ['thead th:last-of-type, tbody td:last-of-type']: {
        borderLeft: 'none',
      },

      ['thead th']: {
        position: 'sticky',
        top: 0,
        zIndex: 3,
        background: theme.white,
      },

      ['thead th:first-of-type']: {
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: 4,
      },

      ['tbody td:first-of-type']: {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: 'inherit',
      },
    },

    inputHeader: {
      minWidth: 160,
      maxWidth: 160,
    },

    row: {
      backgroundColor: theme.white,
    },

    prevRow: {
      backgroundColor: theme.colors['gray'][0],
    },

    todayRow: {
      backgroundColor: todayRowColors.background,
      fontWeight: 600,
    },

    highlightRow: {
      fontWeight: 600,
      backgroundColor: theme.colors[theme.primaryColor][1],
    },

    softHighlightRow: {
      fontWeight: 600,
      backgroundColor: theme.colors[theme.primaryColor][0],
    },

    noteCell: {
      padding: '0 !important',
      minWidth: 500,
      maxWidth: 500,
    },
  }
})
