import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Button } from '@/components/Elements'
import { useLogout } from '@/features/auth/hooks'

export const Footer = () => {
  const { t } = useTranslation()
  const { logout, loading } = useLogout()

  const onLogout = async () => {
    await logout()
  }

  return (
    <Box px={'lg'} py={'lg'}>
      <Button fullWidth onClick={onLogout} variant={'outline'} color={'gray'} loading={loading}>
        {t('logout')}
      </Button>
    </Box>
  )
}
