import _ from 'lodash'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'

type OptionType = {
  label: string
  value: INTEGRATION_SYSTEM_PROVIDER
}

export const getProviders = () => {
  return [
    {
      label: 'Shopify',
      value: INTEGRATION_SYSTEM_PROVIDER.SHOPIFY,
    },
    {
      label: 'Facebook',
      value: INTEGRATION_SYSTEM_PROVIDER.FACEBOOK,
    },
    {
      label: 'Klaviyo',
      value: INTEGRATION_SYSTEM_PROVIDER.KLAVIYO,
    },
    {
      label: 'Google Ads',
      value: INTEGRATION_SYSTEM_PROVIDER.GOOGLE_ADS,
    },
  ]
}

export const getProvider = (value: INTEGRATION_SYSTEM_PROVIDER | string): OptionType | any => {
  const options = getProviders()
  return _.find(options, { value })
}

export const providersMap = getProviders().reduce((acc: any, curr) => {
  const { value } = curr

  return acc.set(value, curr)
}, new Map())

export const isShopify = (value: INTEGRATION_SYSTEM_PROVIDER | string): boolean => {
  return value === INTEGRATION_SYSTEM_PROVIDER.SHOPIFY
}

export const isFacebook = (value: INTEGRATION_SYSTEM_PROVIDER | string): boolean => {
  return value === INTEGRATION_SYSTEM_PROVIDER.FACEBOOK
}

export const isKlaviyo = (value: INTEGRATION_SYSTEM_PROVIDER | string): boolean => {
  return value === INTEGRATION_SYSTEM_PROVIDER.KLAVIYO
}

export const isGoogleAds = (value: INTEGRATION_SYSTEM_PROVIDER | string): boolean => {
  return value === INTEGRATION_SYSTEM_PROVIDER.GOOGLE_ADS
}
