import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  MultiSelect as MantineMultiSelect,
  MultiSelectProps as MantineMultiSelectProps,
} from '@mantine/core'
import { FormUtils } from '@/utils'

export interface MultiSelectProps extends MantineMultiSelectProps {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const MultiSelect = ({
  onChange,
  normalize,
  meta,
  fieldRef,
  translateParams,
  value,
  ...props
}: MultiSelectProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <MantineMultiSelect
      nothingFound={t('no_options')}
      // value={value ? [...value].map((item) => item.toString()) : value}
      value={value}
      {...props}
      onChange={onChange}
      error={showError ? getErrorMessage() : null}
    />
  )
}
