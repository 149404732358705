import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { SectionsDailyTrackerSection } from '@/features/tracker/types'
import { TrackerActiveControl } from './Controls'

interface IProps {
  data: SectionsDailyTrackerSection
  trackerFSActiveControlProps?: {
    onSuccess: (data: any) => void
  }
}

export const SectionHeader = ({ data, trackerFSActiveControlProps }: IProps) => {
  const { t } = useTranslation()

  const { n: name } = data

  return (
    <div className={'flex'}>
      <Group className={'w-full'} noWrap>
        <div className={'flex grow'}>{name || '-'}</div>

        <TrackerActiveControl id={data.id} {...trackerFSActiveControlProps} />
      </Group>
    </div>
  )
}
