import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, SimpleGrid, Text, Title, useMantineTheme } from '@mantine/core'
import { SignUpCTA } from '@/features/landing/components/CTA'
import useStyles from './ContentSection.styles'
import { ImageBlock } from './ImageBlock'

export const ContentSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const theme = useMantineTheme()

  return (
    <SimpleGrid
      cols={2}
      spacing="lg"
      breakpoints={[{ maxWidth: 'md', cols: 1, verticalSpacing: 'xl' }]}
    >
      <Flex direction={'column'} justify="center" className={classes.contentWrapper}>
        <Box className={classes.content}>
          <Title size={50} className={classes.title}>
            {t('landing.hero-title-start')}
            <Text c={theme.fn.primaryColor()}>{t('landing.hero-title-end')}</Text>
          </Title>

          <Text component={'p'} fw="500" fz={'lg'}>
            {t('landing.hero-description')}
          </Text>

          <Box my={'xl'} className={'w-full'}>
            <SignUpCTA />
          </Box>
        </Box>
      </Flex>

      <Flex direction={'column'} justify="center">
        <ImageBlock />
      </Flex>
    </SimpleGrid>
  )
}
