import avatar1 from '@/assets/images/illustrations/avatar-1.png'
import avatar2 from '@/assets/images/illustrations/avatar-2.png'
import img1 from '@/assets/images/integration-systems/facebook.svg'
import img2 from '@/assets/images/integration-systems/klaviyo-brand.png'

export const reviewsData = [
  {
    icon: img1,
    text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit fringilla molestie lacus dolor aliquet risus proin feugiat tellus sed fusce et risus elit quam mattis fusce vulputate fui semper viverra neque sed pharetra cursus cras id fames sit aliquet lectus.',
    author: {
      avatar: avatar1,
      name: 'John Carter',
      job: 'VP of Marketing at Facebook',
    },
  },
  {
    icon: img2,
    text: 'Lorem ipsum dolor sit amet consectetur adipiscing elit fringilla molestie lacus dolor aliquet risus proin feugiat tellus sed fusce et risus elit quam mattis fusce vulputate',
    author: {
      avatar: avatar2,
      name: 'Sophie Moore',
      job: 'VP of Marketing at YouTube',
    },
  },
]
