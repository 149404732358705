import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@/components/Elements'
import { useForecastContext } from '@/features/forecast/contexts'
import { ImportEntry } from '@/features/import/components/Import/ImportEntry'
import { usePopup } from '@/hooks'

export const ImportControl = () => {
  const { t } = useTranslation()

  const {
    actions: { onSessionReset },
  } = useForecastContext()

  const { visible, open, close } = usePopup()

  const onClose = () => {
    close()
  }

  const onImportComplete = async () => {
    await onSessionReset()
  }

  return (
    <>
      <Button leftIcon={<Icon name={'file-earmark-arrow-up'} />} variant={'outline'} onClick={open}>
        {t('upload_data')}
      </Button>

      <ImportEntry visible={visible} onClose={onClose} onImportComplete={onImportComplete} />
    </>
  )
}
