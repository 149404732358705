import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { GeneralHeader as GeneralPageHeader } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { ImportStatus } from './ImportStatus'
import { SyncStatusLabel } from './SyncStatusLabel'

interface IProps {
  onSyncAll: () => void
  syncAllAllow?: boolean
  syncInProgress?: boolean
  hasConnectedIntegrations?: boolean
  isImportActive?: boolean
}

export const GeneralHeader = ({
  onSyncAll,
  syncAllAllow,
  syncInProgress,
  hasConnectedIntegrations,
  isImportActive,
}: IProps) => {
  const { t } = useTranslation()

  return (
    <GeneralPageHeader>
      {/*<Group align={'flex-start'}>*/}
      {/*  <SyncAllControl onSync={onSyncAll} syncAllAllow={syncAllAllow} />*/}
      {/*</Group>*/}

      <Group spacing={'xl'} position={'right'}>
        <ImportStatus isActive={isImportActive} />

        <SyncStatusLabel
          syncInProgress={syncInProgress}
          hasConnectedIntegrations={hasConnectedIntegrations}
        />
      </Group>
    </GeneralPageHeader>
  )
}
