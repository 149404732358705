import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMountedState } from 'react-use'
import { useIntegrationSystem } from '@/features/integration/hooks'
import { integrationEntityFetchPA, integrationSyncStartPA } from '@/features/integration/store'
import { IntegrationSystemModel } from '@/features/integration/types'
import { useAsyncDispatch, useNotify } from '@/hooks'

interface IProps {
  onIntegrationUpdate: (data: IntegrationSystemModel) => void
  onError: (data: any) => void

  options?: {
    checkDelay: number
  }
}

const defaultOptions = {
  checkDelay: 2000,
}

export const useIntegrationSyncing = ({ onIntegrationUpdate, onError, options }: IProps) => {
  const { t } = useTranslation()

  const isMounted = useMountedState()

  options = {
    ...defaultOptions,
    ...options,
  }

  const { checkDelay } = options

  const { showNotification } = useNotify()

  const { loading: starting, onAsyncDispatch: onStartDispatch } = useAsyncDispatch()

  const { isSyncActive } = useIntegrationSystem()

  /* Check */
  const { loading: checking, onAsyncDispatch: onCheckDispatch } = useAsyncDispatch()

  const onFetch = async (id: number) => {
    try {
      const data: IntegrationSystemModel = await onCheckDispatch(integrationEntityFetchPA.request, {
        id,
      })

      const { status } = data ?? {}

      onIntegrationUpdate(data)

      if (status && isSyncActive(status)) {
        if (isMounted()) {
          onCheck(id)
        }
      } else {
        showNotification({
          type: 'success',
          message: t('sync_completed'),
        })
      }
    } catch (error: any) {
      onError(error)

      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const onCheck = useCallback(debounce(onFetch, checkDelay), [])

  useEffect(() => {
    return () => {
      onCheck.cancel()
    }
  }, [onCheck])

  const onSyncCheck = async (id: number) => {
    onCheck(id)
  }
  // ======= //

  /* Start */
  const onSyncStart = async (id: number) => {
    try {
      const data = await onStartDispatch(integrationSyncStartPA.request, { id })

      showNotification({
        type: 'success',
        message: t('sync_started_successfully'),
      })

      const { status } = data ?? {}

      if (status && isSyncActive(status)) {
        if (isMounted()) {
          onCheck(id)
        }
      }

      return data
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }
  // ======= //

  return {
    onSyncStart,
    starting,
    onSyncCheck,
    checking,
  }
}
