import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Burger, Container, Group, Header as MantineHeader, MediaQuery } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import { Anchor, CTAButton, Icon, LogoIcon } from '@/components/Elements'
import { NavMenu, NavbarDrawer } from '@/components/Layouts/Elements'
import { LANDING_HEADER_HEIGHT, LANDING_HEADER_HEIGHT_XS } from '@/const/layout'
import useStyles from './Header.styles'
import { GeneralLinks } from './Navbar'

export const Header = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const [isNavbarDrawerOpened, toggleNavbarDrawerOpened] = useToggle([false, true])

  return (
    <>
      <MantineHeader
        className={classes.root}
        height={{ base: LANDING_HEADER_HEIGHT_XS, md: LANDING_HEADER_HEIGHT }}
        fixed
        zIndex={1}
        withBorder={false}
      >
        <Container className={classes.header} size={'xl'}>
          <Group grow className={'w-full flex-nowrap'}>
            <div>
              <Group className={'flex-nowrap'}>
                <MediaQuery largerThan="md" styles={{ display: 'none', height: 0 }}>
                  <Burger
                    opened={false}
                    onClick={() => toggleNavbarDrawerOpened()}
                    size="sm"
                    mr={'lg'}
                  />
                </MediaQuery>

                <Anchor component={Link} to={'/'}>
                  <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                    <LogoIcon name={'v2'} size={'xs'} />
                  </MediaQuery>

                  <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
                    <LogoIcon name={'v2'} />
                  </MediaQuery>
                </Anchor>
              </Group>
            </div>

            <MediaQuery smallerThan="md" styles={{ visibility: 'hidden' }}>
              <Box className={'flex justify-center'}>
                <NavMenu>
                  <GeneralLinks onLinkClick={() => {}} />
                </NavMenu>
              </Box>
            </MediaQuery>

            <MediaQuery smallerThan="xs" styles={{ visibility: 'hidden' }}>
              <div className={'flex justify-end'}>
                <Group spacing={'xl'}>
                  <CTAButton
                    mode={'link'}
                    to={'/login'}
                    rightIcon={<Icon size={30} name={'arrow-right-short'} />}
                  >
                    {t('get_started')}
                  </CTAButton>
                </Group>
              </div>
            </MediaQuery>
          </Group>
        </Container>
      </MantineHeader>

      <NavbarDrawer opened={isNavbarDrawerOpened} onClose={toggleNavbarDrawerOpened}>
        <GeneralLinks onLinkClick={toggleNavbarDrawerOpened} />
      </NavbarDrawer>
    </>
  )
}
