import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Title } from '@mantine/core'
import { SignUpCTA } from '@/features/landing/components/CTA'
import useStyles from './ContentSection.styles'

export const ContentSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Title size={40} color={'white'} className={classes.title}>
        {t('landing.cta-v3-section.title')}
      </Title>

      <Box mt={'xl'}>
        <SignUpCTA />
      </Box>
    </div>
  )
}
