import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DemoMediaCardCarousel } from '@/components/Elements'
import { ReviewContent } from './ReviewContent'
import useStyles from './Reviews.styles'
import { reviewsData } from './data'

export const Reviews = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const data = useMemo(() => {
    return reviewsData.map(({ icon, ...rest }) => ({
      icon: icon,
      renderContent: () => <ReviewContent data={rest} />,
    }))
  }, [])

  return (
    <div>
      <DemoMediaCardCarousel data={data} loop />
    </div>
  )
}
