import { all } from 'redux-saga/effects'
import { dailyTrackerSaga } from './daily-tracker/sagas'
import { forecastSectionsSaga } from './forecast-sections/sagas'
import { inputsSaga } from './inputs/sagas'
import { sectionsDailyTrackerSaga } from './sections-daily-tracker/sagas'
import { trackerForecastSectionSaga } from './tracker-forecast-section/sagas'
import { trackerInputSaga } from './tracker-input/sagas'

export function* trackerSaga() {
  yield all([
    dailyTrackerSaga(),
    trackerInputSaga(),
    inputsSaga(),
    sectionsDailyTrackerSaga(),
    forecastSectionsSaga(),
    trackerForecastSectionSaga(),
  ])
}
