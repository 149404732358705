import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { GeneralInputsContainer } from '@/features/input/components/Inputs/GeneralInputs'

export const Inputs = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'inputs_and_metrics'} />

      <PageContent>
        <GeneralInputsContainer />
      </PageContent>
    </>
  )
}
