import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Text } from '@mantine/core'
import { Select, SelectProps } from '@/components/FormElements'
import { IMPORT_MODE } from '@/features/import/consts/import'
import { useInputStaticOptions } from '@/features/import/hooks'

type OptionItem = {
  label: string
  value: IMPORT_MODE
  description: string
}

// eslint-disable-next-line
export interface ImportModeSelectProps extends Omit<SelectProps, 'data'> {
  data?: OptionItem[]
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  value: IMPORT_MODE
  description: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, value, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>
      <Text size="xs" opacity={0.65}>
        {description}
      </Text>
    </div>
  )
)

export const ImportModeSelect = ({ data, ...props }: ImportModeSelectProps) => {
  const { t } = useTranslation()

  const { getModes } = useInputStaticOptions()

  return <Select data={data || getModes()} itemComponent={SelectItem} {...props} />
}
