import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })

  return {
    root: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      maxWidth: 473,
      marginTop: -46,

      [theme.fn.smallerThan('md')]: {
        marginTop: 0,
      },

      [theme.fn.smallerThan('sm')]: {
        maxWidth: 357,
      },
    },

    dots1: {
      position: 'absolute',
      left: -33,
      top: 77,
      maxWidth: 143,

      [theme.fn.smallerThan('sm')]: {
        maxWidth: '27%',
        left: 7,
        top: 21,
      },
    },

    dots2: {
      position: 'absolute',
      right: -18,
      bottom: 35,
      maxWidth: 143,

      [theme.fn.smallerThan('md')]: {
        right: -48,
      },

      [theme.fn.smallerThan('sm')]: {
        maxWidth: '27%',
        right: -10,
      },
    },

    img1: {
      position: 'relative',
      zIndex: 1,
      maxWidth: 376,
      marginLeft: 'auto',
      border: `1px solid ${colors.background}`,
      borderRadius: theme.radius.xl,
      boxShadow: `0 3px 28px 0 ${theme.fn.rgba(theme.colors['indigo'][6], 0.06)}`,

      [theme.fn.smallerThan('sm')]: {
        maxWidth: '79%',
      },
    },

    img2: {
      position: 'absolute',
      bottom: 24,
      zIndex: 2,
      maxWidth: '50%',
      border: `1px solid ${colors.background}`,
      borderRadius: theme.radius.xl,
      boxShadow: `0 34px 87px 0 ${theme.fn.rgba(theme.colors['indigo'][6], 0.06)}`,

      [theme.fn.smallerThan('sm')]: {
        bottom: 15,
      },
    },
  }
})
