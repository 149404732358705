import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  INTEGRATION_SYSTEM_PROVIDER,
  INTEGRATION_SYSTEM_STATUS,
} from '@/features/integration/consts/system'
import { useIntegrationSyncing, useIntegrationSystem } from '@/features/integration/hooks'
import { IntegrationSystem, IntegrationSystemModel } from '@/features/integration/types'

interface IProps {
  data: IntegrationSystem
  onIntegrationUpdate: (provider: INTEGRATION_SYSTEM_PROVIDER, data: IntegrationSystemModel) => void
  syncCount?: number
}

export const useIntegrationSystemProviderSyncEffect = ({
  data,
  onIntegrationUpdate,
  syncCount,
}: IProps) => {
  const { t } = useTranslation()

  const modelData = data.data || null
  const id = modelData?.id
  const status = modelData?.status
  const provider = data.provider

  const onIntegrationDataUpdate = (entity: IntegrationSystemModel | any) => {
    onIntegrationUpdate(provider, entity)
  }

  const onSyncCheckError = (error: any) => {
    const message: string = error?.message || t('error')

    onIntegrationDataUpdate({
      status: INTEGRATION_SYSTEM_STATUS.ERROR_UNKNOWN,
      text: message,
    })
  }

  const { starting, onSyncStart, onSyncCheck } = useIntegrationSyncing({
    onIntegrationUpdate: onIntegrationDataUpdate,
    onError: onSyncCheckError,
  })

  const { isSyncActive, isSyncStartAllow } = useIntegrationSystem()

  const onSync = async (id?: number) => {
    const systemId = modelData?.id || id
    if (systemId) {
      const entity = await onSyncStart(systemId)

      if (entity) {
        onIntegrationUpdate(provider, entity)
      }
    }
  }

  const onCheck = async () => {
    if (id) {
      await onSyncCheck(id)
    }
  }

  useEffect(() => {
    if (status && isSyncActive(status)) {
      onCheck()
    }
  }, [])

  useEffect(() => {
    if (syncCount && status && isSyncStartAllow(status)) {
      onSync()
    }
  }, [syncCount])

  return {
    onSync,
    starting,
  }
}
