import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  INPUT_ACTION,
  INPUT_FUNCTION_TYPE,
  INPUT_SYMBOL,
  INPUT_TYPE,
} from '@/features/input/consts/input'
import { inputHelper } from '@/features/input/helpers'

export const useInputStaticOptions = () => {
  const { t } = useTranslation()

  const getTypes = () => {
    return inputHelper.type.getTypes()
  }

  const getType = (value: INPUT_TYPE) => {
    return inputHelper.type.getType(value)
  }

  const isCustomType = (value: INPUT_TYPE) => {
    return inputHelper.type.isCustom(value)
  }

  const isConstantType = (value: INPUT_TYPE) => {
    return inputHelper.type.isConstant(value)
  }

  const getFunctionTypes = () => {
    return inputHelper.functionType.getFunctionTypes()
  }

  const getFunctionType = (value: INPUT_FUNCTION_TYPE) => {
    return inputHelper.functionType.getFunctionType(value)
  }

  const getActions = () => {
    return inputHelper.action.getActions()
  }

  const getAction = (value: INPUT_ACTION) => {
    return inputHelper.action.getAction(value)
  }

  const getSymbols = () => {
    return inputHelper.symbol.getSymbols()
  }

  const getSymbol = (value: INPUT_SYMBOL) => {
    return inputHelper.symbol.getSymbol(value)
  }

  const isCurrencySymbol = (value: INPUT_SYMBOL) => {
    return inputHelper.symbol.isCurrency(value)
  }

  const isPercentageSymbol = (value: INPUT_SYMBOL) => {
    return inputHelper.symbol.isPercentage(value)
  }

  const getPeriods = inputHelper.period.getPeriods

  const getPeriod = inputHelper.period.getPeriod

  return {
    getTypes,
    getType,
    isCustomType,
    isConstantType,
    getFunctionTypes,
    getFunctionType,
    getActions,
    getAction,
    getSymbols,
    getSymbol,
    isCurrencySymbol,
    isPercentageSymbol,
    getPeriods,
    getPeriod,
  }
}
