import React from 'react'
import { useTranslation } from 'react-i18next'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'
import { useIntegrationStaticOptions } from '@/features/integration/hooks'
import {
  integrationFacebookCreatePA,
  integrationGoogleCreatePA,
  integrationKlaviyoCreatePA,
} from '@/features/integration/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useIntegrationCreate = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const { isFacebookProvider, isKlaviyoProvider, isGoogleAdsProvider } =
    useIntegrationStaticOptions()

  const onSubmit = async (provider: INTEGRATION_SYSTEM_PROVIDER, params: any) => {
    try {
      const action = isFacebookProvider(provider)
        ? integrationFacebookCreatePA
        : isKlaviyoProvider(provider)
        ? integrationKlaviyoCreatePA
        : isGoogleAdsProvider(provider)
        ? integrationGoogleCreatePA
        : null

      if (action) {
        const data = await onAsyncDispatch(action.request, { params })

        if (!isFacebookProvider(provider)) {
          showNotification({
            type: 'success',
            message: t('added_successfully'),
          })
        }

        return data
      }
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })

      throw error
    }
  }

  return {
    onSubmit,
    loading,
  }
}
