import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Space, Text, Title, TypographyStylesProvider } from '@mantine/core'
import { Anchor, GeneralCard } from '@/components/Elements'
import { APP_NAME } from '@/const/core'
import { useFromPath } from '@/hooks'

export const TermsContainer = () => {
  const { t } = useTranslation()
  const from = useFromPath()

  const items = [
    {
      title: 'Non-Disclosure of Confidential Information:',
      text: 'I acknowledge that any information disclosed by Spark Forecast during my participation in the beta testing program is confidential and proprietary. I agree not to disclose any Confidential Information obtained from Spark Forecast to any third parties without prior written consent.',
    },
    {
      title: 'User Responsibilities:',
      text: 'I agree to actively use Spark Forecast during the beta testing period, providing feedback, suggestions, bug reports, and any usability issues encountered. I commit to respecting the confidentiality of any information shared within the beta testing program and engaging in a collaborative and respectful manner.',
    },
    {
      title: 'Access and Usage:',
      text: 'I understand that my access to Spark Forecast during the beta testing period is provided solely for testing and feedback purposes. I agree not to use Spark Forecast for any commercial purposes or share access with unauthorized individuals or organizations.',
    },
    {
      title: 'Connection to Platforms and Data Usage:',
      text: 'I confirm that I have the necessary permissions and authority to connect my business platforms (e.g., Shopify, Facebook Ads, Google Ads, Klaviyo) to Spark Forecast API. I acknowledge that Spark Forecast will pull my business data from these platforms onto its platform for forecasting and analysis purposes. I understand that Spark Forecast does not have direct access to my platform accounts, and my data is securely transmitted and processed through API integrations.',
    },
    {
      title: 'Data Confidentiality and Security:',
      text: 'I acknowledge that Spark Forecast will handle my data in accordance with industry-standard security practices and its privacy policy. I understand that Spark Forecast will not disclose, sell, or share my data with any third parties without my prior written consent, except as required by law.',
    },
    {
      title: 'Limitations and Acknowledgements:',
      text: 'I understand that Spark Forecast is a beta version, and as such, it may have limitations, bugs, or features that are still being developed. I acknowledge that Spark Forecast is provided "as is" without any warranties or guarantees of its functionality or performance.',
    },
    {
      title: 'Termination:',
      text: 'Both parties reserve the right to terminate my participation in the beta testing program at any time, without prior notice or liability. Upon termination, my access to Spark Forecast will be revoked, and I agree to discontinue using the tool and delete any related materials or data.',
    },
    {
      title: 'Intellectual Property:',
      text: 'I acknowledge that Spark Forecast and all associated intellectual property rights belong exclusively to Spark Forecast and its parent company. Any feedback or suggestions I provide during the beta testing program do not grant me ownership or rights to the tool or its intellectual property.',
    },
    {
      title: 'Liability:',
      text: 'I acknowledge that Spark Forecast shall not be held liable for any business decisions I make based on the usage or non-usage of the platform or any analysis performed using Spark Forecast. I understand and agree that any decisions made based on the data and insights provided by Spark Forecast are my sole responsibility.',
    },
  ]

  return (
    <GeneralCard
      title={'Terms and Conditions for Spark Forecast Beta Testing'}
      titleProps={{ order: 2 }}
    >
      <div className={'flex'}>
        <Title order={6}>
          {t('back_to')}{' '}
          <Anchor component={Link} to={from}>
            {APP_NAME}
          </Anchor>
        </Title>
      </div>

      <Space h={'lg'} />

      <TypographyStylesProvider>
        <Text>
          Please read and acknowledge the following terms and conditions before accessing and using
          Spark Forecast:
        </Text>

        {items.map(({ title, text }, i) => (
          <Box mt={'lg'} key={i}>
            <Text pl={38}>{title}</Text>

            <ol start={i + 1}>
              <li>{text}</li>
            </ol>
          </Box>
        ))}

        <Text mt={'lg'}>
          By checking the box, I acknowledge that I have read, understood, and agree to abide by the
          terms and conditions outlined above.
        </Text>
      </TypographyStylesProvider>
    </GeneralCard>
  )
}
