import React from 'react'
import { Navigate } from 'react-router-dom'
import { BasicLayout } from '@/components/Layouts'
import { PrivacyPolicy, Terms } from '@/features/legal/routes'

//config
export const getPublicRoutes = () => {
  return [
    {
      path: '/*',
      element: <BasicLayout />,
      children: [
        {
          path: `terms`,
          element: <Terms />,
        },
        {
          path: `privacy-policy`,
          element: <PrivacyPolicy />,
        },
      ],
    },
  ]
}
