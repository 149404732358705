import axios from 'axios'

class StatisticsAPI {
  getDashboardStatistics = async (params: any) => {
    return axios.get(`api/dashboard`, {
      params,
    })
  }
}

export const statisticsAPI = new StatisticsAPI()
