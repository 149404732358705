import React from 'react'
import { set, useFieldArray } from 'react-hook-form'
import { StepCellUpdateType } from '@/features/forecast-session/types'
import { FORECAST_INPUT_CELL_GOAL_MIN_VALUE } from '@/features/forecast/consts/forecast'
import {
  ForecastInputCell,
  ForecastSectionFormValue,
  ForecastSectionInputFormValue,
} from '@/features/forecast/types'
import { UseForecastFormProps, UseForecastFormReturn } from '../types'

export const useForecastForm = ({ form, dates }: UseForecastFormProps): UseForecastFormReturn => {
  const { watch, reset, setValue, control, trigger } = form

  const { append, update } = useFieldArray({
    control: control,
    name: 'sections',
  })

  const sectionsValue = watch('sections')

  const resetValue = (values: any) => {
    reset(values)
  }

  const setFieldValue = (field: string, value: any) => {
    setValue(field, value)
  }

  const getCells = (section: number, input: number) => {
    return sectionsValue[section].inputs[input].cells
  }

  const getCellIndex = (section: number, input: number, date: string) => {
    const cells = getCells(section, input)

    return cells.findIndex((cell: any) => cell.date === date)
  }

  const setGoal = (
    value: string,
    params: { sectionIndex: number; inputIndex: number; date: string }
  ) => {
    const { sectionIndex, inputIndex, date } = params

    const cellIndex = getCellIndex(sectionIndex, inputIndex, date)

    if (cellIndex > -1) {
      const field = `sections.${sectionIndex}.inputs.${inputIndex}.cells.${cellIndex}.goal`

      setFieldValue(field, value)
    }
  }

  const appendSection = (value: { name: string }) => {
    const { name } = value

    const sort = sectionsValue.length

    append({ name, sort, inputs: [] })
  }

  const updateSection = (index: number, value: { name: string }) => {
    const currentValue = sectionsValue[index]

    update(index, { ...currentValue, ...value })
  }

  const removeSection = (index: number) => {
    // remove(index)

    const result = (sectionsValue as ForecastSectionFormValue[])
      .filter((_, i) => i !== index)
      .map((section, j) => ({
        ...section,
        sort: j,
      }))

    setValue('sections', result)
  }

  const updateSectionInputs = (index: number, value: ForecastSectionInputFormValue[]) => {
    const currentValue = sectionsValue[index]
    const currentInputsMap = sectionsValue[index].inputs.reduce((acc: any, curr: any) => {
      const { input_id } = curr

      return acc.set(input_id, curr)
    }, new Map())

    const cells: ForecastInputCell[] = dates.map((date) => ({
      date,
      value: 0,
      goal: FORECAST_INPUT_CELL_GOAL_MIN_VALUE,
      original_goal: 0,
    }))

    const inputs = value.map((input, i) => {
      const currentInput = currentInputsMap.get(+input.input_id)

      return {
        ...input,
        sort: i,
        cells: currentInput ? currentInput.cells : input?.cells ? input?.cells : cells,
      }
    })

    update(index, { ...currentValue, inputs: [...inputs] })

    // trigger(`sections.${index}`)
  }

  const structureUpdateInputs = (
    index: number,
    inputIds: number[],
    data: ForecastSectionInputFormValue[]
  ) => {
    const currentSection = sectionsValue[index]
    const currentInputsMap = currentSection.inputs.reduce((acc: any, curr: any) => {
      const { input_id } = curr

      return acc.set(input_id, curr)
    }, new Map())

    const dataMap = data.reduce((acc: any, curr: any) => {
      const { input_id } = curr

      return acc.set(input_id, curr)
    }, new Map())

    const inputs = inputIds.map((id, i) => {
      const value = dataMap.get(id) || currentInputsMap.get(id)

      return {
        ...value,
        sort: i,
      }
    })

    update(index, { ...currentSection, inputs: [...inputs] })
  }

  const cellsUpdateGoals = (data: StepCellUpdateType[]) => {
    const sectionsValueMap = new Map()

    for (let i = 0; i < sectionsValue.length; i++) {
      const inputs = sectionsValue[i].inputs
      for (let j = 0; j < inputs.length; j++) {
        const input = inputs[j]

        sectionsValueMap.set(input.input_id, {
          sectionIndex: i,
          inputIndex: j,
        })
      }
    }

    data.forEach(({ goal, date, input_id }) => {
      const fieldIndexes = sectionsValueMap.get(input_id)

      setGoal(goal.toString(), {
        sectionIndex: fieldIndexes.sectionIndex,
        inputIndex: fieldIndexes.inputIndex,
        date,
      })
    })
  }

  return {
    form,
    values: {
      sections: sectionsValue,
    },
    resetValue,
    setFieldValue,
    setGoal,
    appendSection,
    updateSection,
    removeSection,
    updateSectionInputs,
    structureUpdateInputs,
    cellsUpdateGoals,
  }
}
