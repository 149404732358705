import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { dailyTrackerAPI } from '@/features/tracker/api'
import { UpdateNoteDailyTrackerDTO } from '@/features/tracker/api/dto'
import {
  TRACKER_DAILY_TRACKER_NOTE_UPDATE_FAILED,
  TRACKER_DAILY_TRACKER_NOTE_UPDATE_REQUEST,
  TRACKER_DAILY_TRACKER_NOTE_UPDATE_SUCCESS,
} from './actionTypes'

type ArgsType = {
  params: UpdateNoteDailyTrackerDTO
}

export const trackerDailyTrackerNoteUpdatePA = createPromiseAction(
  TRACKER_DAILY_TRACKER_NOTE_UPDATE_REQUEST,
  TRACKER_DAILY_TRACKER_NOTE_UPDATE_SUCCESS,
  TRACKER_DAILY_TRACKER_NOTE_UPDATE_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* submit(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(dailyTrackerAPI.updateDailyTrackerNote, params)
    const { data } = response

    yield put(trackerDailyTrackerNoteUpdatePA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* updateSaga() {
  yield takeEvery(trackerDailyTrackerNoteUpdatePA.request, submit)
}
