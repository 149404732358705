import React from 'react'
import { useTranslation } from 'react-i18next'
import { ForecastSectionFormValue } from '@/features/forecast/types'
import { SectionForm } from '../../SectionForm'

interface IProps {
  section: ForecastSectionFormValue
  onClose: () => void
  onSave: (data: any) => void
  onRemove: () => void
}

export const SectionEditContainer = ({ section, onClose, onSave, onRemove }: IProps) => {
  const { t } = useTranslation()

  const onSubmit = async (values: any) => {
    onSave(values)
  }

  return (
    <>
      <SectionForm
        onSubmit={onSubmit}
        onCancel={onClose}
        initialValues={{ name: section?.name }}
        isEdit
        onRemove={onRemove}
      />
    </>
  )
}
