import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { ImportModeSelect, ImportModeSelectProps } from '../../ImportModeSelect'

interface ImportModeSelectControllerProps<T extends FieldValues> extends ImportModeSelectProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const ImportModeSelectController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: ImportModeSelectControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return <ImportModeSelect {...field} fieldRef={ref} {...rest} meta={meta} />
      }}
    />
  )
}
