import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })

  return {
    root: {
      position: 'absolute',
      width: '69%',
      height: '100%',
      borderRadius: theme.radius.xl,
      transform: 'skew(0deg, 3deg)',

      [theme.fn.smallerThan('md')]: {
        width: '80%',
      },

      [theme.fn.smallerThan('sm')]: {
        width: '90%',
      },
    },

    primary: {
      backgroundColor: colors.background,
    },
  }
})
