import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { GeneralIntegrationSystemsContainer } from '@/features/integration/components/IntegrationSystem/IntegrationSystems'

export const Integrations = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'integrations'} />

      <PageContent>
        <GeneralIntegrationSystemsContainer />
      </PageContent>
    </>
  )
}
