import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })

  return {
    tab: {
      ...theme.fn.focusStyles(),
      backgroundColor: theme.white,
      color: colors.color,
      border: `1px solid ${colors.background}`,
      fontSize: theme.fontSizes.md,
      padding: '16px 20px',
      fontWeight: 500,

      '&[data-active], &:hover': {
        backgroundColor: colors.background,
        color: colors.color,
      },

      '&[data-active]:hover': {
        backgroundColor: colors.background,
      },
    },
  }
})
