import axios from 'axios'

class SectionsDailyTrackerAPI {
  getSectionsDailyTracker = async (params: any = {}) => {
    return axios.get(`api/sections-daily-tracker`, {
      params,
    })
  }
}

export const sectionsDailyTrackerAPI = new SectionsDailyTrackerAPI()
