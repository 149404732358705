import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@mantine/core'
import { ConfirmationControl } from '@/components/Controls'
import { Button } from '@/components/Elements'

interface IProps extends ButtonProps {
  onSync: () => void
}

export const SyncControl = ({ onSync, ...props }: IProps) => {
  const { t } = useTranslation()

  return (
    <ConfirmationControl
      onConfirm={onSync}
      render={({ onOpen }) => (
        <Button onClick={onOpen} {...props}>
          {t('sync')}
        </Button>
      )}
    />
  )
}
