import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  return {
    inputHeader: {
      minWidth: 250,
    },

    entryHeader: {
      minWidth: 180,
      maxWidth: 180,
    },

    highlightBg: {
      backgroundColor: `${theme.fn.rgba(theme.colors[theme.primaryColor][0], 1)} !important`,
      border: 'none !important',
    },
  }
})
