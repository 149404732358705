import React, { useMemo, useState } from 'react'
import { useDeepCompareEffect, useFirstMountState } from 'react-use'
import {
  AdjustStepForecastSessionStepDTO,
  StepForecastSessionStepDTO,
} from '@/features/forecast-session/api/dto'
import { FORECAST_SESSION_STEP_TYPE } from '@/features/forecast-session/consts/step'
import {
  useForecastSessionDispatch,
  useForecastSessionSave,
} from '@/features/forecast-session/hooks'
import { DateUtils } from '@/utils'
import {
  ForecastSessionActions,
  ForecastSessionState,
  UseForecastSessionProps,
  UseForecastSessionReturn,
} from './types'

const { CELL_UPDATE } = FORECAST_SESSION_STEP_TYPE

export const useForecastSession = ({
  id,
  period,
  ...props
}: UseForecastSessionProps): UseForecastSessionReturn => {
  const [sessionId, setSessionId] = useState(id || null)

  const [activeStepType, setActiveStepType] = useState<FORECAST_SESSION_STEP_TYPE | null>(null)

  const onSessionIdSet = (id?: number | null) => {
    setSessionId(id || null)
  }

  const onReset = (id?: number | null) => {
    onSessionIdSet(id)

    setActiveStepType(null)
  }

  const isFirstMount = useFirstMountState()

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      onReset()
    }
  }, [period])

  const sessionSave = useForecastSessionSave()

  const sessionDispatch = useForecastSessionDispatch()

  const state: ForecastSessionState = {
    saving: sessionSave.loading,
    error: sessionSave.error,
    step: {
      type: activeStepType,
      submitting: sessionDispatch.submitting,
      undoing: sessionDispatch.undoing,
      redoing: sessionDispatch.redoing,
    },
    adjustStep: {
      submitting: sessionDispatch.adjustSubmitting,
    },
  }

  const isDisabled = useMemo(() => {
    const {
      saving,
      step: { submitting, undoing, redoing },
      adjustStep: { submitting: adjusting },
    } = state

    return saving || submitting || undoing || redoing || adjusting
  }, [state])

  const onSave = async () => {
    try {
      if (sessionId && !isDisabled) {
        await sessionSave.onSubmit({ params: { session_id: sessionId } })
      }
    } finally {
      onReset()
    }
  }

  const onStepSubmit = async (data: Omit<StepForecastSessionStepDTO, 'session_id'>) => {
    const { type } = data
    setActiveStepType(type)

    try {
      if (!isDisabled) {
        let params = {
          session_id: sessionId,
          type,
          data: data.data,
        }

        if (type !== CELL_UPDATE) {
          const [start, end] = period

          params = {
            ...params,
            data: {
              ...params.data,
              date_start: DateUtils.formatDateToServerMonthYearFormat(start),
              date_end: DateUtils.formatDateToServerMonthYearFormat(end),
            },
          }
        }

        const result = await sessionDispatch.onSubmit(params)

        if (result.meta) {
          setSessionId(result?.meta?.session_id || null)
        }

        return result.data
      }
    } catch (error: any) {
      onReset()

      throw error
    } finally {
      setActiveStepType(null)
    }
  }

  const onStepUndo = async () => {
    try {
      if (sessionId && !isDisabled) {
        await sessionDispatch.onUndo({ session_id: sessionId })
      }
    } catch (error: any) {
      onReset()

      throw error
    }
  }

  const onStepRedo = async () => {
    try {
      if (sessionId && !isDisabled) {
        await sessionDispatch.onRedo({ session_id: sessionId })
      }
    } catch (error: any) {
      onReset()

      throw error
    }
  }

  // === Adjust === //
  const onAdjustSubmit = async (data: Omit<AdjustStepForecastSessionStepDTO, 'session_id'>) => {
    try {
      if (!isDisabled) {
        const params = {
          session_id: sessionId,
          ...data,
        }

        const result = await sessionDispatch.onAdjustSubmit(params)

        if (result.meta) {
          setSessionId(result?.meta?.session_id || null)
        }

        return result.data
      }
    } catch (error: any) {
      onReset()

      throw error
    }
  }
  // === //

  const actions: ForecastSessionActions = {
    onSave,
    onReset,
    step: {
      onSubmit: onStepSubmit,
      onUndo: onStepUndo,
      onRedo: onStepRedo,
    },
    adjustStep: {
      onSubmit: onAdjustSubmit,
    },
  }

  return {
    actions,
    state,
  }
}
