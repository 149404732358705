import React from 'react'
import { useTranslation } from 'react-i18next'
import { importHelper } from '@/features/import/helpers'

export const useInputStaticOptions = () => {
  const { t } = useTranslation()

  const getModes = importHelper.mode.getModes

  const getMode = importHelper.mode.getMode

  return {
    getModes,
    getMode,
  }
}
