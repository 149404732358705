import { all } from 'redux-saga/effects'
import { forgotPasswordSaga } from './forgot-password/saga'
import { loginSaga } from './login/saga'
import { logoutSaga } from './logout/saga'
import { registerSaga } from './register/saga'
import { resetPasswordSaga } from './reset-password/saga'
import { verifyEmailSaga } from './verify-email/saga'

export function* authSaga() {
  yield all([
    registerSaga(),
    loginSaga(),
    verifyEmailSaga(),
    forgotPasswordSaga(),
    logoutSaga(),
    resetPasswordSaga(),
  ])
}
