import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@/components/Elements'
import { useForecastContext } from '@/features/forecast/contexts'
import { usePopup } from '@/hooks'
import { SectionAdd } from '../../../Section'

export const SectionAddControl = () => {
  const { t } = useTranslation()

  const {
    actions: { sectionAdd },
  } = useForecastContext()

  const { visible, open, close } = usePopup()

  const onClose = () => {
    close()
  }

  const onSave = (data: any) => {
    close()

    sectionAdd(data)
  }

  return (
    <>
      <Button leftIcon={<Icon name={'plus'} />} variant={'outline'} onClick={open}>
        {t('add_section')}
      </Button>

      <SectionAdd visible={visible} onClose={onClose} onSave={onSave} />
    </>
  )
}
