import { createSlice } from '@reduxjs/toolkit'
import { DAILY_TRACKER_TYPE } from '@/features/tracker/consts/daily-tracker'
import { DailyTrackerModel } from '@/features/tracker/types'

interface IState {
  list: DailyTrackerModel[]
  loading: boolean
  error: any | null
  meta: { date: string } | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  meta: null,
}

const trackerDailyTrackerListSlice = createSlice({
  name: 'trackerDailyTrackerList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = [...state.list, ...action.payload.data]
      state.meta = action.payload.meta
    },
    updateEntryNote(state, action) {
      const { date, type, value }: { date: string; type: DAILY_TRACKER_TYPE; value: string } =
        action.payload

      state.list = state.list.map((item) => {
        if (item.date === date && item.type === type) {
          return {
            ...item,
            note: value,
          }
        } else {
          return item
        }
      })
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const trackerDailyTrackerList = trackerDailyTrackerListSlice.actions

export default trackerDailyTrackerListSlice.reducer
