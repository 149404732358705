import React from 'react'
import { Tabs as MantineTabs, TabsProps } from '@mantine/core'
import { ContentShell } from './ContentShell'
import useStyles from './Tabs.styles'

interface CustomTabProps {
  label: React.ReactNode
  value: string
  icon?: React.ReactNode
  render?: () => React.ReactNode
}

interface IProps extends Omit<TabsProps, 'children'> {
  value: string
  onChange: (value: any) => void
  tabs: Array<CustomTabProps>
}

export const Tabs = ({ value, tabs, onChange }: IProps) => {
  const { classes } = useStyles()

  const onTabChange = (tab: string) => {
    if (tab !== value) {
      onChange(tab)
    }
  }

  return (
    <>
      <MantineTabs
        value={value}
        onTabChange={onTabChange}
        classNames={classes}
        radius="xl"
        variant={'pills'}
        keepMounted={false}
      >
        <MantineTabs.List position={'center'}>
          {tabs.map(({ label, value, icon }) => (
            <MantineTabs.Tab key={value} value={value} icon={icon}>
              {label}
            </MantineTabs.Tab>
          ))}
        </MantineTabs.List>

        <ContentShell>
          {tabs.map(({ value, render }) => (
            <MantineTabs.Panel key={value} value={value}>
              {render && render()}
            </MantineTabs.Panel>
          ))}
        </ContentShell>
      </MantineTabs>
    </>
  )
}
