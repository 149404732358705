import React from 'react'
import { Popover, PopoverProps } from '@mantine/core'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { SettingsUpdateContainer } from './SettingsUpdateContainer'

interface IProps {
  children: React.ReactNode
  visible: boolean
  onClose: () => void
  onSuccess: (data: { sections: string[] }) => void
  sectionsData?: ForecastSectionModel[]
  sectionsValue?: number[]
  popoverProps?: Omit<PopoverProps, 'children'>
}

export const SettingsUpdate = ({
  children,
  visible,
  onClose,
  onSuccess,
  sectionsData,
  sectionsValue,
  popoverProps,
}: IProps) => {
  return (
    <>
      <Popover opened={visible} onClose={onClose} {...popoverProps}>
        <Popover.Target>{children}</Popover.Target>

        <Popover.Dropdown>
          <SettingsUpdateContainer
            onCancel={onClose}
            onSuccess={onSuccess}
            sectionsData={sectionsData}
            sectionsValue={sectionsValue}
          />
        </Popover.Dropdown>
      </Popover>
    </>
  )
}
