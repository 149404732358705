import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { GeneralHeader as GeneralPageHeader } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { Filter } from '../../Filters'
import { SettingsControl } from './SettingsControl'

interface IProps {
  config?: {}
  filters: {
    date: string | null
  }
  onFiltersChange: (data: object) => void
  onSettingsSubmitted: (data: { sections: string[] }) => void
  sectionsData?: ForecastSectionModel[]
  sectionsValue?: number[]
}

const defaultConfig = {}

export const SectionsDailyTrackerHeader = ({
  config,
  filters,
  onFiltersChange,
  onSettingsSubmitted,
  sectionsData,
  sectionsValue,
}: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <GeneralPageHeader>
      <Group grow>
        <Group align={'flex-start'} position={'right'}>
          <SettingsControl
            onSuccess={onSettingsSubmitted}
            sectionsData={sectionsData}
            sectionsValue={sectionsValue}
          />

          <Filter values={filters} onFiltersChange={onFiltersChange} />
        </Group>
      </Group>
    </GeneralPageHeader>
  )
}
