import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { RadioGroup, RadioGroupProps } from '../../RadioGroup'

interface RadioGroupControllerProps<T extends FieldValues> extends RadioGroupProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const RadioGroupController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: RadioGroupControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return <RadioGroup {...field} fieldRef={ref} {...rest} meta={meta} />
      }}
    />
  )
}
