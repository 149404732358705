import { cloneDeep, isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { UseForecastEffectProps } from '../types'

export const useForecastEffect = ({
  initialData = {
    sections: [],
  },
  store,
  forecastForm,
  dates,
  willInitForm,
  onWillInitFormChange,
}: UseForecastEffectProps) => {
  const { form } = forecastForm
  const { watch } = form

  const presentSections = useMemo(() => {
    return cloneDeep(store?.state?.data?.sections || [])
  }, [store?.state?.data?.sections])

  const setSections = useCallback((sections: any[]) => {
    store.actions.setSections(sections)
  }, [])

  const resetData = useCallback((state: any) => {
    store.actions.resetData(state)
  }, [])

  useDeepCompareEffect(() => {
    onWillInitFormChange(true)

    const sections = initialData.sections

    resetData({ sections })
  }, [initialData.sections])

  const sectionsValue = watch('sections')

  const [formChanged, setFormChanged] = React.useState(false)

  const onFormChange = useCallback((value: any) => {
    setFormChanged(true)
  }, [])

  useEffect(() => {
    const sections = cloneDeep(sectionsValue)

    if (formChanged && !isEqual(presentSections, sections)) {
      setSections(sections)
    }

    setFormChanged(false)
  }, [formChanged])

  useEffect(() => {
    if (willInitForm.current) {
      form.reset({ sections: [...presentSections] })
      onWillInitFormChange(false)
    }
  }, [presentSections])

  useEffect(() => {
    const subscription = watch((values: any) => {
      onFormChange(values)
    })
    return () => subscription.unsubscribe()
  }, [watch])
}
