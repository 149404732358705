import React from 'react'
import { useMantineColorScheme } from '@mantine/core'
import { Logo } from './Logo'
import { LogoV2 } from './LogoV2'

interface IProps {
  name?: string

  [x: string]: any
}

export const LogoIcon = ({ name, ...props }: IProps) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const dark = colorScheme === 'dark'

  switch (name) {
    case 'v2':
      return <LogoV2 {...props} />

    default:
      return <Logo dark={dark} {...props} />
  }
}
