import React from 'react'
import { useTranslation } from 'react-i18next'
import { CloseButton } from '@/components/Elements'
import { useFSTrackerUpdate } from '@/features/forecast-section/hooks'

interface IProps {
  id: number
  onSuccess?: (data: any) => void
}

export const TrackerActiveControl = ({ id, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { loading, onSubmit } = useFSTrackerUpdate()

  const onUpdate = async () => {
    const data = await onSubmit({ id, params: { active: false } })

    onSuccess && onSuccess({ id, active_tracker_section: false })
  }

  return (
    <>
      <CloseButton onClick={onUpdate} disabled={loading} />
    </>
  )
}
