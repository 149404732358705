import React from 'react'
import { useMantineTheme } from '@mantine/core'
import { Badge, BadgeProps } from '@/components/Elements'

interface IProps extends BadgeProps {
  light?: boolean
}

export const SectionTitleBadge = ({ light = false, ...props }: IProps) => {
  const theme = useMantineTheme()
  return (
    <Badge
      size={'xl'}
      radius={'xl'}
      color={theme.primaryColor}
      styles={{
        root: {
          height: '46px',
          lineHeight: '44px',
          backgroundColor: light ? theme.white : undefined,
        },
      }}
      {...props}
    >
      {props.children}
    </Badge>
  )
}
