import React from 'react'
import { useTranslation } from 'react-i18next'
import { INPUT_SYMBOL, INPUT_TYPE } from '@/features/input/consts/input'
import { useInputStaticOptions } from '@/features/input/hooks'

export const useInputModel = () => {
  const { t } = useTranslation()

  const { isPercentageSymbol, isCustomType, isConstantType } = useInputStaticOptions()

  const isCustomPercentageInput = (data: { type: INPUT_TYPE; symbol: INPUT_SYMBOL }) => {
    const { type, symbol } = data

    return (isCustomType(type) || isConstantType(type)) && isPercentageSymbol(symbol)
  }

  return {
    isCustomPercentageInput,
  }
}
