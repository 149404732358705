import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputModel } from '@/features/input/types'
import { SectionInputsForm } from '../../SectionInputsForm'

interface IProps {
  onCancel?: () => void
  onSave: (params: { inputs: string[] }) => Promise<void>
  inputsData?: InputModel[]
  inputsValue?: number[]
}

export const SectionInputsUpdateContainer = ({
  onCancel,
  onSave,
  inputsData,
  inputsValue = [],
}: IProps) => {
  const { t } = useTranslation()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    await onSave(params)
  }

  const initialValues = useMemo(() => {
    return {
      inputs: inputsValue?.filter((id) => id).map((item) => item.toString()),
    }
  }, [inputsValue])

  return (
    <>
      <SectionInputsForm
        onSubmit={onSubmit}
        inputsData={inputsData}
        initialValues={initialValues}
      />
    </>
  )
}
