import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@mantine/core'
import { ForecastSectionInputFormValue } from '@/features/forecast/types'
import { Edit } from './Edit'

interface IProps {
  data: ForecastSectionInputFormValue
}

export const InputCell = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { name, input_id } = data ?? {}

  return (
    <>
      <Flex align="center" className={'grow'} gap={'xs'}>
        <Flex align="center" className={'grow'}>
          <Text>{name || '-'}</Text>
        </Flex>

        <Edit inputId={input_id} />
      </Flex>
    </>
  )
}
