import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { trackerTrackerForecastSectionMassUpdatePA } from '@/features/tracker/store'
import { useNotify } from '@/hooks'
import { SettingsForm } from '../../SettingsForm'

interface IProps {
  onCancel?: () => void
  onSuccess: (data: { sections: string[] }) => void
  sectionsData?: ForecastSectionModel[]
  sectionsValue?: number[]
}

export const SettingsUpdateContainer = ({
  onCancel,
  onSuccess,
  sectionsData,
  sectionsValue = [],
}: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }
    await dispatch(trackerTrackerForecastSectionMassUpdatePA.request({ params }))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(values)
  }

  const initialValues = useMemo(() => {
    return {
      sections: sectionsValue?.map((item) => item.toString()),
    }
  }, [sectionsValue])

  return (
    <>
      <SettingsForm onSubmit={onSubmit} sectionsData={sectionsData} initialValues={initialValues} />
    </>
  )
}
