import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Grid } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { FileInputController } from '@/components/FormElements'
import { FILE_MAX_SIZE } from '@/const/form'
import { ImportModeSelectController } from '@/features/import/components/FormElements'
import { useFormSubmit } from '@/hooks'
import { FileUtils } from '@/utils'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
}

export const ImportForm = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const defaultValues = {
    type: 'forecast',
    mode: null,
    file: null,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
    control,
    reset,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
      }

      await onFormSubmit(values)

      reset()
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Col xs={6}>
              <ImportModeSelectController
                control={control}
                name={'mode'}
                id={'mode'}
                label={t('mode')}
                placeholder={t('mode')}
                mb={'md'}
                clearable
              />
            </Grid.Col>
          </Grid>

          <FileInputController
            control={control}
            name={'file'}
            id={'file'}
            label={t('file')}
            placeholder={t('file')}
            mb={'md'}
            accept={'text/csv'}
            translateParams={{
              text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
            }}
          />

          <div className={'mt-2'}>
            <Group>
              <Button loading={isSubmitting} type={'submit'}>
                {t('upload')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
