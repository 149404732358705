import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  return {
    wrap: {
      position: 'relative',
      left: 0,
    },

    buttonWrap: {
      position: 'sticky',
      right: 0,
    },
  }
})
