import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { ScrollArea } from '@mantine/core'
import { Card, OverlayDataShower } from '@/components/Elements'
import { GeneralPageLayoutShell } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { SectionsDailyTrackerHeader } from '@/features/tracker/components/DailyTracker/Layouts'
import { ForecastSectionsDailyTrackerTable } from '@/features/tracker/components/DailyTracker/Tables'
import {
  useDailyTrackerNoteUpdate,
  useDailyTrackerQueryParam,
  useTrackerForecastSectionsList,
  useTrackerInputsList,
} from '@/features/tracker/hooks'
import {
  selectTrackerFSListActiveTrackerFSData,
  trackerSectionsDailyTrackerList,
} from '@/features/tracker/store'
import { SectionsDailyTrackerModel } from '@/features/tracker/types'
import { useInfiniteScroll } from '@/hooks'
import { RootState } from '@/store'
import { DateUtils } from '@/utils'
import { LoadMore } from './LoadMore'

interface IProps {
  list: SectionsDailyTrackerModel[]
  loading: boolean
  error: any
  meta: { date: string } | null
  isSuccess: boolean

  activeTrackerFSData: {
    items: ForecastSectionModel[]
    ids: number[]
  }
}

const SectionsDailyTrackerContainerComponent = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { filters, onFiltersChange } = useDailyTrackerQueryParam()

  const [syncCount, forceUpdate] = React.useReducer((x: number) => x + 1, 0)

  const cleanState = () => {
    dispatch(trackerSectionsDailyTrackerList.cleanState())
  }

  useEffect(() => {
    return () => {
      cleanState()
    }
  }, [])

  useDeepCompareEffect(() => {
    cleanState()

    fetchList(filters)
  }, [filters, syncCount])

  const syncData = () => {
    forceUpdate()
  }

  const fetchList = ({ date }: { date: string }) => {
    const params = {
      date,
    }

    dispatch(trackerSectionsDailyTrackerList.getList({ params }))
  }

  const onFilterFormChange = (values: any) => {
    const { date } = values

    onFiltersChange({ date: DateUtils.formatDateToServerMonthYearFormat(date) })
  }

  const { getInputDataById } = useTrackerInputsList()

  const {
    list: sectionsData,
    massUpdateTrackerForecastSectionsActiveStatus,
    updateTrackerForecastSection,
  } = useTrackerForecastSectionsList()

  const onSettingsSubmitted = (data: { sections: string[] }) => {
    massUpdateTrackerForecastSectionsActiveStatus(data?.sections)

    syncData()
  }

  const onTrackerFSActiveSuccess = async (data: any) => {
    updateTrackerForecastSection(data)

    dispatch(trackerSectionsDailyTrackerList.removeSection(data))
  }

  const { onSubmit: onDailyTrackerNoteSubmit } = useDailyTrackerNoteUpdate()

  const onNoteUpdate = async (data: any) => {
    await onDailyTrackerNoteSubmit(data)

    dispatch(trackerSectionsDailyTrackerList.updateEntryNote(data))
  }

  const getNextMonth = useCallback((current: string | null) => {
    let next = null

    if (current) {
      const date = moment(DateUtils.monthYearToDate(current))

      const nextDate = date.clone().add(1, 'month').toDate()
      next = DateUtils.formatDateToServerMonthYearFormat(nextDate)
    }

    return next
  }, [])

  const hasNext = useMemo(() => {
    if (props.meta?.date) {
      return !!getNextMonth(props.meta?.date)
    }
    return false
  }, [props.meta?.date])

  const isMoreLoading = useMemo(() => {
    return props.loading && !!props.meta
  }, [props.loading, props.meta])

  const isLoading = useMemo(() => {
    return props.loading && !isMoreLoading
  }, [props.loading, isMoreLoading])

  const onLoadMore = () => {
    if (props.meta?.date) {
      const next = getNextMonth(props.meta?.date)

      fetchList({ date: next })
    }
  }

  const { lastRef } = useInfiniteScroll({
    isLoading: props.loading,
    hasNext,
    onLoad: onLoadMore,
  })

  return (
    <GeneralPageLayoutShell
      grow
      header={
        <SectionsDailyTrackerHeader
          filters={filters}
          onFiltersChange={onFilterFormChange}
          onSettingsSubmitted={onSettingsSubmitted}
          sectionsData={sectionsData}
          sectionsValue={props.activeTrackerFSData.ids}
        />
      }
    >
      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <Card px={0} py={0} className={'overflow-visible h-full'}>
          <ScrollArea
            className={'!absolute inset-0'}
            styles={{
              scrollbar: {
                zIndex: 3,
              },
            }}
          >
            <ForecastSectionsDailyTrackerTable
              data={props.list}
              loading={isLoading}
              getInputDataById={getInputDataById}
              lastRef={lastRef}
              onNoteUpdate={onNoteUpdate}
              onTrackerFSActiveSuccess={onTrackerFSActiveSuccess}
            />

            {!isLoading && (
              <LoadMore hasNext={hasNext} loading={isMoreLoading} onLoadMore={onLoadMore} />
            )}
          </ScrollArea>
        </Card>
      </OverlayDataShower>
    </GeneralPageLayoutShell>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error, meta, isSuccess } = state.tracker.sectionsDailyTracker.list

  const activeTrackerFSData = selectTrackerFSListActiveTrackerFSData(state)

  return {
    list,
    loading,
    error,
    meta,
    isSuccess,
    activeTrackerFSData,
  }
}

export const SectionsDailyTrackerContainer = connect(mapStateToProps)(
  SectionsDailyTrackerContainerComponent
)
