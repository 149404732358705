import React from 'react'
import { useTranslation } from 'react-i18next'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'
import { useIntegrationStaticOptions } from '@/features/integration/hooks'
import { integrationShopifySetupPA } from '@/features/integration/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useIntegrationSetup = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const { isShopifyProvider } = useIntegrationStaticOptions()

  const onSubmit = async (provider: INTEGRATION_SYSTEM_PROVIDER, params: any) => {
    try {
      const action = isShopifyProvider(provider) ? integrationShopifySetupPA : null

      if (action) {
        const data = await onAsyncDispatch(action.request, { params })

        showNotification({
          type: 'success',
          message: t('saved_successfully'),
        })

        return data
      }
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })

      throw error
    }
  }

  return {
    onSubmit,
    loading,
  }
}
