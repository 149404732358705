import * as Yup from 'yup'
import {
  CUSTOM_INPUT_MAX_VALUE,
  CUSTOM_INPUT_MIN_VALUE,
  INPUT_SYMBOL,
  INPUT_TYPE,
} from '@/features/input/consts/input'
import { inputHelper } from '@/features/input/helpers'

const commonValidation = {
  name: Yup.string().nullable().required('field.error.required'),
  description: Yup.string().nullable().required('field.error.required'),
  type: Yup.mixed().nullable().required('field.error.required'),
  symbol: Yup.mixed()
    .nullable()
    .when(['input_b_id', 'type'], {
      is: (input_b_id: string, type: INPUT_TYPE) => type === INPUT_TYPE.CONSTANT || !!input_b_id,
      then: (schema) => schema.required('field.error.required'),
      otherwise: (schema) => schema,
    }),
}

export const validationSchema = Yup.object().shape(
  {
    ...commonValidation,
    function_type: Yup.mixed()
      .nullable()
      .when('type', {
        is: (type: INPUT_TYPE) => type === INPUT_TYPE.CUSTOM,
        then: (schema) => schema.required('field.error.required'),
        otherwise: (schema) => schema,
      }),
    input_a_id: Yup.mixed()
      .nullable()
      .when('type', {
        is: (type: INPUT_TYPE) => type === INPUT_TYPE.CUSTOM,
        then: (schema) => schema.required('field.error.required'),
        otherwise: (schema) => schema,
      }),
    action: Yup.mixed()
      .nullable()
      .when('input_b_id', {
        is: (input_b_id: string) => !!input_b_id,
        then: (schema) => schema.required('field.error.required'),
        otherwise: (schema) => schema,
      }),
    input_b_id: Yup.mixed()
      .nullable()
      .when('action', {
        is: (action: string) => !!action,
        then: (schema) => schema.required('field.error.required'),
        otherwise: (schema) => schema,
      }),
    value: Yup.number()
      .nullable(true)
      .transform((v, o) => (o === '' ? null : v))
      .min(CUSTOM_INPUT_MIN_VALUE, 'field.error.value.between')
      .max(CUSTOM_INPUT_MAX_VALUE, 'field.error.value.between')
      .when('type', {
        is: (type: INPUT_TYPE) => type === INPUT_TYPE.CONSTANT,
        then: (schema) => schema.required('field.error.required'),
        otherwise: (schema) => schema,
      })
      .when('symbol', {
        is: (symbol: INPUT_SYMBOL) => inputHelper.symbol.isPercentage(symbol),
        then: (schema) => schema.integer('field.error.integer'),
        otherwise: (schema) => schema,
      }),
  },
  [['action', 'input_b_id']]
)
