import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollArea, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { CheckboxGroup, TextInput } from '@/components/FormElements'

type Option = {
  label?: React.ReactNode
  value: string
  name: string
}

export interface InputsPickerProps {
  initialInputValue?: string
  options: Option[]
  value?: string[]
  onChange?: (value: string[]) => void
}

const defaultFilter = (value: string, item: Option) => {
  return item.name.toLowerCase().trim().includes(value.toLowerCase().trim())
}

export const InputsPicker = ({
  initialInputValue = '',
  options = [],
  value,
  onChange,
}: InputsPickerProps) => {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState<string>(initialInputValue)

  const filteredData = useMemo(() => {
    return options.filter((item) => defaultFilter(inputValue, item))
  }, [options, inputValue])

  return (
    <div className={'flex flex-col grow'}>
      <div className={'w-full'}>
        <TextInput
          value={inputValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(event.target.value)
          }
          name={'keywords'}
          id={'keywords'}
          placeholder={t('search')}
          icon={<Icon name={'search'} />}
          mb={'xs'}
          size={'xs'}
        />
      </div>

      <ScrollArea
        style={{ maxHeight: 256 }}
        className={'flex flex-col grow w-full'}
        offsetScrollbars
      >
        {filteredData.length > 0 ? (
          <CheckboxGroup
            value={value}
            onChange={onChange}
            options={filteredData}
            orientation="vertical"
            size={'xs'}
            spacing={'xs'}
          />
        ) : (
          <Text size={'xs'} pt={'xs'}>
            {t('no_options')}
          </Text>
        )}
      </ScrollArea>
    </div>
  )
}
