import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMountedState } from 'react-use'
import { UseDispatchingProps, UseDispatchingReturn } from '../types'

export const useDispatching = ({ ...props }: UseDispatchingProps): UseDispatchingReturn => {
  const { t } = useTranslation()

  const isMounted = useMountedState()

  const [error, setError] = useState<any>(null)

  const onError = (error: any) => {
    if (isMounted()) {
      setError(error)
    }
  }

  const resetError = () => {
    if (isMounted()) {
      setError(null)
    }
  }

  const onSubmitHandler = async (data: any) => {
    if (props.onSubmit) {
      const params = {
        ...data,
      }

      const payload = {}

      return props.onSubmit(params, payload)
    }
  }

  const onSubmitInvalid = async () => {
    throw 'Invalid'
  }

  const onSubmit = async () => {
    if (props.onSubmit) {
      try {
        resetError()

        return props.onHandleSubmit(onSubmitHandler, onSubmitInvalid)
      } catch (error: any) {
        const message = error?.message || t('error')
        onError(message)

        throw error
      }
    }
  }

  return {
    error,
    onError,
    resetError,
    onSubmit,
  }
}
