import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Button, Icon } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: any
  isEdit?: boolean
  onRemove?: () => void
}

export const SectionForm = ({
  onCancel,
  initialValues = {},
  isEdit = false,
  onRemove,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      name: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = methods

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
      }

      await props.onSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInputController
            control={control}
            name={'name'}
            id={'name'}
            label={t('name')}
            placeholder={t('name')}
            mb={'md'}
          />

          {isEdit && (
            <Group position={'right'} className={'mb-4'}>
              <Button
                variant="outline"
                color="red"
                onClick={onRemove}
                leftIcon={<Icon name={'x'} />}
              >
                {t('remove')}
              </Button>
            </Group>
          )}

          <div className={'mt-2'}>
            <Group position={'right'}>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
