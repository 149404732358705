import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    slideWrapper: {
      height: `100%`,
      width: `calc(100% - 64px - 64px)`, // 100% - 2 * control-width
      padding: `${theme.spacing.xl}px`,
      margin: `0 auto`,

      [theme.fn.smallerThan('sm')]: {
        padding: `${theme.spacing.sm}px`,
        width: `calc(100% - 32px - 32px)`, // 100% - 2 * control-width
      },
    },
  }
})
