import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@mantine/core'
import { ConfirmationControl } from '@/components/Controls'
import { Button } from '@/components/Elements'
import { useIntegrationRemove } from '@/features/integration/hooks'

interface IProps extends ButtonProps {
  id: number
  onSuccess?: (data: any) => void
}

export const RemoveAccountControl = ({ id, onSuccess, ...props }: IProps) => {
  const { t } = useTranslation()

  const { loading, onSubmit } = useIntegrationRemove()

  const onRemoveConfirm = async () => {
    await onSubmit(id, onSuccess)
  }

  return (
    <ConfirmationControl
      onConfirm={onRemoveConfirm}
      render={({ onOpen }) => (
        <Button onClick={onOpen} variant="outline" color="dark" loading={loading} {...props}>
          {t('remove_account')}
        </Button>
      )}
    />
  )
}
