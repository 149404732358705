import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton } from '@/components/Elements'
import { InputEdit } from '@/features/input/components/Input'
import { InputModel } from '@/features/input/types'
import { usePopup } from '@/hooks'

interface IProps {
  input: InputModel
  onSuccess?: (data: any) => void
}

export const EditControl = ({ input, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { id, type } = input

  const { visible, open, close } = usePopup()

  const onEditSuccess = (data: any) => {
    close()

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <div>
        <ActionIconButton name={'edit'} onClick={open} />
      </div>

      <InputEdit id={id} type={type} visible={visible} onClose={close} onSuccess={onEditSuccess} />
    </>
  )
}
