import React from 'react'
import { useTranslation } from 'react-i18next'
import { SectionDivider } from '@/features/landing/components/Elements'
import useStyles from './HomeContent.styles'
import {
  CTAv2Section,
  CTAv3Section,
  FeaturesSection,
  HeroSection,
  HowWorksSection,
  IntegrationsSection,
  ProductSection,
  TestimonialsSection,
} from './Sections'

export const HomeContent = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={'w-full'}>
      <HeroSection />

      <ProductSection />

      <FeaturesSection />

      <CTAv2Section />

      <IntegrationsSection />

      <SectionDivider />

      <HowWorksSection />

      <TestimonialsSection />

      <CTAv3Section />
    </div>
  )
}
