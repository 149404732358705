import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@mantine/core'
import { Button } from '@/components/Elements'
import useStyles from './LoadMore.styles'

interface IProps {
  hasNext: boolean
  loading: boolean
  onLoadMore: () => void
}

export const LoadMore = ({ hasNext, loading, onLoadMore }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      {(loading || hasNext) && (
        <Flex align={'center'} justify={'flex-end'} my={'xl'} className={classes.wrap}>
          <Box px="md" className={classes.buttonWrap}>
            <Button loading={loading} onClick={onLoadMore}>
              {t('load_more')}
            </Button>
          </Box>
        </Flex>
      )}
    </>
  )
}
