import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultForecastSessionDTO } from '@/features/forecast-session/api/dto'
import { forecastSessionSavePA } from '@/features/forecast-session/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useForecastSessionSave = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, error, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async ({ params }: { params: DefaultForecastSessionDTO }) => {
    try {
      const data = await onAsyncDispatch(forecastSessionSavePA.request, { params })

      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })

      return data
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onSubmit,
    loading,
    error,
  }
}
