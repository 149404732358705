import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { SettingsUpdate } from '@/features/tracker/components/TrackerSettings'
import { usePopup } from '@/hooks'

interface IProps {
  onSuccess: (data: { sections: string[] }) => void
  sectionsData?: ForecastSectionModel[]
  sectionsValue?: number[]
}

export const SettingsControl = ({ onSuccess, sectionsData, sectionsValue }: IProps) => {
  const { t } = useTranslation()

  const { visible, close, toggle } = usePopup()

  const onSubmitSuccess = (data: any) => {
    close()

    onSuccess(data)
  }

  return (
    <>
      <SettingsUpdate
        visible={visible}
        onClose={close}
        onSuccess={onSubmitSuccess}
        sectionsData={sectionsData}
        sectionsValue={sectionsValue}
        popoverProps={{
          width: 300,
        }}
      >
        <ActionIcon variant={'transparent'} size={42} onClick={toggle}>
          <Icon name={'gear'} size={24} />
        </ActionIcon>
      </SettingsUpdate>
    </>
  )
}
