import React from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleGrid, Text, Title } from '@mantine/core'
import bg1 from '@/assets/images/illustrations/how-works-bg-step-1.svg'
import bg2 from '@/assets/images/illustrations/how-works-bg-step-2.svg'
import bg3 from '@/assets/images/illustrations/how-works-bg-step-3.svg'
import icon1 from '@/assets/images/illustrations/how-works-icon-step-1.svg'
import icon2 from '@/assets/images/illustrations/how-works-icon-step-2.svg'
import icon3 from '@/assets/images/illustrations/how-works-icon-step-3.svg'
import useStyles from './Steps.styles'

export const Steps = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const data = [
    {
      bg: bg1,
      icon: icon1,
      title: t('landing.how_works-section.step-1.title'),
      text: t('landing.how_works-section.step-1.text'),
    },
    {
      bg: bg2,
      icon: icon2,
      title: t('landing.how_works-section.step-2.title'),
      text: t('landing.how_works-section.step-2.text'),
    },
    {
      bg: bg3,
      icon: icon3,
      title: t('landing.how_works-section.step-3.title'),
      text: t('landing.how_works-section.step-3.text'),
    },
  ]

  return (
    <div>
      <SimpleGrid
        cols={3}
        spacing={38}
        breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}
        style={{ marginTop: 50, marginBottom: 50 }}
      >
        {data.map(({ bg, icon, title, text }, i) => (
          <div key={i}>
            <div className={classes.itemHeader}>
              <img alt={'bg'} src={bg} className={classes.itemHeaderBg} />
            </div>

            <div className={classes.itemContentBlock}>
              <div className={classes.itemIcon}>
                <img alt={'icon'} src={icon} />
              </div>

              <div>
                <Title fz={28}>{`${i + 1}. ${title}`}</Title>
                <Text component={'p'}>{text}</Text>
              </div>
            </div>
          </div>
        ))}
      </SimpleGrid>
    </div>
  )
}
