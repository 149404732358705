import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    card: {
      height: '100%',
    },

    inner: {
      display: 'flex',
      height: '100%',
    },

    mediaBlock: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 50,
      backgroundColor: theme.colors[theme.primaryColor][1],

      [theme.fn.smallerThan('sm')]: {
        height: 200,
      },
    },

    img: {
      maxWidth: '100%',

      [theme.fn.smallerThan('sm')]: {
        maxHeight: '100%',
      },
    },
  }
})
