import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mantine/core'
import { DataShower } from '@/components/Elements'
import { GeneralPageLayoutShell } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { useImportDataEffect, useImportDataQuery } from '@/features/import/hooks'
import { InputModel } from '@/features/input/types'
import { IntegrationSystemProvider } from '@/features/integration/components/IntegrationSystem/IntegrationSystem'
import { GeneralHeader } from '@/features/integration/components/IntegrationSystem/IntegrationSystems/Layouts'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'
import { useIntegrationSystem } from '@/features/integration/hooks'
import { integrationIntegrationSystemsList } from '@/features/integration/store'
import { IntegrationSystem, IntegrationSystemModel } from '@/features/integration/types'
import { RootState } from '@/store'

interface IProps {
  list: IntegrationSystem[]
  loading: boolean
  error: any
}

const GeneralIntegrationSystemsContainerComponent = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [syncCount, forceUpdate] = React.useReducer((x: number) => x + 1, 0)

  useEffect(() => {
    return () => {
      dispatch(integrationIntegrationSystemsList.cleanState())
    }
  }, [])

  useImportDataEffect({})
  const {
    state: { data: importData, isLoading: importDataIsLoading },
  } = useImportDataQuery()

  const fetchList = () => {
    dispatch(integrationIntegrationSystemsList.getList())
  }

  useEffect(() => {
    fetchList()
  }, [])

  const syncData = () => {
    forceUpdate()
  }

  const onAccountUpdate = (provider: INTEGRATION_SYSTEM_PROVIDER, data: IntegrationSystemModel) => {
    dispatch(integrationIntegrationSystemsList.updateSystemData({ provider, data }))
  }

  const onAccountRemoveSuccess = (provider: INTEGRATION_SYSTEM_PROVIDER) => {
    dispatch(integrationIntegrationSystemsList.removeSystemData({ provider }))
  }

  const { isSyncStartAllow, isInProgressStatus } = useIntegrationSystem()

  const integrationsState = useMemo(() => {
    let syncAllAllow = false
    let syncInProgress = false
    let hasConnectedIntegrations = false

    props.list.forEach((item) => {
      if (item.data) {
        if (!syncAllAllow) {
          syncAllAllow = isSyncStartAllow(item.data?.status)
        }

        if (!syncInProgress) {
          syncInProgress = isInProgressStatus(item.data?.status)
        }

        hasConnectedIntegrations = true
      }
    })

    return {
      syncAllAllow,
      syncInProgress,
      hasConnectedIntegrations,
    }
  }, [props.list])

  const { syncAllAllow, syncInProgress, hasConnectedIntegrations } = integrationsState

  return (
    <GeneralPageLayoutShell
      grow
      header={
        <GeneralHeader
          onSyncAll={syncData}
          syncAllAllow={syncAllAllow}
          syncInProgress={syncInProgress}
          isImportActive={importData?.active}
          hasConnectedIntegrations={hasConnectedIntegrations}
        />
      }
    >
      <DataShower
        isLoading={props.loading || importDataIsLoading}
        isFailed={!!props.error}
        error={props.error}
        isFetched={!!props.list.length}
      >
        <Grid>
          {props.list.map((item) => (
            <React.Fragment key={item.provider}>
              <Grid.Col sm={6} xl={3}>
                <IntegrationSystemProvider
                  data={item}
                  provider={item.provider}
                  onAccountUpdate={onAccountUpdate}
                  onAccountRemoveSuccess={onAccountRemoveSuccess}
                  syncCount={syncCount}
                  syncDisabled={syncInProgress || importData?.active}
                />
              </Grid.Col>
            </React.Fragment>
          ))}
        </Grid>
      </DataShower>
    </GeneralPageLayoutShell>
  )
}

const mapStateToProps = (state: RootState) => {
  const { list, loading, error } = state.integration.integrationSystems.list
  return {
    list,
    loading,
    error,
  }
}

export const GeneralIntegrationSystemsContainer = connect(mapStateToProps)(
  GeneralIntegrationSystemsContainerComponent
)
