import {
  DatePickerType,
  MonthPickerInput as MantineMonthPickerInput,
  MonthPickerInputProps as MantineMonthPickerInputProps,
} from 'mantine-dates-6'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLang } from '@/hooks'
import { FormUtils } from '@/utils'

export interface MonthPickerInputProps extends MantineMonthPickerInputProps<DatePickerType> {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const MonthPickerInput = ({
  onChange,
  normalize,
  meta = {},
  fieldRef,
  translateParams,
  ...props
}: MonthPickerInputProps) => {
  const { t } = useTranslation()

  const { lang } = useLang()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <MantineMonthPickerInput
      {...props}
      locale={lang}
      value={props.value}
      onChange={onChange}
      error={showError ? getErrorMessage() : null}
    />
  )
}
