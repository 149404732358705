import React from 'react'
import { useTranslation } from 'react-i18next'
import { AdjustForecastControl } from '../../../../Controls'

interface IProps {
  inputId: number
}

export const Edit = ({ inputId }: IProps) => {
  const { t } = useTranslation()

  return <AdjustForecastControl inputId={inputId} />
}
