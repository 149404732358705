import React, { useMemo } from 'react'
import { NumericFormat } from 'react-number-format'
import { CommonUtils } from '@/utils'

interface IProps {
  value: string | number | null
  allowDecimalZeros?: boolean
  decimalScale?: number
  fixedDecimalScale?: boolean
}

export const GeneralNumericFormat = ({
  value = '',
  allowDecimalZeros = false,
  decimalScale = 2,
  ...props
}: IProps) => {
  const formattedValue = useMemo(() => {
    if (!(CommonUtils.isNil(value) || CommonUtils.isNanValue(value)) && !allowDecimalZeros) {
      if (typeof value === 'string') {
        const number = +value

        return Number.isInteger(number) ? number : value
      }
    }

    return value
  }, [value, allowDecimalZeros])

  return (
    <NumericFormat
      value={formattedValue}
      displayType="text"
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={decimalScale}
      {...props}
    />
  )
}
