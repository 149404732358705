import axios from 'axios'
import { AdjustStepForecastSessionStepDTO } from '@/features/forecast-session/api/dto'

class ForecastSessionAdjustStepAPI {
  adjustStepForecastSession = async (data: AdjustStepForecastSessionStepDTO) => {
    return axios.post(`api/forecast-session/adjust-step`, data)
  }
}

export const forecastSessionAdjustStepAPI = new ForecastSessionAdjustStepAPI()
