import React from 'react'
import { useTranslation } from 'react-i18next'
import { HistoryState } from '@/features/forecast/hooks/useForecastCore'
import { StoreData, StoreSectionType, UseStoreProps, UseStoreReturn } from '../types'

export const useStore = ({ history }: UseStoreProps): UseStoreReturn => {
  const { t } = useTranslation()

  const { state, actions } = history

  const { setSections: historySetSection, setData: historySetData, ...historyActions } = actions

  const { present, past } = state

  const setSections = (sections: StoreSectionType[]) => {
    actions.setSections(sections)
  }

  const resetData = (data: StoreData) => {
    const state: HistoryState = {
      data,
    }

    actions.reset(state)
  }

  const cleanStore = () => {
    const initialData = past[0] || present
    actions.reset(initialData)
  }

  return {
    state: present,
    actions: {
      setSections,
      resetData,
      cleanStore,
      ...historyActions,
    },
  }
}
