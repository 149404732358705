import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/store'

interface AuthState {
  isLoggedIn: boolean
}

const initialState: AuthState = {
  isLoggedIn: false,
}

const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setData(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn
    },
    cleanData() {
      return initialState
    },
  },
})

export const authData = authDataSlice.actions

export const selectAuthData = (state: RootState) => state.auth.data

export default authDataSlice.reducer
