import axios from 'axios'
import { UpdateNoteDailyTrackerDTO } from './dto'

class DailyTrackerAPI {
  getDailyTracker = async (params: any = {}) => {
    return axios.get(`api/daily-tracker`, {
      params,
    })
  }

  updateDailyTrackerNote = async (data: UpdateNoteDailyTrackerDTO) => {
    return axios.post(`api/daily-tracker/notes`, data)
  }
}

export const dailyTrackerAPI = new DailyTrackerAPI()
