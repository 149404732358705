import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useLang } from '@/hooks'
import { useAppDispatch } from '@/store'
import { authRegisterPA } from '../../../store'
import { FormValues, RegisterForm } from './RegisterForm'

interface IProps {
  initialValues?: any
}

export const RegisterContainer = ({ initialValues = {} }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { lang } = useLang()

  const handleSubmit = async (values: FormValues) => {
    const params = {
      ...values,
      phone: values.phone.replace(/[^0-9]/g, ''),
      // role,
      // lang,
    }
    await dispatch(authRegisterPA.request(params))

    navigate('/email-verification')
  }

  return (
    <AuthCard title={t('registration')}>
      <div className={'flex'}>
        <Title order={6}>
          {t('already_have_account')}?{' '}
          <Anchor component={Link} to={'/login'}>
            {t('login')}
          </Anchor>
        </Title>
      </div>

      <Space h={'md'} />

      <RegisterForm initialValues={initialValues} onSubmit={handleSubmit} />
    </AuthCard>
  )
}
