import React from 'react'
import { ModalProps } from '@mantine/core'
import { ConfirmModal } from '@/components/Elements'
import { useConfirmPopup } from '@/hooks'

interface IProps {
  render: (props: { onOpen: (data: any) => void }) => React.ReactNode
  onConfirm: () => void
  message?: string | null
  modalProps?: any
}

export const ConfirmationControl = ({ onConfirm, render, message, modalProps }: IProps) => {
  const {
    visible,
    open,
    close,
    onConfirm: onPopupConfirm,
  } = useConfirmPopup({ confirm: onConfirm })

  return (
    <>
      {render({
        onOpen: open,
      })}

      <ConfirmModal
        visible={visible}
        onClose={close}
        onConfirm={onPopupConfirm}
        message={message}
        modalProps={modalProps}
      />
    </>
  )
}
