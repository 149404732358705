import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  INTEGRATION_SYSTEM_PROVIDER,
  INTEGRATION_SYSTEM_STATUS,
} from '@/features/integration/consts/system'
import { integrationSystemHelper } from '@/features/integration/helpers'

export const useIntegrationStaticOptions = () => {
  const { t } = useTranslation()

  const isFacebookProvider = (value: INTEGRATION_SYSTEM_PROVIDER) => {
    return integrationSystemHelper.provider.isFacebook(value)
  }

  const getStatus = (value: INTEGRATION_SYSTEM_STATUS) => {
    return integrationSystemHelper.status.getStatus(value)
  }

  const isKlaviyoProvider = (value: INTEGRATION_SYSTEM_PROVIDER) => {
    return integrationSystemHelper.provider.isKlaviyo(value)
  }

  const isShopifyProvider = (value: INTEGRATION_SYSTEM_PROVIDER) => {
    return integrationSystemHelper.provider.isShopify(value)
  }

  const isGoogleAdsProvider = (value: INTEGRATION_SYSTEM_PROVIDER) => {
    return integrationSystemHelper.provider.isGoogleAds(value)
  }

  return {
    isFacebookProvider,
    getStatus,
    isKlaviyoProvider,
    isShopifyProvider,
    isGoogleAdsProvider,
  }
}
