import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Button, Icon } from '@/components/Elements'
import { useForecastContext } from '@/features/forecast/contexts'
import { GoalsResetControl } from '../GoalsResetControl'

export const ChangesControls = () => {
  const { t } = useTranslation()

  const {
    forecast: {
      state: { isDispatching },
    },
    actions: { canUndo, discardChanges, onSave, onGoalsResetSubmit },
  } = useForecastContext()

  const onHandleSubmit = async () => {
    await onSave()
  }

  return (
    <Group>
      <Button
        disabled={!canUndo || isDispatching}
        onClick={discardChanges}
        leftIcon={<Icon name={'arrow-counterclockwise'} />}
        variant={'outline'}
      >
        {t('discard_changes')}
      </Button>

      <Button
        loading={isDispatching}
        disabled={!canUndo}
        onClick={onHandleSubmit}
        leftIcon={<Icon name={'save'} />}
        variant={'outline'}
      >
        {t('save_changes')}
      </Button>

      <GoalsResetControl onGoalsReset={onGoalsResetSubmit} />
    </Group>
  )
}
