import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { MonthPickerInputController } from '@/components/FormElements'

export const FilterForm = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Group align={'flex-start'}>
      <MonthPickerInputController
        control={control}
        name={'date'}
        id={'date'}
        placeholder={t('start_period')}
        allowDeselect={false}
        valueFormat="MMM YYYY"
      />
    </Group>
  )
}
