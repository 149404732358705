export enum INTEGRATION_SYSTEM_STATUS {
  SETUP = 'setup',
  NEW = 'new',
  ENABLED = 'enabled',
  COLLECTING_DATA = 'collecting_data',
  IN_PROGRESS = 'in_progress',
  ERROR_SYNC = 'error_sync',
  ERROR_FIRST_SYNC = 'error_first_sync',
  ERROR_ACCOUNT = 'error_account',
  ERROR_UNKNOWN = 'error_unknown',
}
