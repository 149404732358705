import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    wrapper: {
      position: 'relative',
      padding: '124px 54px',
      borderRadius: theme.radius.xl,
      backgroundColor: theme.colors['indigo'][7],
      overflow: 'hidden',

      [theme.fn.smallerThan('md')]: {
        paddingTop: 100,
        paddingBottom: 100,
      },

      [theme.fn.smallerThan('sm')]: {
        padding: '66px 25px',
      },
    },
  }
})
