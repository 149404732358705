import * as action from './action'
import * as functionType from './function-type'
import * as period from './period'
import * as symbol from './symbol'
import * as type from './type'

export const inputHelper = {
  type,
  functionType,
  action,
  symbol,
  period,
}
