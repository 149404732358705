import axios from 'axios'
import { KlaviyoIntegrationCreateDTO } from './dto'

class KlaviyoIntegrationAPI {
  createKlaviyoIntegration = async (data: KlaviyoIntegrationCreateDTO) => {
    return axios.post(`api/integrations/klaviyo`, data)
  }

  getMetrics = async (params: any = {}) => {
    return axios.get(`api/integrations/klaviyo/metrics`, {
      params,
    })
  }
}

export const klaviyoIntegrationAPI = new KlaviyoIntegrationAPI()
