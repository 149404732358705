import React from 'react'
import { Table } from '@mantine/core'
import useStyles from './GeneralTable.styles'

interface IProps {
  children: React.ReactNode
  className?: string
}

export const GeneralTable = ({ children, className, ...props }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <Table
      horizontalSpacing="md"
      verticalSpacing="sm"
      withColumnBorders
      {...props}
      className={cx('', classes.root, {
        [`${className}`]: className,
      })}
    >
      {children}
    </Table>
  )
}
