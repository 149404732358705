import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForecastContext } from '@/features/forecast/contexts'
import { ForecastSectionFormValue, ForecastSectionInputFormValue } from '@/features/forecast/types'
import { SectionInputsUpdate } from '../../../SectionInputs'

interface IProps {
  section: ForecastSectionFormValue
  index: number
  onClose: () => void
}

export const SectionInputsUpdateContainer = ({ section, index, onClose }: IProps) => {
  const { t } = useTranslation()

  const {
    values: { sections },
    actions: { structureUpdateInputs, onStepStructureUpdateInputs },
    inputsData,
  } = useForecastContext()

  const inputsDataMap = useMemo(() => {
    return inputsData.reduce((acc: any, curr) => {
      const { id } = curr

      return acc.set(id, curr)
    }, new Map())
  }, [inputsData])

  const inputsOptions = useMemo(() => {
    const disabledInputs = sections
      .filter((section, i) => i !== index && !!section.inputs.length)
      .flatMap((section) => {
        const ids = section.inputs.map(({ input_id }: ForecastSectionInputFormValue) => input_id)
        return [...ids]
      })

    return Array.from(inputsDataMap, ([key, value]) => ({
      ...value,
      disabled: disabledInputs.includes(key),
    }))
  }, [inputsDataMap])

  const onSave = async (params: { inputs: string[] }) => {
    const { inputs } = params

    const ids = inputs.map((id) => +id)

    const currentSection = sections[index]
    const sectionInputs = ids.map((id, i) => {
      return {
        input_id: id,
        sort: i,
      }
    })

    const structure = [...sections]
    structure.splice(index, 1, { ...currentSection, inputs: [...sectionInputs] })

    try {
      const calculatedData = await onStepStructureUpdateInputs({ sections: structure })

      structureUpdateInputs(index, ids, calculatedData)
    } catch (error: any) {}

    onClose()
  }

  const inputsIDs = useMemo(() => {
    const ids: number[] = []

    if (section) {
      const { inputs } = section

      inputs.forEach(({ input_id }) => {
        ids.push(input_id)
      })
    }

    return ids
  }, [section])

  return (
    <>
      <SectionInputsUpdate
        onClose={onClose}
        onSave={onSave}
        inputsValue={inputsIDs}
        inputsData={inputsOptions}
      />
    </>
  )
}
