import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Title } from '@mantine/core'
import { DataShower } from '@/components/Elements'
import { GeneralPageLayoutShell } from '@/components/Layouts/Shells/GeneralPageLayoutShell'
import { statisticsDashboardEntries } from '@/features/statistics/store'
import { DashboardStatisticEntry } from '@/features/statistics/types'
import { RootState } from '@/store'
import { StatisticsContent } from './StatisticsContent'

interface IProps {
  data: {
    items: DashboardStatisticEntry[]
  } | null
  loading: boolean
  error: any
}

const StatisticsContainerComponent = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    fetchEntries()

    return () => {
      dispatch(statisticsDashboardEntries.cleanState())
    }
  }, [])

  const fetchEntries = () => {
    const params = {}

    dispatch(statisticsDashboardEntries.getData({ params }))
  }

  return (
    <GeneralPageLayoutShell>
      <Title order={3} mb={'md'}>
        {t('dashboard')}
      </Title>

      <Title order={5} mb={'md'}>
        {t('monthly_statistics')}
      </Title>

      <DataShower
        isFetched={!!props.data}
        isLoading={props.loading}
        isFailed={!!props.error}
        error={props.error}
      >
        <StatisticsContent items={props.data?.items || []} />
      </DataShower>
    </GeneralPageLayoutShell>
  )
}

const mapStateToProps = (state: RootState) => {
  const { data, loading, error } = state.statistics.dashboard.entries
  return {
    data,
    loading,
    error,
  }
}

export const StatisticsContainer = connect(mapStateToProps)(StatisticsContainerComponent)
