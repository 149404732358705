import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Group, Popover, Text } from '@mantine/core'
import { GeneralNumericFormat, Icon } from '@/components/Elements'

interface IProps {
  value: number
  unit?: string | null
  fixedDecimalScale?: boolean
}

export const OriginalInfo = memo(({ value, unit, fixedDecimalScale }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Popover position={'right'} withArrow>
        <Popover.Target>
          <ActionIcon color={'gray'} variant={'transparent'} size={16}>
            <Icon name={'info-circle'} size={14} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Group noWrap spacing={4}>
            <Text c="dimmed">{t('original_forecast')}</Text>
            <div>
              {unit && `${unit} `}
              <GeneralNumericFormat value={value} fixedDecimalScale={fixedDecimalScale} />
            </div>
          </Group>
        </Popover.Dropdown>
      </Popover>
    </>
  )
})
