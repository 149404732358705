import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { BasicLayout } from '@/components/Layouts'
import { AuthContentLayout } from '@/features/auth/components/Layouts'
import useStyles from './AuthLayout.styles'

export const AuthLayout = () => {
  const { classes } = useStyles()

  return (
    <BasicLayout>
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <AuthContentLayout>
            <Outlet />
          </AuthContentLayout>
        </div>
      </div>
    </BasicLayout>
  )
}
