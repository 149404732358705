import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SelectController } from '@/components/FormElements'
import { useInputStaticOptions, useInputsFetch } from '@/features/input/hooks'
import { BaseInputType } from '@/features/input/types'
import { SymbolSection } from '../../SymbolSection'

export const InputsSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const { getActions, isConstantType } = useInputStaticOptions()

  const { data, onFetch } = useInputsFetch()

  const inputs: BaseInputType[] = data?.data || []

  useEffect(() => {
    onFetch(
      {
        pagination: false,
        sort: [{ id: 'created_at', desc: true }],
      },
      {
        short: true,
      }
    )
  }, [])

  const watchInputA = watch('input_a_id')
  const watchInputB = watch('input_b_id')

  const isInputConstant = useCallback(
    (value: string | null) => {
      if (value) {
        const inputData: BaseInputType | undefined = _.find(inputs, { id: +value })
        if (inputData) {
          return isConstantType(inputData.type)
        }
      }
      return false
    },
    [isConstantType]
  )

  const getOptions = useCallback(
    (value: string | null) => {
      const isConstantValue = isInputConstant(value)

      return inputs.map(({ id, name, type }) => ({
        label: name,
        value: id.toString(),
        disabled: isConstantValue && isConstantType(type),
      }))
    },
    [inputs, isConstantType]
  )

  const renderInputField = (name: string, data: any[]) => (
    <Grid.Col xs={5}>
      <SelectController
        control={control}
        name={name}
        id={name}
        label={t('metric')}
        placeholder={t('metric')}
        mb={'md'}
        data={data}
        clearable
        searchable
      />
    </Grid.Col>
  )

  return (
    <>
      <Grid>
        {renderInputField('input_a_id', getOptions(watchInputB))}

        <Grid.Col xs={2}>
          <SelectController
            control={control}
            name={'action'}
            id={'action'}
            label={t('action')}
            placeholder={t('action')}
            mb={'md'}
            data={getActions()}
            clearable
          />
        </Grid.Col>

        {renderInputField('input_b_id', getOptions(watchInputA))}
      </Grid>

      {!!watchInputB && <SymbolSection />}
    </>
  )
}
