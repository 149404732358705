import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SignUpCTAForm } from '@/features/landing/components/Forms'

export const SignUpCTA = () => {
  const navigate = useNavigate()

  const onSubmit = async (data: any) => {
    const { email } = data

    if (email) {
      navigate(`/register?email=${email}`)
    }
  }

  return <SignUpCTAForm onSubmit={onSubmit} />
}
