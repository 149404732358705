import _ from 'lodash'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { InputModel } from '@/features/input/types'
import { TableUtils } from '@/utils'
import useStyles from './Table.styles'

interface IProps {
  components: {
    InputCell: FC<any>
    SectionCell: FC<any>
    GoalsCell: FC<any>
    ActualCell: FC<any>
    DateHeader: FC<any>
  }
  getInputDataById: (id: number) => InputModel | undefined
}

export const useForecastColumns = ({
  components: { InputCell, SectionCell, GoalsCell, ActualCell, DateHeader },
  getInputDataById,
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const getSectionColumnConfig = useCallback(
    () => ({
      id: 'section',
      header: null,
      isPlaceholder: true,
      cell: ({ row }: any) => {
        const { depth } = row

        if (depth) {
          return <InputCell data={row.original} />
        } else {
          return <SectionCell row={row} />
        }
      },
      __headerProps: {
        className: classes.inputHeader,
      },
    }),
    [getInputDataById]
  )

  const getDateColumnConfig = useCallback(
    (date: string, dateIndex: number) => ({
      id: `${date}-date`,
      header: <DateHeader value={date} />,
      columns: [
        {
          id: `${date}-date-goals`,
          cell: ({ row, table }: any) => {
            const { depth } = row

            if (depth) {
              const parentRow = TableUtils.getParentRow(row, table)

              const cells = row.original?.cells ?? []
              const cell = _.find(cells, { date })

              return (
                <GoalsCell
                  sectionIndex={parentRow.index}
                  inputIndex={row.index}
                  cellIndex={dateIndex}
                  data={cell}
                  getInputDataById={getInputDataById}
                  input={row.original}
                />
              )
            } else {
              return null
            }
          },
          header: t('goals'),
          __headerProps: {
            className: `${classes.entryHeader} ${classes.highlightBg}`,
          },
          __cellProps: {
            className: classes.highlightBg,
          },
        },
        {
          id: `${date}-date-actual`,
          cell: ({ row }: any) => {
            const { depth } = row

            if (depth) {
              const cells = row.original?.cells ?? []
              const cell = _.find(cells, { date })

              return (
                <ActualCell data={cell} getInputDataById={getInputDataById} input={row.original} />
              )
            } else {
              return null
            }
          },
          header: t('actual'),
          __headerProps: {
            className: classes.entryHeader,
          },
        },
      ],
    }),
    [getInputDataById]
  )

  const getColumns = useCallback((dates: string[]) => {
    const columns = []

    columns.push(getSectionColumnConfig())

    dates.forEach((item, i) => {
      columns.push(getDateColumnConfig(item, i))
    })

    return columns
  }, [])

  return {
    getColumns,
  }
}
