import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { RadioGroupController, TextInputController } from '@/components/FormElements'
import {
  CUSTOM_INPUT_MAX_VALUE,
  CUSTOM_INPUT_MIN_VALUE,
  INPUT_TYPE,
} from '@/features/input/consts/input'
import { useInputModel, useInputStaticOptions } from '@/features/input/hooks'
import { NormalizeUtils } from '@/utils'
import { SymbolSection } from '../SymbolSection'

export const ConstantInputSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const { isCustomPercentageInput } = useInputModel()

  const watchSymbol = watch('symbol')

  const isPercentageFormat = useMemo(() => {
    return isCustomPercentageInput({ type: INPUT_TYPE.CUSTOM, symbol: watchSymbol })
  }, [watchSymbol])

  const { getPeriods } = useInputStaticOptions()

  return (
    <>
      <Grid>
        <Grid.Col xs={4}>
          <RadioGroupController
            control={control}
            name={'period'}
            id={'period'}
            label={t('period')}
            options={getPeriods()}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col xs={4}>
          <TextInputController
            control={control}
            name={'value'}
            id={'value'}
            label={t('value')}
            placeholder={t('value')}
            mb={'md'}
            normalize={(value) =>
              isPercentageFormat
                ? NormalizeUtils.onlyNumbers(value)
                : NormalizeUtils.onlyDecimal(value, 2)
            }
            translateParams={{
              min: CUSTOM_INPUT_MIN_VALUE,
              max: CUSTOM_INPUT_MAX_VALUE,
            }}
          />
        </Grid.Col>
      </Grid>

      <SymbolSection />
    </>
  )
}
