import { createSelector } from '@reduxjs/toolkit'
import { InputModel } from '@/features/input/types'
import { RootState } from '@/store'

const getInputsList = (state: RootState) => state.tracker.inputs.list.list

export const selectTrackerInputsListActiveTrackerInputsData = createSelector(
  [getInputsList],
  (list) => {
    const items: InputModel[] = []
    const ids: number[] = []
    const aliases: string[] = []

    list.forEach((item) => {
      if (item?.active_tracker_input) {
        items.push(item)
        ids.push(item?.id)
        aliases.push(item?.alias)
      }
    })

    return {
      items,
      ids,
      aliases,
    }
  }
)
