const numberToPercentage = (value: number | string) => {
  value = +value * 100
  return Math.round((value + Number.EPSILON) * 100) / 100
}

const percentageToNumber = (value: number | string) => {
  value = +value / 100
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export const number = {
  numberToPercentage,
  percentageToNumber,
}
