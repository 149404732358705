import { createSlice } from '@reduxjs/toolkit'
import { ForecastSectionModel } from '@/features/forecast-section/types'

interface IState {
  list: ForecastSectionModel[]
  loading: boolean
  error: any | null
  meta: any | null
  fetched: boolean
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  meta: null,
  fetched: false,
}

const trackerForecastSectionsListSlice = createSlice({
  name: 'trackerForecastSectionsList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
      state.fetched = false
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload.meta
      state.fetched = true
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateListItem(state, action) {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    updateTrackerFSActiveStatus(state, action) {
      const list = action.payload ?? []

      const activeIds = list.map((item: string | number) => +item)

      state.list = state.list.map((item) => {
        return {
          ...item,
          active_tracker_section: activeIds.includes(item.id),
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})

export const trackerForecastSectionsList = trackerForecastSectionsListSlice.actions

export default trackerForecastSectionsListSlice.reducer
