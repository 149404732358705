import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { forecastInputsAPI } from '@/features/forecast/api'
import { FormTypes } from '@/types'
import {
  FORECAST_FORECAST_INPUTS_FETCH_FAILED,
  FORECAST_FORECAST_INPUTS_FETCH_REQUEST,
  FORECAST_FORECAST_INPUTS_FETCH_SUCCESS,
} from './actionTypes'

type ArgsType = {
  params: any
}

export const forecastForecastInputsFetchPA = createPromiseAction(
  FORECAST_FORECAST_INPUTS_FETCH_REQUEST,
  FORECAST_FORECAST_INPUTS_FETCH_SUCCESS,
  FORECAST_FORECAST_INPUTS_FETCH_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* fetch(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(forecastInputsAPI.getForecastInputs, params)
    const { data } = response

    yield put(forecastForecastInputsFetchPA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* fetchSaga() {
  yield takeEvery(forecastForecastInputsFetchPA.request, fetch)
}
