import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { ChangesControls } from './ChangesControls'
import { HistoryControls } from './HistoryControls'
import { ImportControl } from './ImportControl'
import { SectionAddControl } from './SectionAddControl'

export const ActionBar = () => {
  const { t } = useTranslation()

  return (
    <Group spacing={'xl'}>
      <SectionAddControl />

      <HistoryControls />

      <ChangesControls />

      <ImportControl />
    </Group>
  )
}
