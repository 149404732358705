import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { integrationAPI } from '@/features/integration/api'
import { FormTypes } from '@/types'
import {
  INTEGRATION_SYNC_START_FAILED,
  INTEGRATION_SYNC_START_REQUEST,
  INTEGRATION_SYNC_START_SUCCESS,
} from './actionTypes'

export const integrationSyncStartPA = createPromiseAction(
  INTEGRATION_SYNC_START_REQUEST,
  INTEGRATION_SYNC_START_SUCCESS,
  INTEGRATION_SYNC_START_FAILED
)<null, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* start(action: PromiseAction<string, any, any>) {
  try {
    const { id } = action.payload

    const response: AxiosResponse = yield call(integrationAPI.start, id)
    const { data } = response

    yield put(integrationSyncStartPA.success({}))

    resolvePromiseAction(action, { ...data?.data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* startSaga() {
  yield takeEvery(integrationSyncStartPA.request, start)
}
