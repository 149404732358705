import React, { useEffect, useState } from 'react'
import { Grid, Text, Title, Transition } from '@mantine/core'
import useStyles from './Content.styles'

interface IProps {
  data: {
    title: string
    text: string
    renderMedia: () => React.ReactNode
  }
}

export const Content = ({ data: { title, text, renderMedia } }: IProps) => {
  const { classes, cx } = useStyles()

  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Transition mounted={mounted} transition={'fade'} duration={300} timingFunction="ease">
      {(styles) => (
        <div style={styles}>
          <Grid gutter={'xl'} grow>
            <Grid.Col sm={6}>
              <div className={classes.inner}>
                <div className={classes.aboutContent}>
                  <Title fz={28}>{title}</Title>

                  <Text component={'p'}>{text}</Text>
                </div>
              </div>
            </Grid.Col>
            <Grid.Col sm={6}>
              <div className={cx(classes.inner, classes.mediaBlock)}>{renderMedia()}</div>
            </Grid.Col>
          </Grid>
        </div>
      )}
    </Transition>
  )
}
