import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InputsPickerController } from '@/features/input/components/FormElements'
import { InputModel } from '@/features/input/types'

type InputsOption = InputModel & {
  disabled?: boolean
}

interface IProps {
  inputsData?: InputsOption[]
}

export const InputsSection = ({ inputsData = [] }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const options = useMemo(() => {
    return inputsData?.map(({ id, name, disabled }) => ({
      label: name,
      value: id.toString(),
      name,
      disabled,
    }))
  }, [])

  return (
    <>
      <InputsPickerController control={control} name={'inputs'} options={options} />
    </>
  )
}
