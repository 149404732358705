import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { IntegrationSystemModel } from '@/features/integration/types'
import { AccountSetupContainer } from './AccountSetupContainer'

interface IProps {
  visible: boolean
  onClose: () => void
  onSuccess: (data: IntegrationSystemModel) => void
}

export const AccountSetup = ({ visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} title={t('setup_account')}>
        <AccountSetupContainer onClose={onClose} onSuccess={onSuccess} />
      </Modal>
    </>
  )
}
