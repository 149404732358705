import moment from 'moment'
import { DateUtils } from '@/utils'

const isSamePeriodLength = (firstDateRange, secondDateRange) => {
  const startDate = moment(firstDateRange[0])
  const endDate = moment(firstDateRange[1])
  const secondStart = moment(secondDateRange[0])
  const secondEnd = moment(secondDateRange[1])

  return endDate.diff(startDate, 'days') === secondEnd.diff(secondStart, 'days')
}

const format = (date, format) => {
  if (format) {
    return moment(date).format(format)
  }
  return date
}

const formatDateToServerFormat = (date) => {
  return date ? format(date, 'YYYY-MM-DD') : null
}

const formatDateToServerDateTimeFormat = (date) => {
  return date ? format(date, 'YYYY-MM-DD HH:mm') : null
}

const formatDate = (date) => {
  return date ? format(date, 'MM/DD/YYYY') : null
}

const formatDateTime = (date) => {
  return date ? format(date, 'DD/MM/YYYY HH:mm') : null
}

const localeToUTC = (date, format = null) => {
  const value = moment(date).utc()

  if (format) {
    return value.format(format)
  }

  return value.toDate()
}

const utcToLocale = (date, format = null) => {
  const value = moment.utc(date).local()

  if (format) {
    return value.format(format)
  }

  return value.toDate()
}

const timeToDate = (time, format = 'HH:mm') => {
  return moment(time, format).toDate()
}

const dateToTime = (date, format = 'HH:mm') => {
  return moment(date).format(format)
}

const utcTimeToLocaleDate = (time, format = 'HH:mm') => {
  return moment.utc(time, format).local().toDate()
}

const localeToUtcTime = (date, format = 'HH:mm') => {
  return localeToUTC(date, format)
}

const yearToDate = (year, format = 'YYYYY') => {
  return moment(year, format).toDate()
}

const toDate = (date) => {
  return moment(date).toDate()
}

const transformDateAndTimeDateToDate = (date, time) => {
  const hour = moment(time).get('hour')
  const minute = moment(time).get('minute')
  const second = moment(time).get('second')

  return moment(date).set('hour', hour).set('minute', minute).set('second', second).toDate()
}

const separateDateTimeToDateAndTimeDate = (date) => {
  const dateValue = date ? moment(date).startOf('day').toDate() : null
  const timeValue = date ? DateUtils.transformDateAndTimeDateToDate(new Date(), date) : null

  return {
    date: dateValue,
    time: timeValue,
  }
}

const monthYearToDate = (value, format = 'YYYY-MM') => {
  return moment(value, format).toDate()
}

const formatServerMonthYear = (value) => {
  if (value) {
    const date = monthYearToDate(value)

    return format(date, 'MMMM YYYY')
  }

  return null
}

const formatDateToServerMonthYearFormat = (date) => {
  return date ? format(date, 'YYYY-MM') : null
}

export {
  format,
  formatDateToServerFormat,
  formatDate,
  isSamePeriodLength,
  localeToUTC,
  utcToLocale,
  timeToDate,
  dateToTime,
  utcTimeToLocaleDate,
  localeToUtcTime,
  yearToDate,
  toDate,
  formatDateToServerDateTimeFormat,
  transformDateAndTimeDateToDate,
  formatDateTime,
  separateDateTimeToDateAndTimeDate,
  formatServerMonthYear,
  monthYearToDate,
  formatDateToServerMonthYearFormat,
}
