import _ from 'lodash'
import { INPUT_ACTION } from '@/features/input/consts/input'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: INPUT_ACTION
}

export const getActions = () => {
  return [
    {
      label: '×',
      value: INPUT_ACTION.MULTIPLICATION,
      name: i18n.t('input.action.multiplication'),
    },
    {
      label: '/',
      value: INPUT_ACTION.DIVISION,
      name: i18n.t('input.action.division'),
    },
    {
      label: '+',
      value: INPUT_ACTION.ADDITION,
      name: i18n.t('input.action.addition'),
    },
    {
      label: '−',
      value: INPUT_ACTION.SUBTRACTION,
      name: i18n.t('input.action.subtraction'),
    },
  ]
}

export const getAction = (value: INPUT_ACTION | string): OptionType | any => {
  const options = getActions()
  return _.find(options, { value })
}
