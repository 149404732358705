import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  const dark = theme.colorScheme === 'dark'

  // const color = theme.primaryColor
  const variant = 'light'

  const color = theme.colors[theme.primaryColor][theme.fn.primaryShade()]

  // const colors = theme.fn.variant({ variant, color })

  return {
    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: dark ? theme.colors.dark[0] : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,

      '&:hover': {
        color: color,

        // colored svg path with fill
        [`&:not(.link_icon_stroke) .${icon} > path`]: {
          fill: color,
        },
        // colored svg path with stroke
        [`&.link_icon_stroke .${icon} path`]: {
          stroke: color,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color: dark ? theme.colors.dark[0] : theme.colors.gray[7],
    },

    rightSection: {},

    linkActive: {
      '&, &:hover': {
        // backgroundColor: colors.background,
        color: color,

        [`&:not(.link_icon_stroke) .${icon} > path`]: {
          color: color,
        },
      },
    },
  }
})
