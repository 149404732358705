import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Card } from '@/components/Elements'
import { useIntegrationSystem } from '@/features/integration/hooks'
import { IntegrationSystem } from '@/features/integration/types'
import { BottomBar } from './BottomBar'
import { Header } from './Header'
import { InfoBlock } from './InfoBlock'
import { TopBar } from './TopBar'

interface IProps {
  data: IntegrationSystem
  onAccountAdd: () => void
  addAccountControlProps?: any
  onSync: () => void
  syncControlProps?: any
  removeAccountControlProps?: any
  systemExtraData?: { label: any; value: any }[]
}

export const GeneralIntegrationSystemCard = ({
  data,
  onAccountAdd,
  addAccountControlProps,
  onSync,
  syncControlProps,
  removeAccountControlProps,
  systemExtraData,
}: IProps) => {
  const { t } = useTranslation()

  const { data: modelData } = data

  const { isSetupStatus, isSyncStartVisible } = useIntegrationSystem()

  const addAccountAllow = !modelData || isSetupStatus(modelData?.status)

  const syncAllow = !!modelData && isSyncStartVisible(modelData?.status)

  return (
    <Card>
      <Header avatar={data.avatar} />

      <TopBar
        data={modelData}
        config={{ addAccount: addAccountAllow, sync: syncAllow }}
        onAccountAdd={onAccountAdd}
        addAccountControlProps={addAccountControlProps}
        onSync={onSync}
        syncControlProps={syncControlProps}
      />

      {modelData && (
        <>
          {!isSetupStatus(modelData?.status) && (
            <InfoBlock data={modelData} extraData={systemExtraData} />
          )}

          <Box mt={'xl'}>
            <BottomBar data={modelData} removeAccountControlProps={removeAccountControlProps} />
          </Box>
        </>
      )}
    </Card>
  )
}
