import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Group } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { useForecastContext } from '@/features/forecast/contexts'

export const HistoryControls = () => {
  const { t } = useTranslation()

  const {
    actions: { canUndo, canRedo, onSessionStepUndo, onSessionStepRedo },
    session: {
      state: {
        step: { undoing, redoing },
      },
    },
  } = useForecastContext()

  const disabled = undoing || redoing

  return (
    <Group spacing={'xs'}>
      <ActionIcon
        size={'lg'}
        loading={undoing}
        disabled={!canUndo || disabled}
        onClick={onSessionStepUndo}
        color={'primary'}
        variant={'transparent'}
      >
        <Icon name={'arrow-return-left'} />
      </ActionIcon>

      <ActionIcon
        size={'lg'}
        loading={redoing}
        disabled={!canRedo || disabled}
        onClick={onSessionStepRedo}
        color={'primary'}
        variant={'transparent'}
      >
        <Icon name={'arrow-return-right'} />
      </ActionIcon>
    </Group>
  )
}
