import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { SectionAddContainer } from './SectionAddContainer'

interface IProps {
  visible: boolean
  onClose: () => void
  onSave: (data: any) => void
}

export const SectionAdd = ({ visible, onClose, onSave }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal centered size={'sm'} opened={visible} onClose={onClose} title={t('add_section')}>
        <SectionAddContainer onClose={onClose} onSave={onSave} />
      </Modal>
    </>
  )
}
