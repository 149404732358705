import { all } from 'redux-saga/effects'
import { entitySaga } from './entity/sagas'
import { facebookSaga } from './facebook/sagas'
import { googleSaga } from './google/sagas'
import { integrationSystemsSaga } from './integration-systems/sagas'
import { klaviyoSaga } from './klaviyo/sagas'
import { removeSaga } from './remove/saga'
import { shopifySaga } from './shopify/sagas'
import { syncSaga } from './sync/sagas'

export function* integrationSaga() {
  yield all([
    integrationSystemsSaga(),
    removeSaga(),
    facebookSaga(),
    syncSaga(),
    entitySaga(),
    klaviyoSaga(),
    shopifySaga(),
    googleSaga(),
  ])
}
