import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const primaryColors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })
  const secondaryColors = theme.fn.variant({
    variant: 'light',
    color: 'secondary',
  })

  return {
    primary: {
      backgroundColor: primaryColors.background,
    },
    secondary: {
      backgroundColor: secondaryColors.background,
    },
  }
})
