import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { SelectController } from '@/components/FormElements'
import { googleIntegrationAPI } from '@/features/integration/api'
import {
  GoogleIntegrationClientAccount,
  GoogleIntegrationManagerAccount,
} from '@/features/integration/types'
import { useFetch, useFormSubmit } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: any
}

export const AccountForm = ({ onCancel, initialValues = {}, ...props }: IProps) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      manager_id: null,
      client_id: null,
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
    setValue,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const watchManagerId = watch('manager_id')

  const managerAccountsFetch = useFetch()

  const managerAccountsData = useMemo(() => {
    if (managerAccountsFetch?.data?.data?.items) {
      const items: GoogleIntegrationManagerAccount[] = managerAccountsFetch?.data?.data?.items
      return items.map(({ id }) => ({
        label: `${id}`,
        value: id.toString(),
      }))
    }

    return []
  }, [managerAccountsFetch?.data?.data])

  useEffect(() => {
    managerAccountsFetch.fetch(googleIntegrationAPI.getManagerAccounts)
  }, [])

  const clientAccountsFetch = useFetch()

  const clientAccountsData = useMemo(() => {
    if (clientAccountsFetch?.data?.data?.items) {
      const items: GoogleIntegrationClientAccount[] = clientAccountsFetch?.data?.data?.items
      return items.map(({ id, name }) => ({
        label: `${name} - ${id}`,
        value: id,
      }))
    }

    return []
  }, [clientAccountsFetch?.data?.data])

  useEffect(() => {
    if (watchManagerId) {
      setValue('client_id', null)

      clientAccountsFetch.resetState()

      clientAccountsFetch.fetch(googleIntegrationAPI.getClientAccounts, {
        manager_id: watchManagerId,
      })
    }
  }, [watchManagerId])

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectController
            control={control}
            name={'manager_id'}
            id={'manager_id'}
            label={t('manager_account')}
            placeholder={t('manager_account')}
            mb={'md'}
            data={managerAccountsData}
          />

          {watchManagerId && (
            <SelectController
              control={control}
              name={'client_id'}
              id={'client_id'}
              label={t('client_account')}
              placeholder={t('client_account')}
              mb={'md'}
              data={clientAccountsData}
            />
          )}

          <div className={'mt-2'}>
            <Group position={'right'}>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
