import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {
      position: 'relative',
      width: '100%',
    },

    img1: {
      maxWidth: '100%',
    },
  }
})
