import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {
      position: 'relative',
      zIndex: 1,
      paddingTop: 40,
      paddingBottom: 40,
    },
  }
})
