import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@mantine/core'
import { Button, ButtonLink, Icon } from '@/components/Elements'

interface IProps extends ButtonProps {
  children?: React.ReactNode
  mode?: 'button' | 'link'
  to?: string

  [x: string]: any
}

export const CTAButton = ({ children, to = '', ...props }: IProps) => {
  const { t } = useTranslation()

  const renderContent = () => <>{children || t('get_started')}</>

  const renderRightSection = () => props.rightIcon || <Icon size={30} name={'arrow-right-short'} />

  if (props.mode === 'link') {
    return (
      <ButtonLink rightIcon={renderRightSection()} to={to} {...props}>
        {renderContent()}
      </ButtonLink>
    )
  }

  return (
    <Button rightIcon={renderRightSection()} {...props}>
      {renderContent()}
    </Button>
  )
}
