import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { forecastAPI } from '@/features/forecast/api'
import i18n from '@/i18n'
import { forecastData } from './slice'

function* getData({ payload: { params } }: PayloadAction<{ params: any }>) {
  try {
    const response: AxiosResponse = yield call(forecastAPI.getForecast, params)

    const {
      data: { data, meta },
    } = response

    yield put(forecastData.getDataSuccess({ data: { sections: data }, meta }))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(forecastData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeLatest(forecastData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
