import React from 'react'
import logo from '@/assets/images/logo/logo-v2.png'

type SizeType = 'xs' | 'sm'

type AttrsType = {
  height?: string
}

interface IProps {
  size?: SizeType | undefined
}

export const LogoV2 = ({ size, ...props }: IProps) => {
  let attrs: AttrsType = {}

  if (size === 'xs') {
    attrs = {
      ...attrs,
      height: '22px',
    }
  }

  return <img src={logo} alt={'logo'} {...attrs} {...props} />
}
