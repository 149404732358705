import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Space, Text, Title, TypographyStylesProvider } from '@mantine/core'
import { Anchor, GeneralCard } from '@/components/Elements'
import { APP_NAME } from '@/const/core'
import { useFromPath } from '@/hooks'

export const PrivacyPolicyContainer = () => {
  const { t } = useTranslation()
  const from = useFromPath()

  const items = [
    {
      title: 'Introduction',
      content:
        'At Spark Forecast, we prioritize your privacy and are committed to protecting your personal and business data. This Privacy Policy outlines our practices concerning the collection, use, and sharing of your information. By using our SaaS web app, you agree to the terms of this Privacy Policy.',
    },
    {
      title: 'Data Collection and Usage',
      content: (
        <>
          <ul>
            <li>
              Data We Collect
              <ul>
                <li>
                  Automated Data Collection: When you visit our website or use our platform, we may
                  use cookies and similar technologies to collect data about your interactions to
                  improve our services and for remarketing purposes.
                </li>
                <li>
                  Data from API Integrations: We collect metrics, spend, and other related data from
                  the integrations you connect to our platform, such as Facebook, Google Ads,
                  Shopify, and Klaviyo. This data is used strictly for the functionality of the tool
                  and is not shared with any third parties.
                </li>
                <li>
                  Contact Information: If you contact us directly or sign up for our newsletter, we
                  may collect your name, email, phone number, and any other information you provide.
                </li>
              </ul>
            </li>
            <li>
              How We Use Your Data
              <ul>
                <li>
                  Service Provision: Offering sales, ad spend forecasts using historical data, daily
                  pacing towards goals, and custom metrics.
                </li>
                <li>
                  Improvement and Customization: Using cookies and similar technologies to
                  understand how users interact with our services to enhance and personalize the
                  user experience.
                </li>
                <li>
                  Communication: Sending updates, news, or marketing materials to users who have
                  opted in.
                </li>
                <li>
                  Remarketing: To retarget visitors with advertising campaigns relevant to their
                  interests based on their website behavior.
                </li>
              </ul>
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'Data Sharing and Disclosure',
      content: (
        <>
          <ul>
            <li>API Data: We do not share any data we receive from third-party integrations.</li>
            <li>
              Cookies Data: Data collected through cookies might be shared with third-party
              advertising networks for remarketing purposes.
            </li>
            <li>
              Legal and Safety Reasons: We might share data to comply with legal obligations,
              protect our rights, prevent wrongdoing, or ensure the safety of our users and the
              public.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'Data Retention',
      content:
        'We store your data as long as your account is active or as needed to provide our services. If you wish to delete or deactivate your account, please contact our support.',
    },
    {
      title: 'User Access and Control',
      content:
        'If you would like to access, correct, or delete your data stored with us, or opt-out of any remarketing efforts, please send a request to our support team.',
    },
    {
      title: 'Security',
      content:
        'We use industry-standard measures to protect the data we collect. However, while we strive for maximum protection, we cannot guarantee its absolute security.',
    },
    {
      title: 'Third-party Services',
      content:
        'We integrate with third-party services, but do not control their actions or privacy policies. Review their privacy policies for concerns.',
    },
    {
      title: 'Changes to the Privacy Policy',
      content: 'Any changes will be posted here. Review periodically for updates.',
    },
    {
      title: 'Contact Us',
      content: (
        <>
          <Text mb={'md'}>For questions or inquiries, please contact:</Text>
          <Text>
            Spark Forecast
            <br />
            701 Tillery St 12
            <br />
            Austin, TX 78702
            <br />
            <br />
            support@sparkforecast.com
          </Text>
        </>
      ),
    },
  ]

  return (
    <GeneralCard title={'Privacy Policy for Spark Forecast'} titleProps={{ order: 2, mb: 0 }}>
      <Text mb={'md'}>Last updated: August 9, 2023</Text>

      <div className={'flex'}>
        <Title order={6}>
          {t('back_to')}{' '}
          <Anchor component={Link} to={from}>
            {APP_NAME}
          </Anchor>
        </Title>
      </div>

      <Space h={'lg'} />

      <TypographyStylesProvider>
        {items.map(({ title, content }, i) => (
          <Box mt={i !== 0 ? 'xl' : 0} mb={'xl'} key={i}>
            <Text mb={'sm'}>
              {`${i + 1}.`} {title}
            </Text>

            {content}
          </Box>
        ))}
      </TypographyStylesProvider>
    </GeneralCard>
  )
}
