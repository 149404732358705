import moment from 'moment'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { useDailyTrackerStaticOptions } from '@/features/tracker/hooks'
import { SectionsDailyTrackerModel } from '@/features/tracker/types'
import { DateUtils } from '@/utils'

interface IProps {
  data: SectionsDailyTrackerModel
}

export const DateCell = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { t: type, d: date } = data

  const { isDateType, getType } = useDailyTrackerStaticOptions()

  const isToday = useCallback((value: string | null) => {
    return value && moment(value).isSame(moment(), 'day')
  }, [])

  return (
    <>
      {isDateType(type) ? (
        <Text fz={isToday(date) ? 'md' : 'sm'} className={'whitespace-nowrap'}>
          {DateUtils.format(date, 'MMMM-DD-YYYY')}
        </Text>
      ) : (
        <Text tt={'uppercase'}>{getType(type)?.label}</Text>
      )}
    </>
  )
}
