import { ExpandedState } from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UseTableProps, UseTableReturn } from '../types'

export const useTable = ({ ...props }: UseTableProps): UseTableReturn => {
  const { t } = useTranslation()

  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  return {
    expanded,
    onExpandedChange: setExpanded,
  }
}
