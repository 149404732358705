import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox as MantineCheckbox,
  CheckboxGroupProps as MantineCheckboxGroupProps,
} from '@mantine/core'
import { FormUtils } from '@/utils'

type Option = {
  label?: React.ReactNode
  value: string
  disabled?: boolean
}

export interface CheckboxGroupProps extends Omit<MantineCheckboxGroupProps, 'children'> {
  options: Option[]
  fieldRef?: any
  translateParams?: object
  meta?: any
}

export const CheckboxGroup = ({
  options,
  meta = {},
  fieldRef,
  translateParams,
  ...props
}: CheckboxGroupProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  // console.log(options)

  return (
    <MantineCheckbox.Group {...props} error={showError ? getErrorMessage() : null}>
      {options.map(({ label, value, disabled = false }) => (
        <MantineCheckbox key={value} value={value} label={label} disabled={disabled} />
      ))}
    </MantineCheckbox.Group>
  )
}
