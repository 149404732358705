import axios from 'axios'

class ForecastSectionsAPI {
  getForecastSections = async (params: any = {}) => {
    return axios.get(`api/forecast-sections`, {
      params,
    })
  }
}

export const forecastSectionsAPI = new ForecastSectionsAPI()
