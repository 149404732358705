import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Text } from '@mantine/core'
import { Button, Modal } from '@/components/Elements'

interface IProps {
  visible: boolean
  children?: React.ReactNode
  message?: string | null
  confirmText?: React.ReactNode
  onConfirm: () => void
  onCancel?: () => void
  onClose: () => void
  modalProps?: any
}

export const ConfirmModal = ({
  visible,
  children,
  message,
  confirmText,
  onConfirm,
  onCancel,
  onClose,
  modalProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  message = message || t('please_confirm')

  const renderMessage = () => <Text weight={600}>{message}</Text>

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    } else {
      onClose && onClose()
    }
  }

  return (
    <>
      <Modal opened={visible} onClose={onClose} centered title={t('confirmation')} {...modalProps}>
        <div>
          {children ? children : renderMessage()}

          <div className={'mt-4 w-full'}>
            <Group position={'right'}>
              <Button variant="outline" color="dark" onClick={handleCancel}>
                {t('cancel')}
              </Button>

              <Button onClick={onConfirm}>{confirmText ? confirmText : t('confirm')}</Button>
            </Group>
          </div>
        </div>
      </Modal>
    </>
  )
}
