import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralIntegrationSystemCard } from '@/features/integration/components/IntegrationSystem/IntegrationSystems/Cards'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'
import {
  useIntegrationCreate,
  useIntegrationGoogleUrlFetch,
  useIntegrationSystem,
} from '@/features/integration/hooks'
import { IntegrationSystem, IntegrationSystemModel } from '@/features/integration/types'
import { usePopup, useWindowPopup } from '@/hooks'
import { AccountSetup } from './AccountSetup'

interface IProps {
  data: IntegrationSystem
  onAccountUpdate: (provider: INTEGRATION_SYSTEM_PROVIDER, data: IntegrationSystemModel) => void
  onAccountRemoveSuccess: (provider: INTEGRATION_SYSTEM_PROVIDER) => void
  onSync: (id?: number) => void
  syncState: {
    starting: boolean
    disabled: boolean
  }
}

export const GoogleAdsProvider = ({
  data,
  onAccountUpdate,
  onAccountRemoveSuccess,
  onSync,
  syncState,
}: IProps) => {
  const { t } = useTranslation()

  const modelData = data?.data

  const { visible, open, close } = usePopup()

  const { isSetupStatus, isSyncStartAllow } = useIntegrationSystem()

  const integrationCreate = useIntegrationCreate()

  const onPostMessage = async (event: MessageEvent<{ payload: any }>) => {
    if (event && event.data && event.data.payload) {
      const location = event.data.payload?.location
      const query = new URLSearchParams(location.search)

      const params: any = {}

      query.forEach((value, key) => {
        params[key] = value
      })

      closeWindowPopup && closeWindowPopup()

      try {
        const integration = await integrationCreate.onSubmit(data.provider, params)

        if (integration) {
          onAccountUpdate(data.provider, integration)
        }

        open()
      } catch (e) {}
    }
  }

  const {
    open: openWindow,
    close: closeWindowPopup,
    isOpen: isWindowOpen,
    focus: windowFocus,
  } = useWindowPopup({
    onPostMessage,
  })

  const onAdd = async () => {
    if (modelData && isSetupStatus(modelData?.status)) {
      open()
    } else {
      await onAccountAdd()
    }
  }

  const googleUrlFetch = useIntegrationGoogleUrlFetch()

  const onAccountAdd = async () => {
    if (isWindowOpen()) {
      windowFocus()
      return
    }

    const data: any = await googleUrlFetch.onFetch()

    if (data.url) {
      openWindow(data.url)
    }
  }

  const onSetupSuccess = (value: IntegrationSystemModel) => {
    close()

    onAccountUpdate(data?.provider, value)

    if (!syncState.disabled && isSyncStartAllow(value?.status)) {
      onSync(value?.id)
    }
  }

  const accountAddLoading = useMemo(() => {
    return googleUrlFetch.loading || integrationCreate.loading
  }, [googleUrlFetch.loading, integrationCreate.loading])

  return (
    <>
      <GeneralIntegrationSystemCard
        data={data}
        onAccountAdd={onAdd}
        addAccountControlProps={{
          loading: accountAddLoading,
          label: modelData?.status && isSetupStatus(modelData.status) ? t('setup') : null,
        }}
        onSync={onSync}
        syncControlProps={{ loading: syncState.starting, disabled: syncState.disabled }}
        removeAccountControlProps={{
          onSuccess: () => onAccountRemoveSuccess(data.provider),
        }}
        systemExtraData={[
          { label: t('manager_account'), value: modelData?.google?.manager_id || '-' },
          {
            label: t('client_account'),
            value: modelData?.google?.client_id || '-',
          },
        ]}
      />

      <AccountSetup visible={visible} onClose={close} onSuccess={onSetupSuccess} />
    </>
  )
}
