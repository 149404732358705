import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'

export const EmailVerificationContainer = () => {
  const { t } = useTranslation()

  return (
    <AuthCard title={t('email-verification-label')}>
      <Alert type={'success'} mb={'sm'}>
        {t('confirmation-email-sent')}.
      </Alert>
    </AuthCard>
  )
}
