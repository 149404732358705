import _ from 'lodash'
import { FORECAST_SESSION_ADJUST_STEP_TYPE } from '@/features/forecast-session/consts/adjust-step'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: FORECAST_SESSION_ADJUST_STEP_TYPE
}

export const getTypes = () => {
  return [
    {
      label: i18n.t('forecast-session.adjust-step.type.mom'),
      value: FORECAST_SESSION_ADJUST_STEP_TYPE.MOM,
    },
    {
      label: i18n.t('forecast-session.adjust-step.type.total'),
      value: FORECAST_SESSION_ADJUST_STEP_TYPE.TOTAL,
    },
  ]
}

export const getType = (value: FORECAST_SESSION_ADJUST_STEP_TYPE | string): OptionType | any => {
  const options = getTypes()
  return _.find(options, { value })
}

export const isMoM = (value: FORECAST_SESSION_ADJUST_STEP_TYPE | string): boolean => {
  return value === FORECAST_SESSION_ADJUST_STEP_TYPE.MOM
}
