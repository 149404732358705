import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AdjustStepForecastSessionStepDTO,
  DefaultForecastSessionDTO,
  StepForecastSessionStepDTO,
} from '@/features/forecast-session/api/dto'
import {
  forecastSessionAdjustStepSubmitPA,
  forecastSessionStepRedoPA,
  forecastSessionStepSubmitPA,
  forecastSessionStepUndoPA,
} from '@/features/forecast-session/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useForecastSessionDispatch = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const submit = useAsyncDispatch()

  const onSubmit = async (params: StepForecastSessionStepDTO) => {
    try {
      return await submit.onAsyncDispatch(forecastSessionStepSubmitPA.request, { params })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })

      throw error
    }
  }

  const undo = useAsyncDispatch()

  const onUndo = async (params: DefaultForecastSessionDTO) => {
    try {
      return await undo.onAsyncDispatch(forecastSessionStepUndoPA.request, { params })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })

      throw error
    }
  }

  const redo = useAsyncDispatch()

  const onRedo = async (params: DefaultForecastSessionDTO) => {
    try {
      return await redo.onAsyncDispatch(forecastSessionStepRedoPA.request, { params })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })

      throw error
    }
  }

  // === Adjust === //
  const adjustSubmit = useAsyncDispatch()

  const onAdjustSubmit = async (params: AdjustStepForecastSessionStepDTO) => {
    try {
      return await adjustSubmit.onAsyncDispatch(forecastSessionAdjustStepSubmitPA.request, {
        params,
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })

      throw error
    }
  }
  // === //

  return {
    onSubmit,
    onUndo,
    onRedo,
    submitting: submit.loading,
    undoing: undo.loading,
    redoing: redo.loading,
    onAdjustSubmit,
    adjustSubmitting: adjustSubmit.loading,
  }
}
