import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {
      maxWidth: 480,
      display: 'flex',
      justifyContent: 'center',

      [theme.fn.smallerThan('md')]: {
        textAlign: 'center',
        margin: `0 auto`,
      },
    },
  }
})
