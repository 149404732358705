import React from 'react'
import { AdjustStepForecastSessionStepDTO } from '@/features/forecast-session/api/dto'
import { StepCellUpdateType } from '@/features/forecast-session/types'
import { ForecastResetGoalsDTO } from '@/features/forecast/api/dto'
import { ForecastFormValue, ForecastSectionInputFormValue } from '@/features/forecast/types'
import { UseManagerProps, UseManagerReturn } from '../types'

export const useManager = ({
  forecastForm,
  store,
  dispatching,
  onWillInitFormChange,
  session,
  forecastDispatching,
  ...props
}: UseManagerProps): UseManagerReturn => {
  const {
    actions: { undo, redo, ...storeActions },
  } = store

  const withWillInitForm = (fn: any) => () => {
    onWillInitFormChange(true)
    fn()
  }

  const onFetch = () => {
    props.onFetch && props.onFetch()
  }

  const onSubmit = async () => {
    try {
      const data: any = await dispatching.onSubmit()

      // let sections = []
      //
      // if (data?.data?.sections) {
      //   sections = data?.data?.sections || []
      // } else {
      //   sections = store.state.data.sections
      // }
      //
      // storeActions.resetData({ sections })

      onFetch()
    } catch (error: any) {}
  }

  const setGoal = (
    value: string,
    params: { sectionIndex: number; inputIndex: number; date: string }
  ) => forecastForm.setGoal(value, params)

  const discardChanges = () => {
    storeActions.cleanStore()

    session.actions.onReset()
  }

  const sectionAdd = async (value: { name: string }) => {
    forecastForm.appendSection(value)

    // update session step
    await onStepStructureCreate()
  }

  const sectionUpdate = async (index: number, value: { name: string }) => {
    forecastForm.updateSection(index, value)

    // update session step
    await onStepStructureUpdate()
  }

  const sectionRemove = async (index: number) => {
    forecastForm.removeSection(index)

    // update session step
    await onStepStructureDelete()
  }

  const sectionInputsUpdate = (index: number, value: ForecastSectionInputFormValue[]) => {
    forecastForm.updateSectionInputs(index, value)
  }

  const structureUpdateInputs = (
    index: number,
    inputIds: number[],
    data: ForecastSectionInputFormValue[]
  ) => {
    forecastForm.structureUpdateInputs(index, inputIds, data)
  }

  const cellsUpdateGoals = (data: StepCellUpdateType[]) => {
    forecastForm.cellsUpdateGoals(data)
  }

  // Session //
  const onSave = async () => {
    await session.actions.onSave()

    onFetch()
  }

  const onSessionStepHandleError = (error: any) => {
    withWillInitForm(discardChanges)()

    throw error
  }

  const onStepCellUpdate = async (value: string, inputId: number, date: string) => {
    try {
      return await session.actions.onCellUpdate({ goal: value, input_id: inputId, date })
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }

  const onSessionStepUndo = async () => {
    try {
      await session.actions.onUndo()

      withWillInitForm(undo)()
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }

  const onSessionStepRedo = async () => {
    try {
      await session.actions.onRedo()

      withWillInitForm(redo)()
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }

  const onStepStructureCreate = async () => {
    try {
      const { sections } = forecastForm.form.getValues()

      await session.actions.onStructureCreate({ sections })
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }

  const onStepStructureDelete = async () => {
    try {
      const { sections } = forecastForm.form.getValues()

      await session.actions.onStructureDelete({ sections })
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }

  const onStepStructureUpdate = async () => {
    try {
      const { sections } = forecastForm.form.getValues()

      await session.actions.onStructureUpdate({ sections })
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }

  const onStepStructureUpdateInputs = async (data: ForecastFormValue) => {
    try {
      const { sections } = data

      return await session.actions.onStructureUpdateInputs({ sections })
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }

  const onAdjustStepSubmit = async (data: Omit<AdjustStepForecastSessionStepDTO, 'session_id'>) => {
    try {
      return await session.actions.onAdjustStepSubmit(data)
    } catch (error: any) {
      onSessionStepHandleError(error)
    }
  }
  // --- //

  const onGoalsResetSubmit = async (data?: ForecastResetGoalsDTO) => {
    await forecastDispatching.goalsReset.onGoalsReset(data)

    withWillInitForm(discardChanges)()

    onFetch()
  }

  const onSessionReset = async () => {
    withWillInitForm(discardChanges)()

    onFetch()
  }

  return {
    actions: {
      ...storeActions,
      undo: withWillInitForm(undo),
      redo: withWillInitForm(redo),
      onFetch,
      onSubmit,
      discardChanges: withWillInitForm(discardChanges),
      setGoal,
      sectionAdd,
      sectionUpdate,
      sectionRemove,
      sectionInputsUpdate,
      structureUpdateInputs,
      cellsUpdateGoals,
      onSave,
      onStepCellUpdate,
      onSessionStepUndo,
      onSessionStepRedo,
      onStepStructureCreate,
      onStepStructureDelete,
      onStepStructureUpdate,
      onStepStructureUpdateInputs,
      onAdjustStepSubmit,
      onGoalsResetSubmit,
      onSessionReset,
    },
  }
}
