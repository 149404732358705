import { combineReducers } from 'redux'
import app from '@/features/app/store/reducers'
import auth from '@/features/auth/store/reducers'
import forecast from '@/features/forecast/store/reducers'
import importReducer from '@/features/import/store/reducers'
import input from '@/features/input/store/reducers'
import integration from '@/features/integration/store/reducers'
import panel from '@/features/panel/store/reducers'
import staticReducer from '@/features/static/store/reducers'
import statistics from '@/features/statistics/store/reducers'
import tracker from '@/features/tracker/store/reducers'
import user from '@/features/user/store/reducers'

const rootReducer = combineReducers({
  app,
  auth,
  user,
  static: staticReducer,
  panel,
  input,
  tracker,
  forecast,
  integration,
  statistics,
  import: importReducer,
})

export default rootReducer
