import React from 'react'
import { Card } from '@/components/Elements'
import useStyles from './ContentShell.styles'

interface IProps {
  children: React.ReactNode
}

export const ContentShell = ({ children }: IProps) => {
  const { classes } = useStyles()

  return (
    <Card
      mt={'xl'}
      shadow={'xl'}
      py={{ base: 40, md: 60 }}
      px={{ base: 40, md: 60 }}
      className={classes.card}
    >
      {children}
    </Card>
  )
}
