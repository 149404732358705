import React from 'react'
import useStyles from './Media.styles'

interface IProps {
  img: any
}

export const Media = ({ img }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <img alt={'media'} className={classes.img1} src={img} />
    </div>
  )
}
