import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableView } from '@/components/Elements'
import { DateUtils } from '@/utils'
import { Action } from './Action'
import { Title } from './Title'
import { Type } from './Type'

type Key = 'title' | 'description' | 'type' | 'date' | 'action'

interface IProps {
  keys: Key[]
  items: any[]
  loading: boolean
  actionProps?: any
  editControlProps?: any
  removeControlProps?: any
}

export const GeneralInputsTable = ({
  keys = [],
  items,
  loading,
  actionProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const columnConfig: any = {
    title: {
      accessorKey: 'title',
      id: 'title',
      header: t('title'),
      cell: (info: any) => <Title input={info.row.original} />,
    },
    description: {
      accessorKey: 'description',
      id: 'description',
      header: t('description'),
      cell: (info: any) => info.getValue() || '-',
    },
    type: {
      accessorKey: 'type',
      id: 'type',
      header: t('type'),
      cell: (info: any) => <Type value={info.getValue()} />,
    },
    date: {
      accessorKey: 'created_at',
      id: 'created_at',
      header: t('created_date'),
      cell: (info: any) => (info.getValue() ? DateUtils.formatDate(info.getValue()) : '-'),
    },
    action: {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: (info: any) => (
        <div className={'flex justify-end'}>
          <Action
            {...actionProps}
            input={info.row.original}
            editControlProps={props.editControlProps}
            removeControlProps={props.removeControlProps}
          />
        </div>
      ),
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}
