import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputModel } from '@/features/input/types'

interface IProps {
  input: InputModel
}

export const Title = ({ input }: IProps) => {
  const { t } = useTranslation()

  const { name } = input

  return <>{name || '-'}</>
}
