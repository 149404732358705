import React from 'react'
import { useTranslation } from 'react-i18next'
import { inputsAPI } from '@/features/input/api'
import { useFetch } from '@/hooks'
import { RequestUtils } from '@/utils'

export const useInputsFetch = () => {
  const { t } = useTranslation()

  const { data, fetch, loading, error } = useFetch()

  const onFetch = (params: any, requestParams?: object) => {
    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    fetch(inputsAPI.getInputs, requestParams)
  }

  return {
    data,
    loading,
    error,
    onFetch,
  }
}
