import React, { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useDeepCompareEffect, useFirstMountState } from 'react-use'
import { useFilterForm } from '@/features/filter/hooks'
import { INPUT_TYPE } from '@/features/input/consts/input'
import { GENERAL_INPUTS_FILTERS_INITIAL_VALUES } from '@/features/input/consts/inputs'
import { GeneralFilterForm } from '../FilterForms/GeneralFilterForm'

interface IProps {
  values: {
    keywords?: string
    types?: INPUT_TYPE[]
  }
  onFiltersChange: (data: any) => void
}

export const GeneralInputsFilter = ({ values = {}, onFiltersChange }: IProps) => {
  const isFirstMount = useFirstMountState()

  const defaultValues = useMemo(() => {
    return {
      keywords: values?.keywords || '',
      types: values?.types || [],
    }
  }, [values])

  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      ...GENERAL_INPUTS_FILTERS_INITIAL_VALUES,
    },
  })

  const { reset } = methods

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      reset(defaultValues)
    }
  }, [defaultValues])

  return (
    <FormProvider {...methods}>
      <GeneralFilterForm />
    </FormProvider>
  )
}
