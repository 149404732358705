import React from 'react'
import { InputModel } from '@/features/input/types'
import { SectionInputsUpdateContainer } from './SectionInputsUpdateContainer'

interface IProps {
  onClose: () => void
  onSave: (params: { inputs: string[] }) => Promise<void>
  inputsData?: InputModel[]
  inputsValue?: number[]
}

export const SectionInputsUpdate = ({ onClose, onSave, inputsData, inputsValue }: IProps) => {
  return (
    <>
      <SectionInputsUpdateContainer
        onCancel={onClose}
        onSave={onSave}
        inputsData={inputsData}
        inputsValue={inputsValue}
      />
    </>
  )
}
