import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { Button, Icon } from '@/components/Elements'

interface IProps {
  onGoalsReset: () => void
}

export const GoalsResetControl = ({ onGoalsReset }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ConfirmationControl
        onConfirm={() => onGoalsReset()}
        render={({ onOpen }) => (
          <Button variant="outline" onClick={onOpen} leftIcon={<Icon name={'restore'} />}>
            {t('revert_to_original')}
          </Button>
        )}
        message={t('adjust-forecast.confirmation.revert.warning')}
      />
    </>
  )
}
