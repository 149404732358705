import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Button, Modal } from '@/components/Elements'
import { ImportEntryContainer } from './ImportEntryContainer'

interface IProps {
  visible: boolean
  onClose: () => void
  onSuccess?: () => void
  onImportComplete?: () => Promise<void>
}

export const ImportEntry = ({ visible, onClose, onSuccess, onImportComplete }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} title={t('upload_data')}>
        <ImportEntryContainer
          onClose={onClose}
          onSuccess={onSuccess}
          onImportComplete={onImportComplete}
        />

        <Group position={'right'} mt={'xl'}>
          <Button variant="outline" color="dark" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Group>
      </Modal>
    </>
  )
}
