import React from 'react'
import { useTranslation } from 'react-i18next'
import { ForecastResetGoalsDTO } from '@/features/forecast/api/dto'
import { forecastResetGoalsSubmitPA } from '@/features/forecast/store'
import { useAsyncDispatch, useNotify } from '@/hooks'
import { UseForecastDispatchReturn } from './types'

export const useForecastDispatch = (): UseForecastDispatchReturn => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const goalsReset = useAsyncDispatch()

  const onGoalsReset = async (params?: ForecastResetGoalsDTO) => {
    try {
      return await goalsReset.onAsyncDispatch(forecastResetGoalsSubmitPA.request, { params })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })

      throw error
    }
  }

  return {
    goalsReset: {
      onGoalsReset,
      goalsResetSubmitting: goalsReset.loading,
    },
  }
}
