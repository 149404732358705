import React from 'react'
import { useTranslation } from 'react-i18next'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'
import { useIntegrationSystemProviderSyncEffect } from '@/features/integration/hooks'
import { IntegrationSystem, IntegrationSystemModel } from '@/features/integration/types'
import { FacebookProvider } from './FacebookProvider'
import { GoogleAdsProvider } from './GoogleAdsProvider'
import { KlaviyoProvider } from './KlaviyoProvider'
import { ShopifyProvider } from './ShopifyProvider'

interface IProps {
  data: IntegrationSystem
  provider: INTEGRATION_SYSTEM_PROVIDER
  onAccountUpdate: (provider: INTEGRATION_SYSTEM_PROVIDER, data: IntegrationSystemModel) => void
  onAccountRemoveSuccess: (provide: INTEGRATION_SYSTEM_PROVIDER) => void
  syncCount?: number
  syncDisabled?: boolean
}

export const IntegrationSystemProvider = ({ syncDisabled = false, ...props }: IProps) => {
  const { t } = useTranslation()

  const { provider, syncCount, ...rest } = props

  const { starting, onSync } = useIntegrationSystemProviderSyncEffect({
    data: props.data,
    onIntegrationUpdate: props.onAccountUpdate,
    syncCount,
  })

  switch (provider) {
    case INTEGRATION_SYSTEM_PROVIDER.SHOPIFY:
      return (
        <ShopifyProvider
          onSync={onSync}
          syncState={{ starting, disabled: syncDisabled }}
          {...rest}
        />
      )

    case INTEGRATION_SYSTEM_PROVIDER.FACEBOOK:
      return (
        <FacebookProvider
          onSync={onSync}
          syncState={{ starting, disabled: syncDisabled }}
          {...rest}
        />
      )

    case INTEGRATION_SYSTEM_PROVIDER.KLAVIYO:
      return (
        <KlaviyoProvider
          onSync={onSync}
          syncState={{ starting, disabled: syncDisabled }}
          {...rest}
        />
      )

    case INTEGRATION_SYSTEM_PROVIDER.GOOGLE_ADS:
      return (
        <GoogleAdsProvider
          onSync={onSync}
          syncState={{ starting, disabled: syncDisabled }}
          {...rest}
        />
      )

    default:
      return null
  }
}
