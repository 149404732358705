import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { forecastSessionStepAPI } from '@/features/forecast-session/api'
import { DefaultForecastSessionDTO } from '@/features/forecast-session/api/dto'
import {
  FORECAST_SESSION_STEP_UNDO_FAILED,
  FORECAST_SESSION_STEP_UNDO_REQUEST,
  FORECAST_SESSION_STEP_UNDO_SUCCESS,
} from './actionTypes'

type ArgsType = {
  params: DefaultForecastSessionDTO
}

export const forecastSessionStepUndoPA = createPromiseAction(
  FORECAST_SESSION_STEP_UNDO_REQUEST,
  FORECAST_SESSION_STEP_UNDO_SUCCESS,
  FORECAST_SESSION_STEP_UNDO_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* stepUndo(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(
      forecastSessionStepAPI.stepUndoForecastSession,
      params
    )
    const { data } = response

    yield put(forecastSessionStepUndoPA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* undoSaga() {
  yield takeEvery(forecastSessionStepUndoPA.request, stepUndo)
}
