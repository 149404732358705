import React from 'react'
import { Billing } from '@/features/billing/routes'
import { Dashboard } from '@/features/dashboard/routes'
import { Forecast } from '@/features/forecast/routes'
import { InputsRoot } from '@/features/input/routes'
import { Integrations } from '@/features/integration/routes'
import { ProfileRoot } from '@/features/profile/routes'
import { Tracker } from '@/features/tracker/routes'
import { ROLE } from '@/features/users/types'
import { GUARD } from '../../guards'

type PrivateRouteType = {
  index?: boolean
  path?: string
  element: React.ReactNode
  roles?: Array<string>
  guards: Array<GUARD>
}

const generalRoutes: PrivateRouteType[] = [
  {
    path: 'profile/*',
    element: <ProfileRoot />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'forecast',
    element: <Forecast />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'tracker',
    element: <Tracker />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'inputs/*',
    element: <InputsRoot />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'billing',
    element: <Billing />,
    roles: [ROLE.Client],
    guards: [],
  },
  {
    path: 'integrations',
    element: <Integrations />,
    roles: [ROLE.Client],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return generalRoutes
}
