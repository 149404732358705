import React from 'react'
import { useTranslation } from 'react-i18next'
import { CarouselProps } from '@mantine/carousel'
import { DemoCarousel } from '@/components/Elements'
import useStyles from './DemoMediaCardCarousel.styles'
import { MediaCard } from './MediaCard'

type ItemType = {
  icon: any
  renderContent: () => any
}

interface IProps extends CarouselProps {
  data: ItemType[]
}

export const DemoMediaCardCarousel = ({ data, ...props }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <DemoCarousel
      {...props}
      slides={data.map((item) => (
        <div className={classes.slideWrapper}>
          <MediaCard data={item} />
        </div>
      ))}
    />
  )
}
