import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { dailyTrackerAPI } from '@/features/tracker/api'
import i18n from '@/i18n'
import { trackerDailyTrackerList } from './slice'

function* getList({ payload: { params } }: PayloadAction<{ params: { date: string } }>) {
  try {
    const response: AxiosResponse = yield call(dailyTrackerAPI.getDailyTracker, params)

    const { data } = response

    yield put(trackerDailyTrackerList.getListSuccess({ data, meta: params }))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(trackerDailyTrackerList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(trackerDailyTrackerList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
