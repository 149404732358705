import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    wrapper: {
      backgroundColor: theme.colors['indigo'][7],
      minHeight: 429,
      marginTop: 46,
      paddingRight: 50,
      paddingBottom: 40,
      paddingLeft: 52,
      borderRadius: theme.radius.xl,

      [theme.fn.smallerThan('md')]: {
        marginTop: 0,
      },
    },

    mediaBlock: {
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.fn.smallerThan('md')]: {
        justifyContent: 'center',
      },
    },
  }
})
