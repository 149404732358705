import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@mantine/core'
import { CTAButton } from '@/components/Elements'
import { Container, Section, SkewBg } from '@/features/landing/components/Elements'
import { Reviews, TitleSection } from './Sections'
import useStyles from './TestimonialsSection.styles'

export const TestimonialsSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Section>
      <SkewBg primary={false} />

      <Container size={'xl'}>
        <TitleSection />

        <Box my={'xl'}>
          <Reviews />
        </Box>

        <Flex pt={'xl'} justify={'center'}>
          <CTAButton mode={'link'} to={'/login'} />
        </Flex>
      </Container>
    </Section>
  )
}
