import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
    },

    itemIcon: {
      height: 50,

      img: {
        objectFit: 'contain',
        maxHeight: '100%',
        maxWidth: '100%',
      },
    },
  }
})
