import React from 'react'
import { useTranslation } from 'react-i18next'
import { ForecastSectionFormValue } from '@/features/forecast/types'
import { SectionInputsUpdateControl } from '../../../../Controls'

interface IProps {
  index: number
  section: ForecastSectionFormValue
}

export const InputsUpdate = ({ section, index }: IProps) => {
  const { t } = useTranslation()

  return <SectionInputsUpdateControl index={index} section={section} />
}
