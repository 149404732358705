import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Container, Section, SkewBg } from '@/features/landing/components/Elements'
import useStyles from './HeroSection.styles'
import { ContentSection, IntegrationSystemsSection } from './Sections'

export const HeroSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Section className={'pt-0'}>
      <SkewBg primary={false} />

      <Container size={'xl'}>
        <Box mb={'xl'}>
          <ContentSection />
        </Box>

        <Box py={'xl'}>
          <IntegrationSystemsSection />
        </Box>
      </Container>
    </Section>
  )
}
