import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@mantine/core'
import { CTAButton } from '@/components/Elements'
import { Container, Section } from '@/features/landing/components/Elements'
import useStyles from './IntegrationsSection.styles'
import { Integrations, TitleSection } from './Sections'

export const IntegrationsSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Section primary={false}>
      <Container size={'xl'}>
        <TitleSection />

        <Box my={'xl'}>
          <Integrations />
        </Box>

        <Flex pt={'xl'} justify={'center'}>
          <CTAButton mode={'link'} to={'/login'} />
        </Flex>
      </Container>
    </Section>
  )
}
