import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Box } from '@mantine/core'
import { DataShower } from '@/components/Elements'
import { useImportDataEffect, useImportDataQuery } from '@/features/import/hooks'
import { importStartPA } from '@/features/import/store'
import { useNotify } from '@/hooks'
import { ImportDetailsTable } from '../../ImportDetailsTable'
import { ImportForm } from '../../ImportForm'
import { ImportInstruction } from '../../ImportInstruction'

interface IProps {
  onClose: () => void
  onSuccess?: () => void
  onImportComplete?: () => Promise<void>
}

export const ImportEntryContainer = ({ onClose, onImportComplete, ...props }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const {
    state: { isLoading, data, error },
  } = useImportDataQuery()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }
    await dispatch(importStartPA.request({ params }))
    showNotification({
      type: 'success',
      message: t('start_successfully'),
    })

    onSubmitSuccess()
  }

  const onComplete = async () => {
    if (onImportComplete) {
      await onImportComplete()
    }

    showNotification({
      type: 'success',
      message: t('upload_data_completed'),
    })
  }

  const importDataEffect = useImportDataEffect({ onComplete })

  const onSubmitSuccess = () => {
    importDataEffect.onCheck()
  }

  return (
    <>
      <DataShower isLoading={isLoading} isFetched={!!data} isFailed={!!error} error={error}>
        {data && (
          <>
            <ImportInstruction example={data?.example_file} />

            <Box my={'xl'}>
              <ImportForm onSubmit={onSubmit} />
            </Box>

            <ImportDetailsTable data={data} />
          </>
        )}
      </DataShower>
    </>
  )
}
