import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box, Center } from '@mantine/core'
import { Anchor } from '@/components/Elements'

interface IProps {
  children: React.ReactNode
}

export const AuthContentLayout = ({ children }: IProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  return (
    <div>
      {children}

      <Box mt={'md'}>
        <Center>
          <Anchor
            component={Link}
            to={'/privacy-policy'}
            state={{ from: location.pathname }}
            fw={'bold'}
            fz={'sm'}
          >
            {t('privacy_policy')}
          </Anchor>
        </Center>
      </Box>
    </div>
  )
}
