import axios from 'axios'
import qs from 'qs'
import { authData } from '@/features/auth/store'
import { startsWith } from '@/libs/lodash'

const BASE_URL = process.env.REACT_APP_BASE_URL

axios.interceptors.request.use(
  (config: any) => {
    return {
      ...config,
      baseURL: BASE_URL,
      withCredentials: true,
      paramsSerializer: (params: object) => {
        return qs.stringify(params, { encode: false })
      },
      headers: { 'Content-Type': 'application/json', ...config.headers },
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)
axios.interceptors.request.use(
  async (config) => {
    if (startsWith(config.url, 'api/auth')) {
      await axios.get('sanctum/csrf-cookie')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status) {
      switch (error.response?.status) {
        case 401:
          if (window.store.getState().auth.data.isLoggedIn) {
            window.store.dispatch(authData.cleanData())
          }
          break
        case 400:
          break

        case 403:
          if (!window.store.getState().auth.data.isLoggedIn) {
            if (error.response?.data?.loggedIn) {
              // user already logged in
            }
          }
          break

        case 419:
          window.store.dispatch(authData.cleanData())

          break
        default:
          break
      }
    }
    return Promise.reject(error)
  }
)
