import React from 'react'
import { useForm } from 'react-hook-form'
import { FormTypes } from '@/types'
import { ValidationsUtils } from '@/utils'
import { UseForecastFormProps, UseForecastFormReturn } from '../types'

export const useForecastForm = ({
  formConfig,
  ...props
}: UseForecastFormProps): UseForecastFormReturn => {
  const form = useForm({
    ...formConfig,
    defaultValues: {
      sections: [],
      ...formConfig?.defaultValues,
    },
  })

  const { handleSubmit, setError } = form

  const onSubmit = async (data: any, submit: (...args: any[]) => Promise<any>) => {
    try {
      await submit(data)
    } catch (error) {
      const serverError = error as FormTypes.ValidationErrors
      ValidationsUtils.setServerSideErrors(serverError?.errors, setError)

      throw error
    }
  }

  const onHandleSubmit = async (
    submit: (...args: any[]) => Promise<any>,
    onInvalid: (data?: any) => Promise<any>
  ) => await handleSubmit(async (data) => await onSubmit(data, submit), onInvalid)()

  return {
    form,
    onHandleSubmit,
  }
}
