import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { integrationGoogleSetupPA } from '@/features/integration/store'
import { useNotify } from '@/hooks'
import { AccountForm } from './AccountForm'

interface IProps {
  onClose: () => void
  onSuccess: (data: any) => void
}

export const AccountSetupContainer = ({ onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }
    const data = await dispatch(integrationGoogleSetupPA.request({ params }))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  return (
    <>
      <AccountForm onSubmit={onSubmit} onCancel={onClose} />
    </>
  )
}
