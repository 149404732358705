import facebook from '@/assets/images/integration-systems/facebook.svg'
import googleAds from '@/assets/images/integration-systems/google-ads.png'
import klaviyo from '@/assets/images/integration-systems/klaviyo.svg'
import shopify from '@/assets/images/integration-systems/shopify.svg'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'
import { IntegrationSystem } from '@/features/integration/types'

export const getIntegrationSystems = (): IntegrationSystem[] => {
  return [
    {
      label: 'Shopify',
      provider: INTEGRATION_SYSTEM_PROVIDER.SHOPIFY,
      avatar: shopify,
      data: null,
    },
    {
      label: 'Facebook',
      provider: INTEGRATION_SYSTEM_PROVIDER.FACEBOOK,
      avatar: facebook,
      data: null,
    },
    {
      label: 'Klaviyo',
      provider: INTEGRATION_SYSTEM_PROVIDER.KLAVIYO,
      avatar: klaviyo,
      data: null,
    },
    {
      label: 'Google Ads',
      provider: INTEGRATION_SYSTEM_PROVIDER.GOOGLE_ADS,
      avatar: googleAds,
      data: null,
    },
  ]
}

export const integrationSystemsMap = getIntegrationSystems().reduce((acc: any, curr) => {
  const { provider } = curr

  return acc.set(provider, curr)
}, new Map())
