import _ from 'lodash'
import { INPUT_SYMBOL } from '@/features/input/consts/input'

type OptionType = {
  label: string
  value: INPUT_SYMBOL
  unit: string | null
}

export const getSymbols = () => {
  return [
    {
      label: '$',
      value: INPUT_SYMBOL.DOLLAR,
      unit: '$',
    },
    {
      label: '%',
      value: INPUT_SYMBOL.PERCENTAGE,
      unit: '%',
    },
    {
      label: '-',
      value: INPUT_SYMBOL.NUMERIC,
      unit: null,
    },
  ]
}

export const getSymbol = (value: INPUT_SYMBOL | string): OptionType | any => {
  const options = getSymbols()
  return _.find(options, { value })
}

export const isCurrency = (value: INPUT_SYMBOL): boolean => {
  return [INPUT_SYMBOL.DOLLAR].includes(value)
}

export const isPercentage = (value: INPUT_SYMBOL): boolean => {
  return [INPUT_SYMBOL.PERCENTAGE].includes(value)
}
