import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LandingLayout } from '@/components/Layouts'
import { Home } from '../home'

export const LandingRoot = () => {
  return (
    <Routes>
      <Route element={<LandingLayout />}>
        <Route index element={<Home />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
