import React from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel, CarouselProps } from '@mantine/carousel'
import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Icon } from '@/components/Elements'
import useStyles from './DemoCarousel.styles'

interface IProps extends CarouselProps {
  slides: React.ReactNode[]
}

export const DemoCarousel = ({ slides, ...props }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const theme = useMantineTheme()

  const minSmScreen = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`)

  const renderIcon = (isNext?: boolean) => (
    <Icon
      color={theme.fn.primaryColor()}
      name={isNext ? 'arrow-right-short' : 'arrow-left-short'}
      size={minSmScreen ? 48 : 24}
    />
  )

  return (
    <Carousel
      controlSize={minSmScreen ? 64 : 32}
      controlsOffset={0}
      slideGap="xl"
      previousControlIcon={renderIcon()}
      nextControlIcon={renderIcon(true)}
      {...props}
    >
      {slides.map((item, i) => (
        <Carousel.Slide key={i}>{item}</Carousel.Slide>
      ))}
    </Carousel>
  )
}
