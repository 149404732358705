import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    itemIcon: {
      marginRight: theme.spacing.xl,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
        marginBottom: theme.spacing.xl,
      },

      img: {
        width: 65,
        borderRadius: theme.radius.lg,
      },
    },
  }
})
