import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    item: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      alignItems: 'center',
    },

    itemHeader: {
      borderRadius: theme.radius.xl,
      overflow: 'hidden',
    },

    itemHeaderBg: {
      width: '100%',
      objectFit: 'cover',
    },

    itemContentBlock: {
      position: 'relative',
      zIndex: 1,
      marginTop: -56,
      textAlign: 'center',

      [theme.fn.smallerThan('md')]: {
        marginTop: -47,
      },
    },

    itemIcon: {
      marginBottom: theme.spacing.xl,

      img: {
        width: 102,
        height: 102,
        borderRadius: theme.radius.xl,
        boxShadow: `0 5px 36px 0 ${theme.colors[theme.primaryColor][1]}`,

        [theme.fn.smallerThan('md')]: {
          width: 82,
          height: 82,
        },
      },
    },
  }
})
