import React from 'react'
import { useTranslation } from 'react-i18next'
import bgDots from '@/assets/images/illustrations/bg-dots.svg'
import dashboard from '@/assets/images/illustrations/dashboard.png'
import useStyles from './ImageBlock.styles'

export const ImageBlock = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <div className={classes.root}>
      <img src={dashboard} className={classes.imgBase} alt={'dashboard'} />
      <img src={bgDots} className={cx(classes.dots, classes.dotsTop)} alt={'dots'} />
      <img src={bgDots} className={cx(classes.dots, classes.dotsLeft)} alt={'dots'} />
      <img src={bgDots} className={cx(classes.dots, classes.dotsBottom)} alt={'dots'} />
    </div>
  )
}
