import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { TrackerQueryDataLoader } from '@/features/tracker/components/DailyTracker'
import { SectionsDailyTrackerContainer } from '@/features/tracker/components/DailyTracker/SectionsDailyTracker'

export const Tracker = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'daily_tracker'} />

      <PageContent>
        <TrackerQueryDataLoader>
          <SectionsDailyTrackerContainer />
        </TrackerQueryDataLoader>
      </PageContent>
    </>
  )
}
