import React from 'react'
import { useTranslation } from 'react-i18next'
import dots from '@/assets/images/illustrations/bg-white-dots.svg'
import shape1 from '@/assets/images/illustrations/cta-shape-1.svg'
import shape2 from '@/assets/images/illustrations/cta-shape-2.svg'
import shape3 from '@/assets/images/illustrations/cta-shape-3.svg'
import useStyles from './ShapesSection.styles'

export const ShapesSection = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <>
      <img alt={'shape'} src={shape1} className={cx(classes.shape, classes.shape1)} />
      <img alt={'shape'} src={dots} className={cx(classes.shape, classes.shape2)} />
      <img alt={'shape'} src={shape2} className={cx(classes.shape, classes.shape3)} />
      <img alt={'shape'} src={dots} className={cx(classes.shape, classes.shape4)} />
      <img alt={'shape'} src={dots} className={cx(classes.shape, classes.shape5)} />
      <img alt={'shape'} src={shape3} className={cx(classes.shape, classes.shape6)} />
    </>
  )
}
