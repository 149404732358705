import React from 'react'
import { Container, Divider } from '@mantine/core'
import useStyles from './SectionDivider.styles'

interface IProps {
  primary?: boolean
}

export const SectionDivider = ({ primary = true }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <Container size={'xl'}>
      <Divider
        className={cx(classes.root, {
          [`${classes.primary}`]: primary,
        })}
      />
    </Container>
  )
}
