import axios from 'axios'
import { FacebookIntegrationCreateDTO, FacebookIntegrationSetupDTO } from './dto'

class FacebookIntegrationAPI {
  getUrl = async () => {
    return axios.get(`api/integrations/facebook/url`)
  }

  createFacebookIntegration = async (data: FacebookIntegrationCreateDTO) => {
    return axios.post(`api/integrations/facebook`, data)
  }

  getBusinessUsers = async () => {
    return axios.get(`api/integrations/facebook/business-users`)
  }

  getAdAccounts = async (params: any = {}) => {
    return axios.get(`api/integrations/facebook/ad-accounts`, {
      params,
    })
  }

  setupFacebookIntegration = async (data: FacebookIntegrationSetupDTO) => {
    return axios.put(`api/integrations/facebook`, data)
  }
}

export const facebookIntegrationAPI = new FacebookIntegrationAPI()
