import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { integrationSyncStartAllPA } from '@/features/integration/store'
import { useAppDispatch } from '@/store'
import { authLoginPA, authVerifyEmailPA } from '../../../store'
import { FormValues, LoginForm } from './LoginForm'

export const LoginContainer = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleSubmit = async (values: FormValues) => {
    await dispatch(authLoginPA.request(values))

    dispatch(integrationSyncStartAllPA.request())
  }

  const onVerifyEmail = async (email: string) => {
    await dispatch(authVerifyEmailPA.request({ email }))
  }

  return (
    <AuthCard title={t('log_in_to_your_account')}>
      <div className={'flex'}>
        <Title order={6}>
          {t('dont_have_account')}?{' '}
          <Anchor component={Link} to={'/register'}>
            {t('sign_up')}
          </Anchor>
        </Title>
      </div>

      <Space h={'md'} />

      <LoginForm onSubmit={handleSubmit} onVerifyEmail={onVerifyEmail} />
    </AuthCard>
  )
}
