import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    section: {
      paddingBottom: 60,

      [theme.fn.smallerThan('md')]: {
        paddingBottom: 30,
      },
    },
  }
})
