import axios from 'axios'
import { ShopifyIntegrationCreateDTO, ShopifyIntegrationSetupDTO } from './dto'

class ShopifyIntegrationAPI {
  getUrl = async () => {
    return axios.get(`api/integrations/shopify/url`)
  }

  createShopifyIntegration = async (data: ShopifyIntegrationCreateDTO) => {
    return axios.post(`api/integrations/shopify`, data)
  }

  setupShopifyIntegration = async (data: ShopifyIntegrationSetupDTO) => {
    return axios.put(`api/integrations/shopify`, data)
  }
}

export const shopifyIntegrationAPI = new ShopifyIntegrationAPI()
