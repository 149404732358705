import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from '@/components/Controls'
import { ActionIconButton } from '@/components/Elements'
import { useInputRemove } from '@/features/input/hooks'
import { InputModel } from '@/features/input/types'

interface IProps {
  input: InputModel
  onSuccess?: (data: any) => void
}

export const RemoveControl = ({ input, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { loading, onSubmit } = useInputRemove()

  const onRemoveConfirm = async () => {
    await onSubmit(input?.id, onSuccess)
  }

  return (
    <>
      <ConfirmationControl
        onConfirm={onRemoveConfirm}
        render={({ onOpen }) => (
          <ActionIconButton name={'remove'} onClick={onOpen} disabled={loading} />
        )}
      />
    </>
  )
}
