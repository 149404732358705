import _ from 'lodash'
import { IMPORT_MODE } from '@/features/import/consts/import'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: IMPORT_MODE
}

export const getModes = () => {
  return [
    {
      label: i18n.t('import.mode.full'),
      value: IMPORT_MODE.FULL,
      description: i18n.t('import.mode.full.description'),
    },
    {
      label: i18n.t('import.mode.replace'),
      value: IMPORT_MODE.REPLACE,
      description: i18n.t('import.mode.replace.description'),
    },
    {
      label: i18n.t('import.mode.only_new'),
      value: IMPORT_MODE.ONLY_NEW,
      description: i18n.t('import.mode.only_new.description'),
    },
  ]
}

export const getMode = (value: IMPORT_MODE | string): OptionType | any => {
  const options = getModes()
  return _.find(options, { value })
}
