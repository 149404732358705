import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { importData, importFetchPA } from '@/features/import/store'
import { useAppSelector } from '@/store'

export const useImportDataQuery = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const state = useAppSelector((state) => state.import.data)

  const onFetch = async () => {
    const data = await dispatch(importFetchPA.request({}))
    return data
  }

  const onClean = () => {
    dispatch(importData.cleanState())
  }

  const isSuccess = useMemo(() => {
    return state.data && !state.error
  }, [state.data, state.error])

  const isLoading = useMemo(() => {
    return state.loading && !isSuccess
  }, [isSuccess, state.loading])

  const isFetching = useMemo(() => {
    return isSuccess && state.loading
  }, [isSuccess, state.loading])

  return {
    state: {
      ...state,
      isLoading,
      isFetching,
      isSuccess,
    },
    onFetch,
    onClean,
  }
}
