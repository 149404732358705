import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { forecastResetGoalsAPI } from '@/features/forecast/api'
import { ForecastResetGoalsDTO } from '@/features/forecast/api/dto'
import {
  FORECAST_RESET_GOALS_SUBMIT_FAILED,
  FORECAST_RESET_GOALS_SUBMIT_REQUEST,
  FORECAST_RESET_GOALS_SUBMIT_SUCCESS,
} from './actionTypes'

type ArgsType = {
  params?: ForecastResetGoalsDTO
}

export const forecastResetGoalsSubmitPA = createPromiseAction(
  FORECAST_RESET_GOALS_SUBMIT_REQUEST,
  FORECAST_RESET_GOALS_SUBMIT_SUCCESS,
  FORECAST_RESET_GOALS_SUBMIT_FAILED
)<ArgsType, unknown, AxiosError<any>>()

function* submit(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(forecastResetGoalsAPI.resetGoals, params)
    const { data } = response

    yield put(forecastResetGoalsSubmitPA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (err) {
    const error = err as AxiosError<any>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(forecastResetGoalsSubmitPA.request, submit)
}
