import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { FileInput, FileInputProps } from '../../FileInput'

interface FleInputControllerProps<T extends FieldValues> extends FileInputProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const FileInputController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: FleInputControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return <FileInput {...field} fieldRef={ref} meta={meta} {...rest} />
      }}
    />
  )
}
