import React from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralNumericFormat, PercentageFormat } from '@/components/Elements'
import { ForecastSectionInputFormValue } from '@/features/forecast/types'
import { useInputModel, useInputStaticOptions } from '@/features/input/hooks'
import { InputModel } from '@/features/input/types'
import { CommonUtils } from '@/utils'

interface IProps {
  data: any
  input: ForecastSectionInputFormValue
  getInputDataById: (id: number) => InputModel | undefined
}

export const ActualCell = ({ data, input, getInputDataById }: IProps) => {
  const { t } = useTranslation()

  const value: any = data?.value

  const { input_id } = input
  const inputData = getInputDataById(input_id)

  const { getSymbol, isCurrencySymbol } = useInputStaticOptions()

  const { isCustomPercentageInput } = useInputModel()

  const symbol = inputData?.symbol
  const unit = symbol ? getSymbol(symbol)?.unit : null

  const fixedDecimalScale = symbol ? isCurrencySymbol(symbol) : false

  const isPercentageFormat = inputData ? isCustomPercentageInput(inputData) : false

  return (
    <>
      {!CommonUtils.isNil(value) ? (
        <>
          {unit && `${unit} `}
          {isPercentageFormat ? (
            <PercentageFormat value={value} />
          ) : (
            <GeneralNumericFormat value={value} fixedDecimalScale={fixedDecimalScale} />
          )}
        </>
      ) : null}
    </>
  )
}
