import React from 'react'
import { useTranslation } from 'react-i18next'
import { INPUT_TYPE } from '@/features/input/consts/input'
import { useInputStaticOptions } from '@/features/input/hooks'

interface IProps {
  value: INPUT_TYPE
}

export const Type = ({ value }: IProps) => {
  const { t } = useTranslation()

  const { getType } = useInputStaticOptions()

  return <>{getType(value)?.label || '-'}</>
}
