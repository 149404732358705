import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { InputModel } from '@/features/input/types'
import { trackerInputsList } from '@/features/tracker/store'
import { useAppSelector } from '@/store'

export const useTrackerInputsList = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { list, ...state } = useAppSelector((state) => state.tracker.inputs.list)

  const inputsDataMap = useMemo(() => {
    return list.reduce((acc: any, curr) => {
      const { id } = curr

      return acc.set(id, curr)
    }, new Map())
  }, [list])

  const onFetch = (params: any = {}) => {
    params = {
      pagination: false,
      sort: [{ id: 'created_at', desc: true }],
      ...params,
    }

    dispatch(trackerInputsList.getList({ params }))
  }

  const onClean = () => {
    dispatch(trackerInputsList.cleanState())
  }

  const getInputDataByAlias = (alias: string) => {
    return _.find(list, { alias })
  }

  const updateTrackerInputsActiveStatus = (active: string[] | number[]) => {
    dispatch(trackerInputsList.updateTrackerInputsActiveStatus(active))
  }

  const getInputDataById = (id: number): InputModel | undefined => {
    return inputsDataMap.get(id)
  }

  return {
    list,
    ...state,
    onFetch,
    onClean,
    getInputDataByAlias,
    updateTrackerInputsActiveStatus,
    getInputDataById,
  }
}
