import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SelectController } from '@/components/FormElements'
import { useInputStaticOptions } from '@/features/input/hooks'

export const SymbolSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { getSymbols } = useInputStaticOptions()

  return (
    <>
      <Grid>
        <Grid.Col xs={4}>
          <SelectController
            control={control}
            name={'symbol'}
            shouldUnregister
            defaultValue={null}
            id={'symbol'}
            label={t('metric_type')}
            placeholder={t('metric_type')}
            mb={'md'}
            data={getSymbols()}
            clearable
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
