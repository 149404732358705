import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  return {
    blankHeader: {
      borderBottom: 'none !important',
    },

    noteCell: {
      padding: '0 !important',
      minWidth: 500,
      maxWidth: 500,
    },

    inputHeader: {
      minWidth: 160,
      maxWidth: 160,
    },
  }
})
