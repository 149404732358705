import { Row, RowData } from '@tanstack/react-table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputModel } from '@/features/input/types'
import {
  useDailyTrackerStaticOptions,
  useFSDailyTrackerTableConfig,
} from '@/features/tracker/hooks'
import { SectionsDailyTrackerModel } from '@/features/tracker/types'
import { DateCell, InputCell, NoteCell } from './Cell'
import { SectionHeader } from './Headers'
import useStyles from './Table.styles'
import { TableView } from './TableView'

interface IProps {
  data: SectionsDailyTrackerModel[]
  loading: boolean
  lastRef: any
  getInputDataById: (id: number) => InputModel | undefined
  onNoteUpdate: (data: any) => void
  onTrackerFSActiveSuccess: (data: any) => void
}

export const ForecastSectionsDailyTrackerTable = ({
  data,
  loading,
  getInputDataById,
  onNoteUpdate,
  lastRef,
  onTrackerFSActiveSuccess,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const [columns, setColumns] = useState<any[]>([])

  const { getColumns } = useFSDailyTrackerTableConfig({
    components: {
      DateCell,
      InputCell,
      NoteCell,
      SectionHeader,
    },
    getInputDataById,
    onNoteUpdate,
    onTrackerFSActiveSuccess,
  })

  useEffect(() => {
    setColumns(getColumns(data))
  }, [data[0]?.s])

  const { isDateType, isGoalType } = useDailyTrackerStaticOptions()

  const getRowClassName = (row: Row<RowData>) => {
    const { original } = row

    const { t: type, d: date } = original as SectionsDailyTrackerModel

    let result = classes.row

    if (isDateType(type) && date) {
      const today = moment()
      const mDate = moment(date)

      if (mDate.isSame(today, 'day')) {
        result = classes.todayRow
      } else if (mDate.isBefore(today, 'day')) {
        result = classes.prevRow
      }
    } else {
      if (isGoalType(type)) {
        result = classes.highlightRow
      } else {
        result = classes.softHighlightRow
      }
    }

    return result
  }

  return (
    <TableView
      data={data}
      columns={columns}
      loading={loading}
      tableProps={{ className: classes.table }}
      getRowClassName={getRowClassName}
      lastRef={lastRef}
    />
  )
}
