import React from 'react'
import useStyles from './SquareBg.styles'

interface IProps {
  primary?: boolean
}

export const SquareBg = ({ primary = true }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [`${classes.primary}`]: primary,
      })}
    />
  )
}
