import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIconButton } from '@/components/Elements'
import { useForecastContext } from '@/features/forecast/contexts'
import { ForecastSectionFormValue } from '@/features/forecast/types'
import { usePopup } from '@/hooks'
import { SectionEdit } from '../../Section'

interface IProps {
  section: ForecastSectionFormValue
  index: number
}

export const SectionEditControl = ({ section, index }: IProps) => {
  const { t } = useTranslation()

  const {
    actions: { sectionUpdate, sectionRemove },
  } = useForecastContext()

  const { visible, open, close } = usePopup()

  const onClose = () => {
    close()
  }

  const onSave = (data: any) => {
    onClose()

    sectionUpdate(index, data)
  }

  const onRemove = () => {
    onClose()

    sectionRemove(index)
  }

  return (
    <>
      <ActionIconButton name={'edit'} onClick={open} />

      <SectionEdit
        section={section}
        visible={visible}
        onClose={onClose}
        onSave={onSave}
        onRemove={onRemove}
      />
    </>
  )
}
