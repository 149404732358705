import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Textarea, UnstyledButton } from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'
import { DAILY_TRACKER_TYPE } from '@/features/tracker/consts/daily-tracker'
import { SectionsDailyTrackerModel } from '@/features/tracker/types'

interface IProps {
  data: SectionsDailyTrackerModel
  onNoteUpdate: (data: { date: string; type: DAILY_TRACKER_TYPE; value: string }) => void
}

export const NoteCell = ({ data, onNoteUpdate }: IProps) => {
  const { t } = useTranslation()

  const [editMode, setEditMode] = useState<boolean>(false)
  const ref = useClickOutside(() => setEditMode(false), ['mouseup', 'touchend'])

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const { d: date, t: type, n: note } = data

  const initialValue = (note || '').toString()

  const [value, setValue] = useState<string>(initialValue)
  const onBlur = () => {
    if (value !== initialValue) {
      onNoteUpdate({ date, type, value })
    }
  }

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onEditMode = () => {
    setEditMode(true)

    setTimeout(() => {
      if (inputRef?.current) {
        inputRef?.current.focus()
      }
    }, 0)
  }

  return (
    <UnstyledButton px="md" py="sm" onClick={onEditMode} className={'w-full h-full'} ref={ref}>
      <>
        {editMode ? (
          <Textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            autosize
            minRows={1}
            ref={inputRef}
          />
        ) : (
          <div className={'whitespace-pre-wrap break-words'}>{value}</div>
        )}
      </>
    </UnstyledButton>
  )
}
