import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Title } from '@mantine/core'
import { SectionTitleBadge } from '@/features/landing/components/Elements'
import useStyles from './TitleSection.styles'

export const TitleSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <Box mb={'xl'}>
          <SectionTitleBadge light>{t('features')}</SectionTitleBadge>
        </Box>

        <Title size={40}>{t('landing.features-section.title')}</Title>
      </div>
    </div>
  )
}
