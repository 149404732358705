import React, { useEffect, useRef } from 'react'

interface WindowOptions {
  width?: number
  height?: number
}

interface IProps {
  onPostMessage?: (event: MessageEvent) => void
  closeOnUnmount?: boolean
}

const defaultWindowOptions = {
  width: 600,
  height: 600,
}

export const useWindowPopup = ({ onPostMessage, closeOnUnmount = true }: IProps) => {
  const popupRef = useRef<any>(null)

  const intervalRef = useRef<any>(null)

  const isOpen = () => !!popupRef.current

  const focus = () => {
    if (popupRef.current) {
      popupRef.current.focus()
    }
  }

  const open = (url: string, options?: WindowOptions) => {
    if (popupRef.current) {
      focus()

      popupRef.current.location = url

      return
    } else {
      popupRef.current = createPopup(url, options)

      if (onPostMessage) {
        window.addEventListener('message', onPostMessage, false)
      }

      watch()
    }
  }

  const close = () => {
    if (onPostMessage) {
      window.removeEventListener('message', onPostMessage)
    }

    if (popupRef.current) {
      popupRef.current.close()
      popupRef.current = null
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const createPopup = (url: string, options?: WindowOptions) => {
    const windowOptions = {
      ...defaultWindowOptions,
      ...options,
    }

    const { width: windowWidth, height: windowHeight } = windowOptions

    const width = window.innerWidth < windowWidth ? window.innerWidth : windowWidth
    const height = window.innerHeight < windowHeight ? window.innerHeight : windowHeight
    const left = window.innerWidth / 2 - width / 2
    const top = window.innerHeight / 2 - height / 2

    return window.open(
      url,
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no,
              scrollbars=no, resizable=no, copyhistory=no, width=${width},
              height=${height}, top=${top}, left=${left}`
    )
  }

  const watch = () => {
    intervalRef.current = setInterval(() => {
      if (!popupRef.current || popupRef.current.closed || popupRef.current.closed === undefined) {
        close()
      }
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (popupRef.current && closeOnUnmount) {
        close()
      }
    }
  }, [])

  return {
    popupRef,
    open,
    close,
    focus,
    isOpen,
  }
}
