import axios from 'axios'
import { GoogleIntegrationCreateDTO, GoogleIntegrationSetupDTO } from './dto'

class GoogleIntegrationAPI {
  getUrl = async () => {
    return axios.get(`api/integrations/google/url`)
  }

  createGoogleIntegration = async (data: GoogleIntegrationCreateDTO) => {
    return axios.post(`api/integrations/google`, data)
  }

  getManagerAccounts = async (params: any = {}) => {
    return axios.get(`api/integrations/google/manager-accounts`, {
      params,
    })
  }

  getClientAccounts = async (params: any = {}) => {
    return axios.get(`api/integrations/google/client-accounts`, {
      params,
    })
  }

  setupGoogleIntegration = async (data: GoogleIntegrationSetupDTO) => {
    return axios.put(`api/integrations/google`, data)
  }
}

export const googleIntegrationAPI = new GoogleIntegrationAPI()
