import axios from 'axios'
import { RequestUtils } from '@/utils'
import { StartImport } from './dto'

class ImportAPI {
  getImport = async () => {
    return axios.get(`api/import`)
  }

  startImport = async (params: StartImport) => {
    const formData = new FormData()

    RequestUtils.buildFormData(formData, params, '')

    return axios.post('api/import', formData)
  }
}

export const importAPI = new ImportAPI()
