import React from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralNumericFormat, PercentageFormat } from '@/components/Elements'
import { useInputModel, useInputStaticOptions } from '@/features/input/hooks'
import { InputModel } from '@/features/input/types'
import { SectionsDailyTrackerInput } from '@/features/tracker/types'

interface IProps {
  data: SectionsDailyTrackerInput
  input?: InputModel
}

export const InputCell = ({ data, input }: IProps) => {
  const { t } = useTranslation()

  const { v: value } = data ?? {}

  const symbol = input?.symbol

  const { getSymbol, isCurrencySymbol } = useInputStaticOptions()

  const { isCustomPercentageInput } = useInputModel()

  const unit = symbol ? getSymbol(symbol)?.unit : null

  const fixedDecimalScale = symbol ? isCurrencySymbol(symbol) : false

  const isPercentageFormat = input ? isCustomPercentageInput(input) : false

  return (
    <div>
      {unit && `${unit} `}
      {isPercentageFormat ? (
        <PercentageFormat value={value} />
      ) : (
        <GeneralNumericFormat value={value} fixedDecimalScale={fixedDecimalScale} />
      )}
    </div>
  )
}
