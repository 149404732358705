import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'

interface IProps {
  syncInProgress?: boolean
  hasConnectedIntegrations?: boolean
}

export const SyncStatusLabel = ({
  syncInProgress = false,
  hasConnectedIntegrations = false,
}: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Text fz={'sm'}>
        {t('status')}:{' '}
        <Text component={'span'} fw={'600'}>
          {syncInProgress ? t('in_progress') : t('inactive')}
        </Text>
      </Text>
    </>
  )
}
