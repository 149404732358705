import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { MultiSelectController } from '@/components/FormElements'
import { SearchFormSection } from '@/features/filter/components/FormSections'
import { useInputStaticOptions } from '@/features/input/hooks'

export const GeneralFilterForm = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { getTypes } = useInputStaticOptions()

  return (
    <Group align={'flex-start'}>
      <SearchFormSection />

      <MultiSelectController
        control={control}
        name={'types'}
        id={'types'}
        placeholder={t('types')}
        data={getTypes()}
        clearable
      />
    </Group>
  )
}
