import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Flex, Group, Title } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { Edit } from './Edit'
import { InputsUpdate } from './InputsUpdate'

interface IProps {
  row: any
}

export const SectionCell = ({ row }: IProps) => {
  const { t } = useTranslation()

  const { original, index } = row

  const { name } = original ?? {}

  return (
    <>
      <Flex align="center" gap={'xs'} className={''}>
        <Flex align="center" className={'grow'} gap={'xs'}>
          <Title order={5}>{name || '-'}</Title>

          <Flex align="center" gap={'xs'}>
            {/*sort={original.sort}*/}
            <Edit index={index} section={original} />

            <InputsUpdate index={index} section={original} />
          </Flex>
        </Flex>

        {row.getCanExpand() && (
          <ActionIcon color={'primary'} onClick={row.getToggleExpandedHandler()}>
            <Icon
              name={'chevron-down'}
              style={{ transform: row.getIsExpanded() ? 'rotate(180deg)' : 'none' }}
            />
          </ActionIcon>
        )}
      </Flex>
    </>
  )
}
