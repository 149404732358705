import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  return {
    table: {
      borderCollapse: 'separate',
      borderSpacing: 0,

      ['thead tr:first-of-type th:first-of-type']: {
        borderBottom: 'none',
      },

      ['thead tr th']: {
        background: theme.white,
        textAlign: 'center',
      },

      ['thead']: {
        position: 'sticky',
        top: 0,
        zIndex: 3,
      },

      ['thead th:first-of-type']: {
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: 3,
      },

      ['tbody td:first-of-type']: {
        position: 'sticky',
        left: 0,
        background: theme.white,
        zIndex: 2,
      },

      ['thead tr:first-of-type th']: {
        borderLeft: `1px solid ${theme.colors.gray[3]}`,

        [':nth-of-type(2), :first-of-type']: {
          borderLeft: 'none',
        },
      },

      ['thead tr th:first-of-type, tbody tr td:first-of-type']: {
        borderRight: `1px solid ${theme.colors.gray[3]}`,
      },
    },
  }
})
