import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useUser } from '@/features/user/hooks'
import { useFormSubmit } from '@/hooks'
import { FormatUtils } from '@/utils'
import { GeneralFormSection } from './FormSections'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
}

export const EditForm = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { phone, first_name, last_name, ...restUserData } = user || {}

  const defaultValues = {
    ...restUserData,
    first_name: first_name || '',
    last_name: last_name || '',
    phone: FormatUtils.formatPhone(phone) || '',
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const { phone, ...resetValues } = data

      const values = {
        ...resetValues,
        phone: phone ? phone.replace(/[^0-9]/g, '') : '',
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralFormSection />

          <div className={'flex'}>
            <Group>
              <Button loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
