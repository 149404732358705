import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Group, Text, useMantineTheme } from '@mantine/core'
import { Avatar } from '@/components/Elements'
import useStyles from './ReviewContent.styles'

interface IProps {
  data: {
    text: string
    author: {
      avatar: any
      name: string
      job: string
    }
  }
}

export const ReviewContent = ({ data: { text, author } }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const theme = useMantineTheme()

  return (
    <Flex
      px={{ base: 'xl', sm: 50 }}
      py={{ base: 'xl', sm: 90 }}
      justify={'center'}
      direction={'column'}
    >
      <Box mb={'xl'}>
        <Text component={'p'}>{`"${text}"`}</Text>
      </Box>

      <Group>
        <Avatar radius={100} size={'xl'} src={author?.avatar} />

        <Box>
          <Text fz={'lg'} fw={'700'}>
            {author?.name}
          </Text>
          <Text color={theme.primaryColor}>{author?.job}</Text>
        </Box>
      </Group>
    </Flex>
  )
}
