import React from 'react'
import { Grid } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import { DashboardStatisticEntry } from '@/features/statistics/types'
import { EntryCard } from './EntryCard'

interface IProps {
  items: DashboardStatisticEntry[]
}

export const StatisticsContent = ({ items }: IProps) => {
  return (
    <>
      {items.length > 0 ? (
        <Grid align={'stretch'}>
          {items.map((item, index) => (
            <Grid.Col key={index} sm={3}>
              <EntryCard data={item} primary={index % 2 !== 0} />
            </Grid.Col>
          ))}
        </Grid>
      ) : (
        <NoDataTextMessage variant={'data'} />
      )}
    </>
  )
}
