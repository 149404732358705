import React from 'react'
import { ContainerProps, Container as MantineContainer } from '@mantine/core'
import useStyles from './Container.styles'

interface IProps extends ContainerProps {
  children: React.ReactNode
}

export const Container = ({ children, ...props }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <MantineContainer className={cx(classes.root)} {...props}>
      {children}
    </MantineContainer>
  )
}
