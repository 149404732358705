import React from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@mantine/core'
import { GeneralInfo } from './GeneralInfo'
import { Password } from './Password'

export const ProfileContainer = () => {
  const { t } = useTranslation()
  return (
    <>
      <GeneralInfo />
      <Space mb={'xl'} />

      <Password />
    </>
  )
}
