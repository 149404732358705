import React, { useMemo } from 'react'
import { useForecastCore } from '@/features/forecast/hooks/useForecastCore'
import { ForecastProps, ForecastState, UseForecastProps, UseForecastReturn } from './types'
import { useDispatching } from './useDispatching'
import { useForecastEffect } from './useForecastEffect'
import { useForecastForm } from './useForecastForm'
import { useManager } from './useManager'
import { useSession } from './useSession'
import { useStore } from './useStore'
import { getPeriodDates } from './utils'

export const useForecast = ({
  formConfig,
  initialData,
  inputsData,
  state: { loading },
  onSubmit,
  period,
  onFetch,
  session,
  forecastDispatching,
  ...props
}: UseForecastProps): UseForecastReturn => {
  const core = useForecastCore({ formConfig })

  const willInitForm = React.useRef(true)

  const onWillInitFormChange = (status: boolean) => {
    willInitForm.current = status
  }

  const {
    history,
    forecast: {
      actions: { onHandleSubmit },
      state: { isSubmitting, isValid },
    },
  } = core

  const store = useStore({ history })

  const dates = useMemo(() => {
    return getPeriodDates(period)
  }, [period])

  const forecastForm = useForecastForm({ form: core.form, dates })

  const { values } = forecastForm

  const dispatching = useDispatching({
    onHandleSubmit,
    onSubmit,
  })

  const forecastSession = useSession({ session })

  const manager = useManager({
    forecastForm,
    store,
    dispatching,
    onFetch,
    onWillInitFormChange,
    session: forecastSession,
    forecastDispatching,
  })

  useForecastEffect({ initialData, store, forecastForm, dates, willInitForm, onWillInitFormChange })

  const state: ForecastState = {
    isDispatching: isSubmitting,
    isValid,
    isLoading: loading,
  }

  const forecast: ForecastProps = {
    state,
    dispatching,
  }

  return {
    core,
    forecast,
    values,
    dates,
    period,
    actions: manager.actions,
    inputsData,
    session,
    forecastDispatching,
  }
}
