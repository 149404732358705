import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ForecastSectionPickerController } from '@/features/forecast-section/components/FormElements'
import { ForecastSectionModel } from '@/features/forecast-section/types'

interface IProps {
  sectionsData?: ForecastSectionModel[]
}

export const ForecastSectionsSection = ({ sectionsData = [] }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const options = useMemo(() => {
    return sectionsData?.map(({ id, name }) => ({ label: name, value: id.toString(), name }))
  }, [])

  return (
    <>
      <ForecastSectionPickerController control={control} name={'sections'} options={options} />
    </>
  )
}
