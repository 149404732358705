import React from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleGrid, Text, Title } from '@mantine/core'
import icon1 from '@/assets/images/illustrations/features-icon-1.svg'
import icon2 from '@/assets/images/illustrations/features-icon-2.svg'
import icon3 from '@/assets/images/illustrations/features-icon-3.png'
import icon4 from '@/assets/images/illustrations/features-icon-4.png'
import { BasicCard } from '@/features/landing/components/Elements'
import useStyles from './Features.styles'

export const Features = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const data = [
    {
      icon: icon1,
      title: t('landing.features-section.feature-1.title'),
      text: t('landing.features-section.feature-1.text'),
    },
    {
      icon: icon2,
      title: t('landing.features-section.feature-2.title'),
      text: t('landing.features-section.feature-2.text'),
    },
    {
      icon: icon3,
      title: t('landing.features-section.feature-3.title'),
      text: t('landing.features-section.feature-3.text'),
    },
    {
      icon: icon4,
      title: t('landing.features-section.feature-4.title'),
      text: t('landing.features-section.feature-4.text'),
    },
  ]

  return (
    <div>
      <SimpleGrid
        cols={2}
        spacing={38}
        breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
        style={{ marginTop: 50 }}
      >
        {data.map(({ icon, title, text }, i) => (
          <BasicCard key={i} className={'flex-col md:flex-row'}>
            <div className={classes.itemIcon}>
              <img alt={'feature'} src={icon} />
            </div>

            <div>
              <Title fz={28}>{title}</Title>
              <Text component={'p'}>{text}</Text>
            </div>
          </BasicCard>
        ))}
      </SimpleGrid>
    </div>
  )
}
