import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Loader, Table, Text, TypographyStylesProvider } from '@mantine/core'
import { useInputStaticOptions } from '@/features/import/hooks'
import { ImportModel } from '@/features/import/types'
import { DateUtils } from '@/utils'
import useStyles from './Table.styles'

interface IProps {
  data: ImportModel
}

export const ImportDetailsTable = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { active, mode, last_status, finished_at, last_run_at, text } = data

  const { getMode } = useInputStaticOptions()

  return (
    <>
      <div className={'overflow-x-auto'}>
        <Table className={classes.table}>
          <tbody>
            <>
              <tr>
                <th scope="row">{t('status')}:</th>
                <td>
                  <Flex align={'center'}>
                    {active ? (
                      <>
                        {t('active')}
                        <Loader size={'xs'} ml={'sm'} />
                      </>
                    ) : (
                      t('inactive')
                    )}
                  </Flex>
                </td>
              </tr>

              <tr>
                <th scope="row">{t('last_import')}:</th>
                <td>{finished_at ? DateUtils.formatDateTime(finished_at) : '-'}</td>
              </tr>

              <tr>
                <th scope="row">{t('last_run')}:</th>
                <td>{last_run_at ? DateUtils.formatDateTime(last_run_at) : '-'}</td>
              </tr>

              <tr>
                <th scope="row">{t('last_status')}:</th>
                <td>{last_status !== null ? (last_status ? t('success') : t('failed')) : '-'}</td>
              </tr>

              <tr>
                <th scope="row">{t('mode')}:</th>
                <td>{mode ? getMode(mode)?.label : '-'}</td>
              </tr>

              <tr>
                <th scope="row">{t('info')}:</th>
                <td>
                  {text ? (
                    <TypographyStylesProvider sx={(theme) => ({ ul: { paddingLeft: '16px' } })}>
                      <Text fz={'sm'} dangerouslySetInnerHTML={{ __html: text }} />
                    </TypographyStylesProvider>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
            </>
          </tbody>
        </Table>
      </div>
    </>
  )
}
