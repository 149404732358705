import React from 'react'
import { useTranslation } from 'react-i18next'
import { DAILY_TRACKER_TYPE } from '@/features/tracker/consts/daily-tracker'
import { dailyTrackerHelper } from '@/features/tracker/helpers'

export const useDailyTrackerStaticOptions = () => {
  const { t } = useTranslation()

  const getTypes = () => {
    return dailyTrackerHelper.type.getTypes()
  }

  const getType = (value: DAILY_TRACKER_TYPE) => {
    return dailyTrackerHelper.type.getType(value)
  }

  const isDateType = (value: DAILY_TRACKER_TYPE) => {
    return dailyTrackerHelper.type.isDateType(value)
  }

  const isGoalType = (value: DAILY_TRACKER_TYPE) => {
    return dailyTrackerHelper.type.isGoalType(value)
  }

  return {
    getTypes,
    getType,
    isDateType,
    isGoalType,
  }
}
