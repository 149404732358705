import { createStyles } from '@mantine/core'
import {
  LANDING_FOOTER_HEIGHT,
  LANDING_HEADER_HEIGHT,
  LANDING_HEADER_HEIGHT_XS,
} from '@/const/layout'

export default createStyles((theme, _params, getRef) => {
  return {
    main: {
      paddingTop: LANDING_HEADER_HEIGHT,

      [theme.fn.smallerThan('md')]: {
        paddingTop: LANDING_HEADER_HEIGHT_XS,
      },

      minHeight: `calc(100vh - ${LANDING_FOOTER_HEIGHT}px)`,
      flex: 1,
      display: 'flex',

      ['p']: {
        color: theme.colors['dark'][3],
      },
    },

    container: {
      flex: 1,
      display: 'flex',
      minWidth: 0,
      justifyContent: 'center',
      padding: 0,
    },
  }
})
