import { DatesRangeValue } from 'mantine-dates-6'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FORECAST_SESSION_ADJUST_STEP_TYPE } from '@/features/forecast-session/consts/adjust-step'
import { useForecastContext } from '@/features/forecast/contexts'
import { DateUtils } from '@/utils'
import { AdjustForecastContainer as AdjustForecast } from '../../../AdjustForecast'

interface IProps {
  inputId: number
  onClose: () => void
}

export const AdjustForecastContainer = ({ inputId, onClose }: IProps) => {
  const { t } = useTranslation()

  const {
    actions: { cellsUpdateGoals, onAdjustStepSubmit, onGoalsResetSubmit },
    period,
  } = useForecastContext()

  const onSave = async (data: {
    period: DatesRangeValue
    type: FORECAST_SESSION_ADJUST_STEP_TYPE
    percent: number
  }) => {
    const {
      period: [date_start, date_end],
      ...restData
    } = data

    const params = {
      ...restData,
      date_start: DateUtils.formatDateToServerMonthYearFormat(date_start),
      date_end: DateUtils.formatDateToServerMonthYearFormat(date_end),
    }

    const calculatedData = await onAdjustStepSubmit({ input_id: inputId, ...params })

    if (calculatedData.length > 0) {
      cellsUpdateGoals(calculatedData)
    }

    onClose()
  }

  const initialValues = useMemo(() => {
    if (period) {
      return { period: period as DatesRangeValue }
    }

    return {}
  }, [period])

  const onGoalsReset = async (data: { period: DatesRangeValue }) => {
    const {
      period: [date_start, date_end],
    } = data

    const params = {
      date_start: DateUtils.formatDateToServerMonthYearFormat(date_start),
      date_end: DateUtils.formatDateToServerMonthYearFormat(date_end),
    }

    await onGoalsResetSubmit({ input_id: inputId, ...params })

    onClose()
  }

  return (
    <>
      <AdjustForecast
        onCancel={onClose}
        onSave={onSave}
        initialValues={initialValues}
        onGoalsReset={onGoalsReset}
      />
    </>
  )
}
