import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'

interface IProps {
  isActive?: boolean
}

export const ImportStatus = ({ isActive = false }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      {isActive ? (
        <Text fz={'sm'}>
          {t('upload_data')}:{' '}
          <Text component={'span'} fw={'600'}>
            {t('active')}
          </Text>
        </Text>
      ) : null}
    </>
  )
}
