import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { RegisterContainer } from '@/features/auth/components/Register'
import { useQuery } from '@/hooks'

export const Register = () => {
  const { t } = useTranslation()

  const query = useQuery()

  return (
    <>
      <MegaTagTitle title={'registration'} />

      <RegisterContainer initialValues={{ email: query.get('email') || '' }} />
    </>
  )
}
