import React, { createContext, useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { UseForecastReturn } from '@/features/forecast/hooks/useForecast'

const ForecastContext = createContext<UseForecastReturn | null>(null)

interface IProps extends UseForecastReturn {
  children: React.ReactNode
}

const ForecastProvider = ({ children, ...props }: IProps) => {
  const { form } = props.core

  return (
    <>
      <FormProvider {...form}>
        <ForecastContext.Provider value={{ ...props }}>{children}</ForecastContext.Provider>
      </FormProvider>
    </>
  )
}

const useForecastContext = () => useContext(ForecastContext) as UseForecastReturn

export { ForecastProvider, useForecastContext }
