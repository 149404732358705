import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { authLogoutPA } from '@/features/auth/store'
import { importData } from './slice'

function* cleanState() {
  yield put(importData.cleanState())
}

function* watchUserLogout() {
  yield takeEvery(authLogoutPA.success, cleanState)
}

export function* dataSaga() {
  yield all([fork(watchUserLogout)])
}
