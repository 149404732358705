import { Table, flexRender } from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from '@mantine/core'
import { GeneralTable } from '@/components/Elements'

interface IProps {
  table: Table<any>
  tableProps?: TableProps
}

export const TableView = ({ table, tableProps }: IProps) => {
  const { t } = useTranslation()

  return (
    <GeneralTable {...tableProps}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const __headerProps = (header.column.columnDef as any)?.__headerProps

              return (
                <th key={header.id} {...__headerProps} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                const __cellProps = (cell.column.columnDef as any)?.__cellProps

                return (
                  <td key={cell.id} {...__cellProps}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </GeneralTable>
  )
}
