import { createSlice } from '@reduxjs/toolkit'
import { DAILY_TRACKER_TYPE } from '@/features/tracker/consts/daily-tracker'
import { SectionsDailyTrackerModel } from '@/features/tracker/types'

interface IState {
  list: SectionsDailyTrackerModel[]
  loading: boolean
  error: any | null
  meta: { date: string } | null
  isSuccess: boolean
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
  meta: null,
  isSuccess: false,
}

const trackerSectionsDailyTrackerListSlice = createSlice({
  name: 'trackerSectionsDailyTrackerList',
  initialState,
  reducers: {
    getList(state, action) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = [...state.list, ...action.payload.data]
      state.meta = action.payload.meta
      state.isSuccess = true
    },
    updateEntryNote(state, action) {
      const { date, type, value }: { date: string; type: DAILY_TRACKER_TYPE; value: string } =
        action.payload

      state.list = state.list.map((item) => {
        if (item.d === date && item.t === type) {
          return {
            ...item,
            n: value,
          }
        } else {
          return item
        }
      })

      return state
    },
    removeSection(state, action) {
      const items = [...state.list]

      const { id } = action.payload

      state.list = items.map((item) => {
        let sections = [...item.s]

        sections = sections.filter((section) => section?.id !== id)

        return {
          ...item,
          s: sections,
        }
      })
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
      state.isSuccess = false
    },
    cleanState() {
      return initialState
    },
  },
})

export const trackerSectionsDailyTrackerList = trackerSectionsDailyTrackerListSlice.actions

export default trackerSectionsDailyTrackerListSlice.reducer
