import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Grid, Text } from '@mantine/core'
import fb from '@/assets/images/integration-systems/facebook.svg'
import google from '@/assets/images/integration-systems/google-system-row.png'
import klaviyo from '@/assets/images/integration-systems/klaviyo-brand.png'
import shopify from '@/assets/images/integration-systems/shopify.svg'
import useStyles from './IntegrationSystemsSection.styles'

const systems = [
  { logo: shopify },
  { logo: fb },
  { logo: google },
  {
    logo: klaviyo,
  },
]

export const IntegrationSystemsSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Grid align={'center'}>
        <Grid.Col md={3}>
          <Flex justify={{ base: 'center', md: 'flex-start' }}>
            <Text component={'p'} fz={24}>
              {t('integrates_with')}:
            </Text>
          </Flex>
        </Grid.Col>

        <Grid.Col md={9}>
          <Grid align={'center'} gutter="xl">
            {systems.map(({ logo }, i) => (
              <Grid.Col span={6} sm={3} key={i}>
                <img key={i} alt={'logo'} src={logo} />
              </Grid.Col>
            ))}
          </Grid>
        </Grid.Col>
      </Grid>
    </div>
  )
}
