import React from 'react'
import { useTranslation } from 'react-i18next'
import { trackerDailyTrackerNoteUpdatePA } from '@/features/tracker/store'
import { useAsyncDispatch, useNotify } from '@/hooks'

export const useDailyTrackerNoteUpdate = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async (params: any) => {
    try {
      const data = await onAsyncDispatch(trackerDailyTrackerNoteUpdatePA.request, { params })

      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })

      return data
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onSubmit,
    loading,
  }
}
