import React from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralIntegrationSystemCard } from '@/features/integration/components/IntegrationSystem/IntegrationSystems/Cards'
import { INTEGRATION_SYSTEM_PROVIDER } from '@/features/integration/consts/system'
import { useIntegrationSystem } from '@/features/integration/hooks'
import { IntegrationSystem, IntegrationSystemModel } from '@/features/integration/types'
import { usePopup } from '@/hooks'
import { AccountCreate } from './AccountCreate'

interface IProps {
  data: IntegrationSystem
  onAccountUpdate: (provider: INTEGRATION_SYSTEM_PROVIDER, data: IntegrationSystemModel) => void
  onAccountRemoveSuccess: (provider: INTEGRATION_SYSTEM_PROVIDER) => void
  onSync: (id?: number) => void
  syncState: {
    starting: boolean
    disabled: boolean
  }
}

export const KlaviyoProvider = ({
  data,
  onAccountUpdate,
  onAccountRemoveSuccess,
  onSync,
  syncState,
}: IProps) => {
  const { t } = useTranslation()

  const modelData = data?.data

  const { visible, open, close } = usePopup()

  const { isSyncStartAllow } = useIntegrationSystem()

  const onAdd = async () => {
    open()
  }

  const onAddSuccess = (value: IntegrationSystemModel) => {
    close()

    onAccountUpdate(data?.provider, value)

    if (!syncState.disabled && isSyncStartAllow(value?.status)) {
      onSync(value?.id)
    }
  }

  return (
    <>
      <GeneralIntegrationSystemCard
        data={data}
        onAccountAdd={onAdd}
        onSync={onSync}
        syncControlProps={{ loading: syncState.starting, disabled: syncState.disabled }}
        removeAccountControlProps={{
          onSuccess: () => onAccountRemoveSuccess(data.provider),
        }}
        systemExtraData={[
          { label: t('api_key'), value: modelData?.klaviyo?.api_key || '-' },
          {
            label: t('metric_id'),
            value: modelData?.klaviyo?.metric_id || '-',
          },
        ]}
      />

      <AccountCreate visible={visible} onClose={close} onSuccess={onAddSuccess} />
    </>
  )
}
