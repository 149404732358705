import React from 'react'
import { useTranslation } from 'react-i18next'
import useUndo from 'use-undo'
import { HISTORY_INITIAL_STATE } from '../consts'
import { HistoryData, HistorySectionType, UseHistoryProps, UseHistoryReturn } from '../types'

export const useHistory = ({ ...props }: UseHistoryProps): UseHistoryReturn => {
  const { t } = useTranslation()

  const [state, actions] = useUndo(HISTORY_INITIAL_STATE)

  const { present } = state

  const setData = (data: HistoryData) => {
    actions.set({ ...present, data })
  }

  const setSections = (sections: HistorySectionType[]) => {
    actions.set({
      ...present,
      data: {
        ...present.data,
        sections,
      },
    })
  }

  return {
    state,
    actions: {
      ...actions,
      setData,
      setSections,
    },
  }
}
