import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Anchor } from '@mantine/core'
import { Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  TextInputController,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { ErrorAlert } from '@/features/auth/components/Elements'
import { useFormSubmit } from '@/hooks'
import { NormalizeUtils, ValidationsUtils } from '@/utils'

export type FormValues = {
  first_name: string
  last_name: string
  email: string
  phone: string
  password: string
  password_confirmation: string
  agree_with_policy: boolean
}

interface IProps {
  initialValues?: any
  onSubmit: (values: FormValues) => Promise<void>
}

export const RegisterForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      agree_with_policy: false,
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        first_name: Yup.string().required('field.error.required'),
        last_name: Yup.string().required('field.error.required'),
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
        phone: Yup.string()
          .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat)
          .required('field.error.required'),
        password: Yup.string()
          .required('field.error.required')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        password_confirmation: Yup.string()
          .required('field.error.required')
          .oneOf([Yup.ref('password'), null], 'field.error.password.no_match')
          .min(PASSWORD_MIN_LENGTH, 'field.error.password.length'),
        agree_with_policy: Yup.bool().oneOf([true], 'required'),
      })
    ),
  })

  const {
    error: submitError,
    onSubmit: onFormSubmit,
    resetState: submitResetState,
  } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  const watchAgreeWithPolicy = watch('agree_with_policy')

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && <ErrorAlert data={submitError} onReset={submitResetState} />}

        <TextInputController
          control={control}
          name={'first_name'}
          id={'first_name'}
          label={t('first_name')}
          placeholder={t('first_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'last_name'}
          id={'last_name'}
          label={t('last_name')}
          placeholder={t('last_name')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'phone'}
          id={'phone'}
          label={t('phone')}
          placeholder={t('phone')}
          mb={'md'}
          normalize={NormalizeUtils.phone}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <CheckboxFieldController
          control={control}
          name={'agree_with_policy'}
          id={'agree_with_policy'}
          label={
            <>
              <Trans
                i18nKey="register_terms_text"
                values={{ name: t('terms-and-conditions') }}
                components={{
                  link1: (
                    <Anchor
                      component={Link}
                      to={'/terms'}
                      className={'font-bold'}
                      state={{ from: '/register' }}
                    />
                  ),
                }}
              />
            </>
          }
          mb={'md'}
        />

        <Button
          mt={'lg'}
          fullWidth
          type={'submit'}
          disabled={!watchAgreeWithPolicy}
          loading={isSubmitting}
        >
          {t('sign_up')}
        </Button>
      </form>
    </>
  )
}
