import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@mantine/core'
import { Button } from '@/components/Elements'

interface IProps extends ButtonProps {
  onAdd: () => void
  label?: string
}

export const AddAccountControl = ({ onAdd, label, ...props }: IProps) => {
  const { t } = useTranslation()

  label = label || t('add_account')

  return (
    <Button onClick={onAdd} {...props}>
      {label}
    </Button>
  )
}
