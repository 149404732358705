import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Center, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Alert, CTAButton } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'
import { useFormSubmit } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
}

export const SignUpCTAForm = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const theme = useMantineTheme()
  const isSmallerThenSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)

  const methods = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
    control,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }
  const renderSubmitButton = () => (
    <CTAButton loading={isSubmitting} type={'submit'}>
      {t('sign_up')}
    </CTAButton>
  )

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            placeholder={t('enter_your_email')}
            rightSectionWidth={isSmallerThenSm ? undefined : 180}
            rightSection={isSmallerThenSm ? null : renderSubmitButton()}
          />

          {isSmallerThenSm && (
            <Box my={'sm'}>
              <Center>{renderSubmitButton()}</Center>
            </Box>
          )}
        </form>
      </FormProvider>
    </>
  )
}
