import _ from 'lodash'
import { INPUT_TYPE } from '@/features/input/consts/input'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: INPUT_TYPE
}

export const getTypes = () => {
  return [
    {
      label: i18n.t('input.type.default'),
      value: INPUT_TYPE.DEFAULT,
    },
    {
      label: i18n.t('input.type.custom'),
      value: INPUT_TYPE.CUSTOM,
    },
    {
      label: i18n.t('input.type.constant'),
      value: INPUT_TYPE.CONSTANT,
    },
  ]
}

export const getType = (value: INPUT_TYPE | string): OptionType | any => {
  const options = getTypes()
  return _.find(options, { value })
}

export const isCustom = (value: INPUT_TYPE | string): boolean => {
  return value === INPUT_TYPE.CUSTOM
}

export const isConstant = (value: INPUT_TYPE | string): boolean => {
  return value === INPUT_TYPE.CONSTANT
}
