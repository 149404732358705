import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Popover } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { ForecastSectionFormValue } from '@/features/forecast/types'
import { usePopup } from '@/hooks'
import { SectionInputsUpdateContainer } from './SectionInputsUpdateContainer'

interface IProps {
  section: ForecastSectionFormValue
  index: number
}

export const SectionInputsUpdateControl = ({ section, index }: IProps) => {
  const { t } = useTranslation()

  const { visible, close, toggle } = usePopup()

  const onClose = () => {
    close()
  }

  return (
    <>
      <Popover opened={visible} onClose={onClose} withinPortal>
        <Popover.Target>
          <ActionIcon color={'primary'} onClick={toggle}>
            <Icon name={'plus'} size={20} />
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown>
          <SectionInputsUpdateContainer section={section} index={index} onClose={onClose} />
        </Popover.Dropdown>
      </Popover>
    </>
  )
}
