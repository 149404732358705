import React from 'react'
import { Card } from '@/components/Elements'
import useStyles from './BasicCard.styles'

interface IProps {
  children: React.ReactNode
  className?: string
}

export const BasicCard = ({ children, className }: IProps) => {
  const { cx } = useStyles()

  return (
    <Card
      className={cx('flex', {
        [`${className}`]: className,
      })}
      shadow={'sm'}
      radius={'xl'}
      py={{ base: 20, sm: 40, md: 60 }}
      px={{ base: 20, sm: 40, md: 60 }}
    >
      {children}
    </Card>
  )
}
