import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })

  return {
    root: {
      position: 'absolute',
      inset: 'auto 0% 0%',
      borderTop: '100px solid transparent',
      borderRightStyle: 'solid',
      borderRightWidth: '100vw',
      borderRightColor: theme.white,

      [theme.fn.smallerThan('md')]: {
        borderTopWidth: '60px',
      },
    },

    primary: {
      borderRightColor: colors.background,
    },
  }
})
