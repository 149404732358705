import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import {
  FORECAST_INPUT_CELL_GOAL_MAX_VALUE,
  FORECAST_INPUT_CELL_GOAL_MIN_VALUE,
  FORECAST_INPUT_CELL_PERCENTAGE_GOAL_MAX_VALUE,
} from '@/features/forecast/consts/forecast'
import { useForecastContext } from '@/features/forecast/contexts'
import {
  ForecastSectionInputCellFormValue,
  ForecastSectionInputFormValue,
} from '@/features/forecast/types'
import { useInputModel, useInputStaticOptions } from '@/features/input/hooks'
import { InputModel } from '@/features/input/types'
import { NormalizeUtils } from '@/utils'
import { CommonUtils, ConvertUtils } from '@/utils'
import { OriginalInfo } from './OriginalInfo'
import useStyles from './Style.styles'

const { numberToPercentage, percentageToNumber } = ConvertUtils.number

interface IProps {
  sectionIndex: number
  inputIndex: number
  cellIndex: number
  data: ForecastSectionInputCellFormValue
  input: ForecastSectionInputFormValue
  getInputDataById: (id: number) => InputModel | undefined
}

export const GoalsCell = ({
  sectionIndex,
  inputIndex,
  cellIndex,
  data,
  input,
  getInputDataById,
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { input_id } = input
  const inputData = getInputDataById(input_id)

  const { isCustomPercentageInput } = useInputModel()

  const isPercentageFormat = inputData ? isCustomPercentageInput(inputData) : false

  const {
    actions: { onStepCellUpdate, cellsUpdateGoals, setGoal },
  } = useForecastContext()

  const { goal, original_goal, date } = data ?? {}

  const initialValue = useMemo(() => {
    if (!CommonUtils.isNil(goal)) {
      const result = isPercentageFormat ? numberToPercentage(goal) : goal
      return result.toString()
    }

    return ''
  }, [goal, isPercentageFormat])

  const [value, setValue] = useState<string>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onBlur = async () => {
    if (value !== initialValue) {
      let v = value ? +value : FORECAST_INPUT_CELL_GOAL_MIN_VALUE

      if (v > FORECAST_INPUT_CELL_GOAL_MAX_VALUE) {
        v = !isPercentageFormat
          ? FORECAST_INPUT_CELL_GOAL_MAX_VALUE
          : FORECAST_INPUT_CELL_PERCENTAGE_GOAL_MAX_VALUE
      }

      const goalValue = isPercentageFormat ? percentageToNumber(v) : v
      const result = goalValue.toString()

      const inputValue = v.toString()
      setValue(inputValue)

      try {
        const calculatedData = await onStepCellUpdate(result, input_id, date)

        setGoal(result, { sectionIndex, inputIndex, date })

        if (calculatedData.length > 0) {
          cellsUpdateGoals(calculatedData)
        }
      } catch (e: any) {
        setValue(initialValue)
      }
    }
  }

  const symbol = inputData?.symbol

  const { getSymbol, isCurrencySymbol } = useInputStaticOptions()

  const unit = symbol ? getSymbol(symbol)?.unit : null

  const originalGoalFixedDecimalScale = symbol ? isCurrencySymbol(symbol) : false

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    if (isPercentageFormat) {
      value = NormalizeUtils.onlyNumbers(value)
    } else {
      value = NormalizeUtils.onlyDecimal(value, 2)
    }

    setValue(value)
  }

  const { ref, entry } = useIntersection()

  const originalGoalFormat = isPercentageFormat ? numberToPercentage(original_goal) : original_goal

  return (
    <div ref={ref} className={classes.wrapper}>
      {entry?.isIntersecting && (
        <Input
          classNames={classes}
          icon={unit}
          size={'sm'}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          rightSection={
            <OriginalInfo
              value={originalGoalFormat}
              unit={unit}
              fixedDecimalScale={originalGoalFixedDecimalScale}
            />
          }
        />
      )}
    </div>
  )
}
