import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkType } from '../NavLink'

interface IProps {
  onLinkClick: () => void
}

export const HelpLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const links: Array<NavLinkType> = [
    {
      path: '/privacy-policy',
      label: t('privacy_policy'),
      target: '_black',
    },
  ]

  return (
    <>
      {links.map((item) => {
        return <NavLink key={item.path} onClick={onLinkClick} {...item} />
      })}
    </>
  )
}
