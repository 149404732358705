import React, { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useDeepCompareEffect, useFirstMountState } from 'react-use'
import { useFilterForm } from '@/features/filter/hooks'
import { GENERAL_DAILY_TRACKER_FILTERS_INITIAL_VALUES } from '@/features/tracker/consts/daily-tracker'
import { DateUtils } from '@/utils'
import { FilterForm } from './FilterForms'

interface IProps {
  values: {
    date: string | null
  }
  onFiltersChange: (data: any) => void
}

export const Filter = ({ values = { date: null }, onFiltersChange }: IProps) => {
  const isFirstMount = useFirstMountState()

  const defaultValues = useMemo(() => {
    const { date } = values
    return {
      date: date ? DateUtils.monthYearToDate(date) : null,
    }
  }, [values])

  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      ...GENERAL_DAILY_TRACKER_FILTERS_INITIAL_VALUES,
    },
    delay: 500,
  })

  const { reset } = methods

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      reset(defaultValues)
    }
  }, [defaultValues])

  return (
    <FormProvider {...methods}>
      <FilterForm />
    </FormProvider>
  )
}
