import _ from 'lodash'
import { INPUT_FUNCTION_TYPE } from '@/features/input/consts/input'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: INPUT_FUNCTION_TYPE
}

export const getFunctionTypes = () => {
  return [
    {
      label: i18n.t('input.function_type.sum'),
      value: INPUT_FUNCTION_TYPE.SUM,
    },
    {
      label: i18n.t('input.function_type.avg'),
      value: INPUT_FUNCTION_TYPE.AVG,
    },
  ]
}

export const getFunctionType = (value: INPUT_FUNCTION_TYPE | string): OptionType | any => {
  const options = getFunctionTypes()
  return _.find(options, { value })
}
