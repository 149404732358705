import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { SelectController } from '@/components/FormElements'
import { facebookIntegrationAPI } from '@/features/integration/api'
import {
  FacebookIntegrationAdAccount,
  FacebookIntegrationBusinessUser,
} from '@/features/integration/types'
import { useFetch, useFormSubmit } from '@/hooks'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
  onCancel: () => void
  initialValues?: any
}

export const AccountForm = ({ onCancel, initialValues = {}, ...props }: IProps) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      business_id: null,
      ad_id: null,
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
    setValue,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const values = {
        ...data,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const watchBusinessId = watch('business_id')

  const businessAccountsFetch = useFetch()

  const businessData = useMemo(() => {
    if (businessAccountsFetch?.data?.data?.items) {
      const items: FacebookIntegrationBusinessUser[] = businessAccountsFetch?.data?.data?.items
      return items.map(({ business }) => ({
        label: `${business?.name} - ${business.id}`,
        value: business.id,
      }))
    }

    return []
  }, [businessAccountsFetch?.data?.data])

  useEffect(() => {
    businessAccountsFetch.fetch(facebookIntegrationAPI.getBusinessUsers)
  }, [])

  const adAccountsFetch = useFetch()

  const adAccountsData = useMemo(() => {
    if (adAccountsFetch?.data?.data?.items) {
      const items: FacebookIntegrationAdAccount[] = adAccountsFetch?.data?.data?.items
      return items.map(({ id, name }) => ({
        label: `${name} - ${id}`,
        value: id,
      }))
    }

    return []
  }, [adAccountsFetch?.data?.data])

  useEffect(() => {
    if (watchBusinessId) {
      setValue('ad_id', null)

      adAccountsFetch.resetState()

      adAccountsFetch.fetch(facebookIntegrationAPI.getAdAccounts, {
        business_id: watchBusinessId,
      })
    }
  }, [watchBusinessId])

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectController
            control={control}
            name={'business_id'}
            id={'business_id'}
            label={t('business_account')}
            placeholder={t('business_account')}
            mb={'md'}
            data={businessData}
          />

          {watchBusinessId && (
            <SelectController
              control={control}
              name={'ad_id'}
              id={'ad_id'}
              label={t('ad_account')}
              placeholder={t('ad_account')}
              mb={'md'}
              data={adAccountsData}
            />
          )}

          <div className={'mt-2'}>
            <Group position={'right'}>
              <Button variant="outline" color="dark" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </Group>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
