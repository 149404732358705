import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { integrationsAPI } from '@/features/integration/api'
import i18n from '@/i18n'
import { integrationIntegrationSystemsList } from './slice'

function* getList() {
  try {
    const response: AxiosResponse = yield call(integrationsAPI.getIntegrations)

    const { data } = response

    yield put(integrationIntegrationSystemsList.getListSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(integrationIntegrationSystemsList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(integrationIntegrationSystemsList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
