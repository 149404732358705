import axios from 'axios'
import { ForecastResetGoalsDTO } from './dto'

class ForecastResetGoalsAPI {
  resetGoals = async (data?: ForecastResetGoalsDTO) => {
    return axios.post(`api/forecast/reset-goals`, data)
  }
}

export const forecastResetGoalsAPI = new ForecastResetGoalsAPI()
