import React from 'react'
import { OverlayLoading, OverlayLoadingProps } from '../OverlayLoading'
import useStyles from './Style.styles'

// eslint-disable-next-line
interface IProps extends Omit<OverlayLoadingProps, 'visible'> {
  children: React.ReactNode
  isLoading: boolean
  className?: any
}

export const OverlayBlockLoading = ({ className, isLoading, children, ...props }: IProps) => {
  const { cx } = useStyles()

  return (
    <div
      className={cx('relative h-full', {
        [`${className}`]: className,
      })}
    >
      <OverlayLoading {...props} visible={isLoading} />

      {children}
    </div>
  )
}
