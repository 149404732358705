import { createSlice } from '@reduxjs/toolkit'
import { integrationSystemsHelper } from '@/features/integration/helpers'
import { IntegrationSystem, IntegrationSystemModel } from '@/features/integration/types'

const { getIntegrationSystems } = integrationSystemsHelper.general

interface IState {
  list: IntegrationSystem[]
  loading: boolean
  error: any | null
}

const initialState: IState = {
  list: [],
  loading: false,
  error: null,
}

const integrationIntegrationSystemsListSlice = createSlice({
  name: 'integrationIntegrationSystemsList',
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      const { data } = action.payload

      state.loading = false
      state.list = getIntegrationSystems().map((item) => {
        const system = data.find(
          (element: IntegrationSystemModel) => element.type === item.provider
        )
        if (system) {
          return {
            ...item,
            data: system,
          }
        } else {
          return item
        }
      })
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateSystemData(state, action) {
      const { provider, data } = action.payload

      state.list = state.list.map((item) => {
        if (item.provider === provider) {
          return {
            ...item,
            data: {
              ...item.data,
              ...data,
            },
          }
        } else {
          return item
        }
      })
    },
    removeSystemData(state, action) {
      state.list = state.list.map((item) => {
        if (item.provider === action.payload.provider) {
          return {
            ...item,
            data: null,
          }
        } else {
          return item
        }
      })
    },
    removeItem(state, action) {
      state.list = state.list.filter((item) => item.provider !== action.payload.provider)
    },
    cleanState() {
      return initialState
    },
  },
})

export const integrationIntegrationSystemsList = integrationIntegrationSystemsListSlice.actions

export default integrationIntegrationSystemsListSlice.reducer
