import moment from 'moment'

export const getPeriodDates = (period: Date[]): string[] => {
  const [start, end] = period

  const dates: string[] = []

  if (start && end) {
    const startDate = moment(start)
    const endDate = moment(end)

    if (startDate < endDate) {
      const date = startDate.startOf('month')

      while (date < endDate.endOf('month')) {
        dates.push(date.format('YYYY-MM'))
        date.add(1, 'month')
      }
    }
  }

  return dates
}
