import React from 'react'
import {
  BsArrowCounterclockwise,
  BsArrowLeftShort,
  BsArrowRepeat,
  BsArrowReturnLeft,
  BsArrowReturnRight,
  BsArrowRight,
  BsArrowRightShort,
  BsChevronDown,
  BsEye,
  BsFileEarmarkArrowUp,
  BsFileEarmarkText,
  BsGear,
  BsInfoCircle,
  BsPencil,
  BsPerson,
  BsPlus,
  BsSave,
  BsSearch,
  BsX,
} from 'react-icons/bs'
import { TbRestore } from 'react-icons/tb'

export type IconNameType =
  | 'plus'
  | 'search'
  | 'gear'
  | 'chevron-down'
  | 'arrow-return-left'
  | 'arrow-return-right'
  | 'save'
  | 'arrow-counterclockwise'
  | 'x'
  | 'info-circle'
  | 'arrow-repeat'
  | 'arrow-right'
  | 'arrow-right-short'
  | 'arrow-left-short'
  | 'file-earmark-text'
  | 'person'
  | 'eye'
  | 'pencil'
  | 'restore'
  | 'file-earmark-arrow-up'

interface IProps {
  name: IconNameType
  size?: number
  color?: string
  className?: string

  [x: string]: any
}

export const Icon = ({ name, ...props }: IProps) => {
  switch (name) {
    case 'plus':
      return <BsPlus {...props} />

    case 'search':
      return <BsSearch {...props} />

    case 'gear':
      return <BsGear {...props} />

    case 'chevron-down':
      return <BsChevronDown {...props} />

    case 'arrow-return-left':
      return <BsArrowReturnLeft style={{ transform: 'scale(1, -1)' }} {...props} />

    case 'arrow-return-right':
      return <BsArrowReturnRight style={{ transform: 'scale(1, -1)' }} {...props} />

    case 'save':
      return <BsSave {...props} />

    case 'arrow-counterclockwise': {
      return <BsArrowCounterclockwise {...props} />
    }

    case 'x': {
      return <BsX {...props} />
    }

    case 'info-circle': {
      return <BsInfoCircle {...props} />
    }

    case 'arrow-repeat': {
      return <BsArrowRepeat {...props} />
    }

    case 'arrow-right': {
      return <BsArrowRight {...props} />
    }

    case 'arrow-right-short': {
      return <BsArrowRightShort {...props} />
    }

    case 'arrow-left-short': {
      return <BsArrowLeftShort {...props} />
    }

    case 'file-earmark-text': {
      return <BsFileEarmarkText {...props} />
    }

    case 'person': {
      return <BsPerson {...props} />
    }

    case 'eye': {
      return <BsEye {...props} />
    }

    case 'pencil': {
      return <BsPencil {...props} />
    }

    case 'restore': {
      return <TbRestore {...props} />
    }

    case 'file-earmark-arrow-up': {
      return <BsFileEarmarkArrowUp {...props} />
    }

    default:
      return null
  }
}
