import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { TermsContainer } from '@/features/legal/components/Terms'
import useStyles from './Terms.styles'

export const Terms = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <>
      <MegaTagTitle title={'terms-and-conditions'} />

      <div className={classes.wrapper}>
        <div className={classes.block}>
          <TermsContainer />
        </div>
      </div>
    </>
  )
}
