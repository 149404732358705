import { combineReducers } from 'redux'
import dailyTracker from './daily-tracker/reducers'
import forecastSections from './forecast-sections/reducers'
import inputs from './inputs/reducers'
import sectionsDailyTracker from './sections-daily-tracker/reducers'

const trackerReducer = combineReducers({
  dailyTracker,
  inputs,
  sectionsDailyTracker,
  forecastSections,
})

export default trackerReducer
