import axios from 'axios'
import { UpdateClientProfileDTO } from './dto'

class ProfileAPI {
  getProfile = async () => {
    return axios.get('api/profile')
  }

  updateClientProfile = async (data: UpdateClientProfileDTO) => {
    return axios.post('api/profile/client', data)
  }

  updatePassword = async (data: any) => {
    return axios.put('api/profile/password', data)
  }

  deleteProfile = async () => {
    return axios.post('api/profile/delete')
  }
}

export const profileAPI = new ProfileAPI()
