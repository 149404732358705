import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { InputModel } from '@/features/input/types'
import {
  SectionsDailyTrackerInput,
  SectionsDailyTrackerModel,
  SectionsDailyTrackerSection,
} from '@/features/tracker/types'
import useStyles from './Table.styles'

interface IProps {
  components: {
    DateCell: FC<any>
    InputCell: FC<any>
    NoteCell: FC<any>
    SectionHeader: FC<any>
  }
  getInputDataById: (id: number) => InputModel | undefined
  onNoteUpdate: (data: any) => void
  onTrackerFSActiveSuccess: (data: any) => void
}

export const useFSDailyTrackerTableConfig = ({
  components: { DateCell, InputCell, NoteCell, SectionHeader },
  getInputDataById,
  onNoteUpdate,
  onTrackerFSActiveSuccess,
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const getDateConfig = useCallback(
    () => ({
      id: 'date',
      header: t('date'),
      cell: (info: any) => <DateCell data={info.row.original} />,
    }),
    []
  )

  const getNoteConfig = useCallback(
    () => ({
      id: 'note',
      header: t('note'),
      cell: (info: any) => <NoteCell data={info.row.original} onNoteUpdate={onNoteUpdate} />,
      meta: {
        cellClassName: classes.noteCell,
      },
    }),
    [onNoteUpdate]
  )

  const getInputConfig = useCallback(
    (input: SectionsDailyTrackerInput, sectionIndex: number, index: number) => {
      const { id } = input

      const inputModel = getInputDataById(id)

      return {
        id: `input-${id}-${index}`,
        header: inputModel?.name || '-',
        cell: ({ row }: any) => {
          const data = row.original?.s?.[sectionIndex]?.c?.[index]

          return <InputCell data={data} input={inputModel} />
        },
        __headerProps: {
          className: classes.inputHeader,
        },
      }
    },
    [getInputDataById]
  )

  const getSectionConfig = useCallback(
    (section: SectionsDailyTrackerSection, index: number) => {
      const { id, c: cells } = section

      return {
        id: `section-${id}`,
        header: (
          <SectionHeader
            data={section}
            trackerFSActiveControlProps={{ onSuccess: onTrackerFSActiveSuccess }}
          />
        ),
        columns:
          cells.length > 0
            ? cells.map((input, i) => getInputConfig(input, index, i))
            : [
                {
                  id: `inputPlaceholder`,
                  isPlaceholder: true,
                  __headerProps: {
                    className: classes.inputHeader,
                  },
                },
              ],
      }
    },
    [getInputDataById]
  )

  const getColumns = useCallback((data: SectionsDailyTrackerModel[]) => {
    const columns = []

    const item = data[0]

    if (item) {
      columns.push(getDateConfig())

      const { s: sections } = item

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        columns.push(getSectionConfig(section, i))
      }

      columns.push(getNoteConfig())
    } else {
      columns.push({
        id: `blank`,
        isPlaceholder: true,
        __headerProps: {
          className: classes.blankHeader,
        },
      })
    }

    return columns
  }, [])

  return {
    getColumns,
  }
}
