import axios from 'axios'
import { ForgotPasswordDTO, LoginDTO, RegisterDTO, ResetPasswordDTO, VerifyEmailDTO } from './dto'

class AuthAPI {
  login = async (data: LoginDTO) => {
    return axios.post('api/auth/login', data)
  }
  register = async (data: RegisterDTO) => {
    return axios.post('api/auth/register', data)
  }
  logOut = async () => {
    return axios.post('api/auth/logout')
  }
  verifyEmail = async (data: VerifyEmailDTO) => {
    return axios.post('api/auth/email/verification-notification', data)
  }
  forgotPassword = async (data: ForgotPasswordDTO) => {
    return axios.post('api/auth/forgot-password', data)
  }
  resetPassword = async (data: ResetPasswordDTO) => {
    return axios.post('api/auth/reset-password', data)
  }
}

export const authAPI = new AuthAPI()
