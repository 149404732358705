import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import img1 from '@/assets/images/illustrations/core-functionality-1.png'
import img2 from '@/assets/images/illustrations/core-functionality-2.png'
import img3 from '@/assets/images/illustrations/core-functionality-3.png'
import { Icon } from '@/components/Elements'
import { Content } from './Content'
import { Media } from './Medias'
import { Tabs } from './Tabs'

const TABS_KEY = {
  METRICS: 'metrics',
  FORECAST: 'forecast',
  GOALS: 'goals',
}

const { METRICS, GOALS, FORECAST } = TABS_KEY

export const ContentSection = () => {
  const { t } = useTranslation()

  const TABS_CONFIG = {
    [METRICS]: {
      label: t('landing.product-section.metrics.label'),
      value: METRICS,
      icon: 'person',
      title: t('landing.product-section.metrics.title'),
      text: t('landing.product-section.metrics.text'),
      renderMedia: () => <Media img={img1} />,
    },
    [FORECAST]: {
      label: t('landing.product-section.forecast.label'),
      value: FORECAST,
      icon: 'file-earmark-text',
      title: t('landing.product-section.forecast.title'),
      text: t('landing.product-section.forecast.text'),
      renderMedia: () => <Media img={img2} />,
    },
    [GOALS]: {
      label: t('landing.product-section.goals.label'),
      value: GOALS,
      icon: 'gear',
      title: t('landing.product-section.goals.title'),
      text: t('landing.product-section.goals.text'),
      renderMedia: () => <Media img={img3} />,
    },
  }

  const [tab, setTab] = useState(TABS_CONFIG[METRICS].value)

  const tabsConfigValues = Object.values(TABS_CONFIG)

  const tabs = useMemo(() => {
    return tabsConfigValues.map(({ label, value, icon, ...data }) => ({
      label,
      value,
      icon: <Icon name={icon as any} />,
      render: () => <Content data={data} />,
    }))
  }, [])

  return (
    <div>
      <Tabs value={tab} onChange={setTab} tabs={tabs} />
    </div>
  )
}
