import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextAreaController, TextInputController } from '@/components/FormElements'

export const BaseSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <Grid>
        <Grid.Col>
          <TextInputController
            control={control}
            name={'name'}
            id={'title'}
            label={t('title')}
            placeholder={t('title')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col>
          <TextAreaController
            control={control}
            name={'description'}
            id={'description'}
            label={t('description')}
            placeholder={t('description')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
