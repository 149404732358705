import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    contentWrapper: {
      position: 'relative',
      zIndex: 1,
    },

    content: {
      display: 'flex',
      flexDirection: 'column',

      [theme.fn.smallerThan('md')]: {
        alignItems: 'center',
      },
    },

    title: {
      [theme.fn.smallerThan('md')]: {
        textAlign: 'center',
      },
    },
  }
})
