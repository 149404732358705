import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Center, Flex, Image } from '@mantine/core'
import useStyles from './Header.styles'

interface IProps {
  avatar: string | null
}

export const Header = ({ avatar }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Flex justify={'center'} pb={'lg'}>
      <Flex align={'center'} justify={'center'}>
        <Image src={avatar} fit={'contain'} height={150} maw={300} />
      </Flex>
    </Flex>
  )
}
