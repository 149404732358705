import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@/components/Elements'
import { useForecastContext } from '@/features/forecast/contexts'

interface IProps {
  error: any
}

export const ErrorInfo = ({ error }: IProps) => {
  const { t } = useTranslation()

  const {
    core: {
      form: {
        formState: { errors },
      },
    },
  } = useForecastContext()

  return (
    <>
      {error && (
        <Alert type={'error'} mb={'md'}>
          {error?.message}
        </Alert>
      )}

      <ErrorMessage
        errors={errors}
        name="sections"
        render={({ message }) => (
          <>
            {message && (
              <Alert type={'error'} mb={'md'}>
                {t(message)}
              </Alert>
            )}
          </>
        )}
      />
    </>
  )
}
