import axios from 'axios'
import {
  DefaultForecastSessionDTO,
  StepForecastSessionStepDTO,
} from '@/features/forecast-session/api/dto'

class ForecastSessionStepAPI {
  stepForecastSession = async (data: StepForecastSessionStepDTO) => {
    return axios.post(`api/forecast-session/step`, data)
  }

  stepUndoForecastSession = async (data: DefaultForecastSessionDTO) => {
    return axios.post(`api/forecast-session/step/undo`, data)
  }

  stepRedoForecastSession = async (data: DefaultForecastSessionDTO) => {
    return axios.post(`api/forecast-session/step/redo`, data)
  }
}

export const forecastSessionStepAPI = new ForecastSessionStepAPI()
