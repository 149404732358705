import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'
import { StatisticsContainer } from '@/features/dashboard/components/Statistics'

export const Dashboard = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'dashboard'} />

      <PageContent>
        <StatisticsContainer />
      </PageContent>
    </>
  )
}
