import React from 'react'
import { forecastSessionHelper } from '@/features/forecast-session/helpers'

export const useForecastSessionStaticOptions = () => {
  const getAdjustStepTypes = forecastSessionHelper.type.getTypes

  const getAdjustStepType = forecastSessionHelper.type.getType

  const isMoMAdjustStepType = forecastSessionHelper.type.isMoM

  return {
    getAdjustStepTypes,
    getAdjustStepType,
    isMoMAdjustStepType,
  }
}
