import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container, Group, Footer as MantineFooter } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { APP_NAME } from '@/const/core'
import { LANDING_FOOTER_HEIGHT } from '@/const/layout'
import useStyles from './Footer.styles'

export const Footer = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <>
      <MantineFooter className={classes.root} height={LANDING_FOOTER_HEIGHT} zIndex={1}>
        <Container className={classes.wrap} size={'xl'}>
          <Group className={'flex-nowrap grow'}>
            <div className={'flex grow'}>
              © {new Date().getFullYear()} {APP_NAME}. {t('all_rights_reserved')}.
            </div>

            <div>
              <Anchor component={Link} to={'/privacy-policy'}>
                {t('privacy_policy')}
              </Anchor>
            </div>
          </Group>
        </Container>
      </MantineFooter>
    </>
  )
}
