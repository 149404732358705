import React from 'react'
import { Text } from '@mantine/core'
import { Card, GeneralNumericFormat, PercentageFormat } from '@/components/Elements'
import { useInputModel, useInputStaticOptions } from '@/features/input/hooks'
import { DashboardStatisticEntry } from '@/features/statistics/types'
import useStyles from './EntryCard.styles'

interface IProps {
  data: DashboardStatisticEntry
  primary?: boolean
}

export const EntryCard = ({ data, primary = true }: IProps) => {
  const { cx, classes } = useStyles()

  const { value, input } = data

  const { getSymbol, isCurrencySymbol } = useInputStaticOptions()

  const { isCustomPercentageInput } = useInputModel()

  const { symbol, name } = input ?? {}

  const unit = symbol ? getSymbol(symbol)?.unit : null

  const fixedDecimalScale = symbol ? isCurrencySymbol(symbol) : false

  const isPercentageFormat = input ? isCustomPercentageInput(input) : false

  return (
    <Card
      className={cx('h-full', {
        [`${classes.primary}`]: primary,
        [`${classes.secondary}`]: !primary,
      })}
      shadow={'xs'}
      p={'xl'}
    >
      <Text c="dimmed" fz={'sm'} fw={600} mb={'xs'}>
        {name}
      </Text>

      <Text truncate fz={24} fw={600}>
        {unit && `${unit} `}
        {isPercentageFormat ? (
          <PercentageFormat value={value} />
        ) : (
          <GeneralNumericFormat value={value} fixedDecimalScale={fixedDecimalScale} />
        )}
      </Text>
    </Card>
  )
}
