import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Table, Text, TypographyStylesProvider } from '@mantine/core'
import { useIntegrationStaticOptions } from '@/features/integration/hooks'
import { IntegrationSystemModel } from '@/features/integration/types'
import { DateUtils } from '@/utils'
import useStyles from './Table.styles'

interface IProps {
  data: IntegrationSystemModel
  extraData?: { label: any; value: any }[]
}

export const InfoBlock = ({ data, extraData = [] }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { status, finished_at, last_run_at, text } = data

  const { getStatus } = useIntegrationStaticOptions()

  return (
    <Box mt={'xl'}>
      <div className={'overflow-x-auto'}>
        <Table className={classes.table}>
          <tbody>
            <>
              <tr>
                <th scope="row">{t('status')}:</th>
                <td>{getStatus(status)?.label || '-'}</td>
              </tr>

              <tr>
                <th scope="row">{t('last_sync')}:</th>
                <td>{finished_at ? DateUtils.formatDateTime(finished_at) : '-'}</td>
              </tr>

              <tr>
                <th scope="row">{t('last_run')}:</th>
                <td>{last_run_at ? DateUtils.formatDateTime(last_run_at) : '-'}</td>
              </tr>

              <tr>
                <th scope="row">{t('info')}:</th>
                <td>
                  {text ? (
                    <TypographyStylesProvider sx={(theme) => ({ ul: { paddingLeft: '16px' } })}>
                      <Text fz={'sm'} dangerouslySetInnerHTML={{ __html: text }} />
                    </TypographyStylesProvider>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>

              {!!extraData?.length &&
                extraData.map(({ label, value }, i) => (
                  <tr key={i}>
                    <th scope="row">{label}:</th>
                    <td>{value}</td>
                  </tr>
                ))}
            </>
          </tbody>
        </Table>
      </div>
    </Box>
  )
}
