import { getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'
import { useForecastContext } from '@/features/forecast/contexts'
import { useForecastColumns } from '@/features/forecast/hooks'
import { ForecastSectionFormValue } from '@/features/forecast/types'
import { InputModel } from '@/features/input/types'
import { ActualCell, GoalsCell, InputCell, SectionCell } from './Cell'
import { DateHeader } from './Headers'
import useStyles from './Table.styles'
import { TableView } from './TableView'

interface IProps {
  data: ForecastSectionFormValue[]
  dates: string[]
  getInputDataById: (id: number) => InputModel | undefined
}

export const Table = ({ data, dates, getInputDataById }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const [columns, setColumns] = useState<any[]>([])

  const { getColumns } = useForecastColumns({
    components: {
      ActualCell,
      GoalsCell,
      InputCell,
      SectionCell,
      DateHeader,
    },
    getInputDataById,
  })

  useDeepCompareEffect(() => {
    setColumns(getColumns(dates))
  }, [dates])

  const {
    core: {
      table: { expanded, onExpandedChange },
    },
  } = useForecastContext()

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: onExpandedChange,
    getSubRows: (row: any) => row.inputs,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  useEffect(() => {
    table.toggleAllRowsExpanded(true)
  }, [])

  return (
    <TableView table={table} tableProps={{ className: classes.table, withColumnBorders: false }} />
  )
}
