import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { trackerForecastSectionsList } from '@/features/tracker/store'
import { useAppSelector } from '@/store'

export const useTrackerForecastSectionsList = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { list, ...state } = useAppSelector((state) => state.tracker.forecastSections.list)

  const onFetch = (params: any = {}) => {
    params = {
      pagination: false,
      ...params,
    }

    dispatch(trackerForecastSectionsList.getList({ params }))
  }

  const onClean = () => {
    dispatch(trackerForecastSectionsList.cleanState())
  }

  const massUpdateTrackerForecastSectionsActiveStatus = (active: string[] | number[]) => {
    dispatch(trackerForecastSectionsList.updateTrackerFSActiveStatus(active))
  }

  const updateTrackerForecastSection = (data: { id: number; active_tracker_section: boolean }) => {
    dispatch(trackerForecastSectionsList.updateListItem(data))
  }

  return {
    list,
    ...state,
    onFetch,
    onClean,
    massUpdateTrackerForecastSectionsActiveStatus,
    updateTrackerForecastSection,
  }
}
