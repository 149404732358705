import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import useStyles from './TitleSection.styles'

export const TitleSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Title size={40} color={'white'}>
        {t('landing.cta-v2-section.title')}
      </Title>
    </div>
  )
}
