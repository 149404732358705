import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PrivacyPolicyContainer } from '@/features/legal/components/PrivacyPolicy'
import useStyles from './PrivacyPolicy.styles'

export const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <>
      <MegaTagTitle title={'privacy_policy'} />

      <div className={classes.wrapper}>
        <div className={classes.block}>
          <PrivacyPolicyContainer />
        </div>
      </div>
    </>
  )
}
