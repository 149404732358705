import { createSlice } from '@reduxjs/toolkit'
import { DashboardStatisticEntry } from '@/features/statistics/types'

interface IState {
  data: {
    items: DashboardStatisticEntry[]
  } | null
  loading: boolean
  error: any | null
}

const initialState: IState = {
  data: null,
  loading: false,
  error: null,
}

const statisticsDashboardEntriesSlice = createSlice({
  name: 'statisticsDashboardEntries',
  initialState,
  reducers: {
    getData(state, action) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const statisticsDashboardEntries = statisticsDashboardEntriesSlice.actions

export default statisticsDashboardEntriesSlice.reducer
