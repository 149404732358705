import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },

    block: {
      maxWidth: 590,
      display: 'flex',
      flexDirection: 'column',
    },
  }
})
