import _ from 'lodash'
import { DAILY_TRACKER_TYPE } from '@/features/tracker/consts/daily-tracker'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: DAILY_TRACKER_TYPE
}

export const getTypes = () => {
  return [
    {
      label: i18n.t('date'),
      value: DAILY_TRACKER_TYPE.DATE,
    },
    {
      label: i18n.t('goal'),
      value: DAILY_TRACKER_TYPE.GOAL,
    },
    {
      label: i18n.t('actual'),
      value: DAILY_TRACKER_TYPE.ACTUAL,
    },
  ]
}

export const getType = (value: DAILY_TRACKER_TYPE | string): OptionType | any => {
  const options = getTypes()
  return _.find(options, { value })
}

export const isDateType = (value: DAILY_TRACKER_TYPE | string): boolean => {
  return value === DAILY_TRACKER_TYPE.DATE
}

export const isGoalType = (value: DAILY_TRACKER_TYPE | string): boolean => {
  return value === DAILY_TRACKER_TYPE.GOAL
}
