import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  BsBarChart,
  BsCashStack,
  BsDiagram3,
  BsFileBarGraph,
  BsFileEarmarkRuled,
  BsStars,
} from 'react-icons/bs'
import { NavLink, NavLinkType } from '../NavLink'

interface IProps {
  onLinkClick: () => void
}

export const GeneralLinks = ({ onLinkClick }: IProps) => {
  const { t } = useTranslation()

  const links: Array<NavLinkType> = [
    {
      path: '/dashboard',
      label: t('dashboard'),
      icon: BsBarChart,
    },
    {
      path: '/forecast',
      label: t('forecast'),
      icon: BsStars,
    },
    {
      path: '/tracker',
      label: t('daily_tracker'),
      icon: BsFileBarGraph,
    },
    {
      path: '/inputs',
      label: t('inputs'),
      icon: BsFileEarmarkRuled,
    },
    {
      path: '/billing',
      label: t('billing'),
      icon: BsCashStack,
    },
    {
      path: '/integrations',
      label: t('integrations'),
      icon: BsDiagram3,
    },
  ]

  return (
    <>
      {links.map((item) => {
        return <NavLink key={item.path} onClick={onLinkClick} {...item} />
      })}
    </>
  )
}
