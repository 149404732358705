import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { Container, MantineProvider } from '@mantine/core'
import { Footer } from './Footer'
import { Header } from './Header'
import useStyles from './LandingLayout.styles'

export const LandingLayout = () => {
  const { classes } = useStyles()

  return (
    <MantineProvider
      inherit
      theme={{
        components: {
          Button: {
            defaultProps: {
              size: 'lg',
              color: 'secondary',
              radius: 'xl',
            },
          },
          TextInput: {
            defaultProps: { size: 'xl', radius: 'xl' },
          },
        },
      }}
    >
      <div>
        <Header />

        <main className={classes.main}>
          <Container fluid className={classes.container}>
            <Outlet />
          </Container>
        </main>

        <Footer />
      </div>
    </MantineProvider>
  )
}
