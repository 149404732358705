import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { MonthPicker } from './MonthPicker'

export const ForecastFilterForm = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Group align={'flex-start'}>
      <Controller
        name={'period'}
        control={control}
        render={({ field: { ref, ...field }, fieldState, formState }) => {
          const meta = {
            error: fieldState.error,
          }

          return <MonthPicker {...field} meta={meta} />
        }}
      />
    </Group>
  )
}
