import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { importAPI } from '@/features/import/api'
import { StartImport } from '@/features/import/api/dto'
import { FormTypes } from '@/types'
import { importData } from '../data/slice'
import { IMPORT_START_FAILED, IMPORT_START_REQUEST, IMPORT_START_SUCCESS } from './actionTypes'

type ArgsType = {
  params: StartImport
}

export const importStartPA = createPromiseAction(
  IMPORT_START_REQUEST,
  IMPORT_START_SUCCESS,
  IMPORT_START_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* start(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(importAPI.startImport, params)
    const { data } = response

    yield put(importStartPA.success({}))

    yield put(importData.getDataSuccess({ data: data?.data }))

    resolvePromiseAction(action, { ...data?.data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* startSaga() {
  yield takeEvery(importStartPA.request, start)
}
