import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { INPUT_TYPE } from '@/features/input/consts/input'
import { InputCreateContainer } from './InputCreateContainer'

interface IProps {
  type: INPUT_TYPE
  visible: boolean
  onClose: () => void
  onSuccess: () => void
}

export const InputCreate = ({ type, visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const title = type === INPUT_TYPE.CUSTOM ? t('add_custom_formula') : t('add_value')

  return (
    <>
      <Modal size={'xl'} opened={visible} onClose={onClose} title={title}>
        <InputCreateContainer type={type} onClose={onClose} onSuccess={onSuccess} />
      </Modal>
    </>
  )
}
