import * as Yup from 'yup'
import { FORECAST_SESSION_ADJUST_STEP_TYPE } from '@/features/forecast-session/consts/adjust-step'
import { forecastSessionHelper } from '@/features/forecast-session/helpers'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object().shape({
  type: Yup.mixed()
    .nullable()
    .when('$isResetMode', (isResetMode, schema) =>
      isResetMode ? schema : schema.required('field.error.required')
    ),
  percent: Yup.number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when(['$isResetMode'], {
      is: (isResetMode: boolean) => !isResetMode,
      then: (schema) => schema.required('field.error.required'),
      otherwise: (schema) => schema,
    }),
  period: Yup.array().test('period', 'field.error.invalid', ValidationsUtils.datePeriod),
})
