import moment from 'moment'
import React, { useMemo, useState } from 'react'

type Filters = {
  period: Date[]
}

export const useForecastFilters = () => {
  const defaultFilters = useMemo(() => {
    const startDate = moment().subtract(3, 'month').startOf('month').toDate()
    const endDate = moment().startOf('month').toDate()

    return {
      period: [startDate, endDate],
    }
  }, [])

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const onFiltersChange = (values: Filters) => {
    setFilters((prevState) => ({ ...prevState, ...values }))
  }

  return {
    filters,
    onFiltersChange,
  }
}
