import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const colors = theme.fn.variant({ variant: 'light', color: theme.primaryColor })

  return {
    root: {},

    primary: {
      borderTopColor: colors.background,
    },
  }
})
