import axios from 'axios'
import { MassUpdateTrackerInputDTO } from './dto'

class TrackerInputsAPI {
  massUpdate = async (data: MassUpdateTrackerInputDTO) => {
    return axios.post(`api/tracker-inputs`, data)
  }
}

export const trackerInputsAPI = new TrackerInputsAPI()
