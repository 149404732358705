import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { shopifyIntegrationAPI } from '@/features/integration/api'
import { ShopifyIntegrationSetupDTO } from '@/features/integration/api/dto'
import { FormTypes } from '@/types'
import {
  INTEGRATION_SHOPIFY_SETUP_FAILED,
  INTEGRATION_SHOPIFY_SETUP_REQUEST,
  INTEGRATION_SHOPIFY_SETUP_SUCCESS,
} from './actionTypes'

type ArgsType = {
  params: ShopifyIntegrationSetupDTO
}

export const integrationShopifySetupPA = createPromiseAction(
  INTEGRATION_SHOPIFY_SETUP_REQUEST,
  INTEGRATION_SHOPIFY_SETUP_SUCCESS,
  INTEGRATION_SHOPIFY_SETUP_FAILED
)<ArgsType, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* setup(action: PromiseAction<string, ArgsType, any>) {
  try {
    const { params } = action.payload

    const response: AxiosResponse = yield call(
      shopifyIntegrationAPI.setupShopifyIntegration,
      params
    )
    const { data } = response

    yield put(integrationShopifySetupPA.success({}))

    resolvePromiseAction(action, { ...data?.data })
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* setupSaga() {
  yield takeEvery(integrationShopifySetupPA.request, setup)
}
