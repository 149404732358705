import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { Card } from '@/components/Elements'
import useStyles from './MediaCard.styles'

interface IProps {
  data: {
    icon: any
    renderContent: () => any
  }
}

export const MediaCard = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const { icon, renderContent } = data

  return (
    <Card p={0} shadow={'xs'} radius={'xl'} className={classes.card}>
      <Grid gutter={0} className={'h-full'}>
        <Grid.Col sm={8} order={2} orderSm={1}>
          <div className={classes.inner}>{renderContent()}</div>
        </Grid.Col>
        <Grid.Col sm={4} order={1} orderSm={2}>
          <div className={cx(classes.inner, classes.mediaBlock)}>
            <img alt={'media'} className={classes.img} src={icon} />
          </div>
        </Grid.Col>
      </Grid>
    </Card>
  )
}
