import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateUtils } from '@/utils'

interface IProps {
  value: string
}

export const DateHeader = ({ value }: IProps) => {
  const { t } = useTranslation()

  const formatted = DateUtils.formatServerMonthYear(value)

  return <>{formatted || '-'}</>
}
