import axios from 'axios'
import { UpdateForecastDTO } from './dto'

class ForecastAPI {
  getForecast = async (params: any = {}) => {
    return axios.get(`api/forecast`, {
      params,
    })
  }

  updateForecast = async (data: UpdateForecastDTO) => {
    return axios.post(`api/forecast`, data)
  }
}

export const forecastAPI = new ForecastAPI()
