import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@mantine/core'
import { useIntegrationSystem } from '@/features/integration/hooks'
import { IntegrationSystemModel } from '@/features/integration/types'
import { RemoveAccountControl } from '../Controls'

interface IProps {
  data: IntegrationSystemModel
  removeAccountControlProps?: any
}

export const BottomBar = ({ data, removeAccountControlProps }: IProps) => {
  const { t } = useTranslation()

  const { isRemoveAllow } = useIntegrationSystem()

  return (
    <Flex justify={'center'}>
      <RemoveAccountControl
        id={data?.id}
        disabled={data && !isRemoveAllow(data?.status)}
        {...removeAccountControlProps}
      />
    </Flex>
  )
}
