import React from 'react'
import dots from '@/assets/images/illustrations/bg-white-dots.svg'
import img1 from '@/assets/images/illustrations/monthly-visitors.svg'
import img2 from '@/assets/images/illustrations/top-countries.svg'
import useStyles from './MediaSection.styles'

export const MediaSection = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <img alt={'media'} className={classes.dots1} src={dots} />
      <img alt={'media'} className={classes.dots2} src={dots} />
      <img alt={'media'} className={classes.img1} src={img1} />
      <img alt={'media'} className={classes.img2} src={img2} />
    </div>
  )
}
