import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {
      position: 'relative',
      width: '100%',
      maxWidth: '817px',
      marginRight: '-262px',
      paddingBottom: '47px',
      paddingLeft: '62px',

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },

      [theme.fn.smallerThan('xs')]: {
        paddingBottom: '33px',
        paddingLeft: '43px',
      },
    },

    imgBase: {
      position: 'relative',
      zIndex: 1,
      maxWidth: '100%',
      borderRadius: theme.radius.xl,
      boxShadow: `0 20px 87px 0 ${theme.colors[theme.primaryColor][1]}`,
    },

    dots: {
      position: 'absolute',

      [theme.fn.smallerThan('xs')]: {
        width: '24%',
      },
    },

    dotsTop: {
      top: '-57px',
      right: '215px',

      [theme.fn.smallerThan('md')]: {
        top: '-36px',
      },

      [theme.fn.smallerThan('xs')]: {
        left: '89px',
        top: '-23px',
        right: 'auto',
      },
    },

    dotsLeft: {
      top: '77px',
      left: 0,

      [theme.fn.smallerThan('xs')]: {
        top: '47px',
      },
    },

    dotsBottom: {
      left: '244px',
      bottom: '-32px',

      [theme.fn.smallerThan('md')]: {
        left: 'auto',
        right: '26px',
        bottom: '-9px',
      },

      [theme.fn.smallerThan('xs')]: {
        right: '20px',
        bottom: '6px',
      },
    },
  }
})
