import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { INPUT_TYPE } from '@/features/input/consts/input'
import { useInputModel } from '@/features/input/hooks'
import { inputEditSubmitPA } from '@/features/input/store'
import { useNotify } from '@/hooks'
import { useAppSelector } from '@/store'
import { CommonUtils, ConvertUtils } from '@/utils'
import { InputForm } from '../../InputForm'

const { numberToPercentage } = ConvertUtils.number

interface IProps {
  onClose: () => void
  onSuccess: (data: any) => void
}

export const InputEditContainer = ({ onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const { data: input } = useAppSelector((state) => state.input.edit.data)

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const data = await dispatch(inputEditSubmitPA.request({ id: input?.id as number, params }))

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  const { isCustomPercentageInput } = useInputModel()

  const initialValues = useMemo(() => {
    let result = {}

    if (input) {
      const { type, type_custom, type_constant, ...restValues } = input

      result = {
        type,
        ...restValues,
      }

      if (type === INPUT_TYPE.CUSTOM && type_custom) {
        const { action, input_a, input_b } = type_custom
        result = {
          ...result,
          action,
          input_a_id: input_a?.id || null,
          input_b_id: input_b?.id || null,
        }
      } else if (type === INPUT_TYPE.CONSTANT && type_constant) {
        const isPercentageFormat = isCustomPercentageInput(input)

        const { value, period } = type_constant

        let valueResult = ''
        if (!CommonUtils.isNil(value)) {
          const result = isPercentageFormat ? numberToPercentage(value) : value
          valueResult = result.toString()
        }

        result = {
          ...result,
          period,
          value: valueResult,
        }
      }
    }
    return result
  }, [input])

  const type = input?.type || INPUT_TYPE.CUSTOM

  return (
    <>
      <InputForm
        type={type}
        onSubmit={onSubmit}
        onCancel={onClose}
        initialValues={initialValues}
        isEdit
      />
    </>
  )
}
