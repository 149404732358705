import _ from 'lodash'
import { INPUT_PERIOD } from '@/features/input/consts/input'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: INPUT_PERIOD
}

export const getPeriods = () => {
  return [
    {
      label: i18n.t('day'),
      value: INPUT_PERIOD.DAY,
    },
    {
      label: i18n.t('month'),
      value: INPUT_PERIOD.MONTH,
    },
  ]
}

export const getPeriod = (value: INPUT_PERIOD | string): OptionType | any => {
  const options = getPeriods()
  return _.find(options, { value })
}
