import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Menu } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { InputCreate } from '@/features/input/components/Input'
import { INPUT_TYPE } from '@/features/input/consts/input'
import { usePopup } from '@/hooks'

interface IProps {
  onSuccess: () => void
}

export const InputCreateControl = ({ onSuccess }: IProps) => {
  const { t } = useTranslation()

  const { data, visible, open, close } = usePopup()

  const onSelect = (type: INPUT_TYPE) => {
    open(type)
  }

  const type = data || INPUT_TYPE.CONSTANT

  const onClose = () => {
    close({ reset: false })
  }

  const onSubmitSuccess = () => {
    close({ reset: false })

    onSuccess()
  }

  return (
    <>
      <Menu>
        <Menu.Target>
          <Button leftIcon={<Icon name={'plus'} />} variant={'outline'}>
            {t('add_custom_item')}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => onSelect(INPUT_TYPE.CUSTOM)}>
            {t('add_custom_formula')}
          </Menu.Item>

          <Menu.Item onClick={() => onSelect(INPUT_TYPE.CONSTANT)}>{t('add_value')}</Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <InputCreate type={type} visible={visible} onClose={onClose} onSuccess={onSubmitSuccess} />
    </>
  )
}
