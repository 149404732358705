import axios from 'axios'
import { UpdateForecastSectionTrackerDTO } from './dto'

class ForecastSectionAPI {
  updateForecastSectionTracker = async (id: number, data: UpdateForecastSectionTrackerDTO) => {
    return axios.post(`api/forecast-sections/${id}/tracker`, data)
  }
}

export const forecastSectionAPI = new ForecastSectionAPI()
