import { createSelector } from '@reduxjs/toolkit'
import { ForecastSectionModel } from '@/features/forecast-section/types'
import { RootState } from '@/store'

const getSectionsList = (state: RootState) => state.tracker.forecastSections.list.list

export const selectTrackerFSListActiveTrackerFSData = createSelector([getSectionsList], (list) => {
  const items: ForecastSectionModel[] = []
  const ids: number[] = []

  list.forEach((item) => {
    if (item?.active_tracker_section) {
      items.push(item)
      ids.push(item?.id)
    }
  })

  return {
    items,
    ids,
  }
})
