import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { forecastInputsList } from '@/features/forecast/store'
import { InputModel } from '@/features/input/types'
import { useAppSelector } from '@/store'

export const useInputsList = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { list, ...state } = useAppSelector((state) => state.forecast.inputs.list)

  const inputsDataMap = useMemo(() => {
    return list.reduce((acc: any, curr) => {
      const { id } = curr

      return acc.set(id, curr)
    }, new Map())
  }, [list])

  const onFetch = (params: any = {}) => {
    params = {
      pagination: false,
      sort: [{ id: 'created_at', desc: true }],
      ...params,
    }

    dispatch(forecastInputsList.getList({ params }))
  }

  const onClean = () => {
    dispatch(forecastInputsList.cleanState())
  }

  const getInputDataById = (id: number): InputModel | undefined => {
    return inputsDataMap.get(id)
  }

  return {
    list,
    ...state,
    onFetch,
    onClean,
    getInputDataById,
  }
}
