import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { inputsAPI } from '@/features/input/api'
import i18n from '@/i18n'
import { RequestUtils } from '@/utils'
import { forecastInputsList } from './slice'

function* getList({ payload: { params } }: PayloadAction<{ params: any }>) {
  try {
    let requestParams: any = {
      includes: [],
      short: true,
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response: AxiosResponse = yield call(inputsAPI.getInputs, requestParams)

    const { data } = response

    yield put(forecastInputsList.getListSuccess(data))
  } catch (error: any) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(forecastInputsList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(forecastInputsList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
