import axios from 'axios'
import { MassUpdateTrackerForecastSectionDTO } from './dto'

class TrackerForecastSectionsAPI {
  massUpdate = async (data: MassUpdateTrackerForecastSectionDTO) => {
    return axios.post(`api/tracker-forecast-sections`, data)
  }
}

export const trackerForecastSectionsAPI = new TrackerForecastSectionsAPI()
