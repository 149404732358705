import { DatesRangeValue } from 'mantine-dates-6'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FORECAST_SESSION_ADJUST_STEP_TYPE } from '@/features/forecast-session/consts/adjust-step'
import { AdjustForecastForm } from '../AdjustForecastForm'

interface IProps {
  onCancel: () => void
  onSave: (data: {
    period: DatesRangeValue
    type: FORECAST_SESSION_ADJUST_STEP_TYPE
    percent: number
  }) => Promise<void>
  initialValues?: {
    period?: DatesRangeValue
  }
  onGoalsReset: (data: { period: DatesRangeValue }) => Promise<void>
}

export const AdjustForecastContainer = ({
  onCancel,
  onSave,
  onGoalsReset,
  initialValues,
}: IProps) => {
  const { t } = useTranslation()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    await onSave(params)
  }

  return (
    <>
      <AdjustForecastForm
        onCancel={onCancel}
        onSubmit={onSubmit}
        initialValues={initialValues}
        onGoalsReset={onGoalsReset}
      />
    </>
  )
}
