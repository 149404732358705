import axios from 'axios'

class IntegrationAPI {
  getIntegration = async (id: number, params: any = {}) => {
    return axios.get(`api/integrations/${id}`, {
      params,
    })
  }

  deleteIntegration = async (id: number) => {
    return axios.delete(`api/integrations/${id}`)
  }

  start = async (id: number) => {
    return axios.post(`api/integrations/${id}/start`)
  }
}

export const integrationAPI = new IntegrationAPI()
