import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PageContent } from '@/components/Layouts'

export const Billing = () => {
  const { t } = useTranslation()
  return (
    <>
      <MegaTagTitle title={'billing'} />

      <PageContent>{t('billing')}</PageContent>
    </>
  )
}
